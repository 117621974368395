<eau-nav
    [title]="appTitle"
    [displayName]="displayName$ | async"
    [userRole]="userRole$ | async"
    [languages]="languages$ | async"
    [isLoggedIn]="isLoggedIn$ | async"
    (logout)="onLogout()"
    (toggleSound)="onToggleSound()"
    *ngIf="shouldShowNav$ | async">
</eau-nav>

<main>
	<div class="container eau-main-container">
		<div class="row">
			<div class="col-md-12">
				<eau-error-page *ngIf="shouldShowErrorPage()"></eau-error-page>
				<div [hidden]="shouldShowErrorPage()">
					<router-outlet></router-outlet>
				</div>
			</div>
		</div>
	</div>
    <!--
    <div class="eau-visma-logo--wrap" *ngIf="isLoggedIn$ | async">
	    <div class="eau-visma-logo"></div>
    </div>
    -->
</main>

<eau-loader-launcher
    [title]="appTitle"
    *ngIf="!(isIdentityChecked$ | async)">
</eau-loader-launcher>
<ng-template ngbModalContainer></ng-template>
<eau-notifications-holder></eau-notifications-holder>
