import { FormatService } from './../../format/shared/format.ser';
import { listItemAppearAnimation } from './../../core/shared/animations';
import {
    Component,
    Input,
    ChangeDetectionStrategy,
} from '@angular/core';
import { Bid } from '../shared/bids.interfaces';
import * as moment from 'moment';

@Component({
    animations: [listItemAppearAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-bids-list',
    styleUrls: [
        './bids-list.styles.less',
    ],
    templateUrl: './bids-list.template.html',
})
export class BidsListComponent {

    @Input()
    public bids: Bid[];

    @Input()
    public unit: string;

    @Input()
    public joinedSuppliers: string[];

    constructor(
        private _formatService: FormatService,
    ) {}

    public getFormatedBidValue(bid: Bid): string {
        return this._formatService.getFormatedNumber(bid.value);
    }

    public getCreationTime(bid: Bid): string {
        return moment(bid.createdOn).format('L HH:mm:ss');
    }

    public isSupplierJoined(supplierName: string): boolean {
        return this.joinedSuppliers.some((x) => x === supplierName);
    }

    public getOnlineStatusTooltip(supplierName: string): string {
        return this.isSupplierJoined(supplierName)
            ? 'bids.tooltip.supplier_joined_auction'
            : 'bids.tooltip.supplier_not_joined_auction';
    }

    public trackByFn(bid: Bid): number {
        return bid.id;
    }

}
