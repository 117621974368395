import { ErrorPageService } from './../shared/error-page.service';
import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './not-authorized-page.template.html',
})
export class NotAuthorizedPageComponent implements OnInit {

    constructor(
        private _errorPageService: ErrorPageService,
    ) {}

    public ngOnInit(): void {
        this._errorPageService.showNotAuthorized();
    }

}
