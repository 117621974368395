<div class="eau-login-bg"></div>

<div class="eau-login-box">

    <div class="eau-login-flags">
        <eau-languages-flags [languages]="languages$ | async"></eau-languages-flags>
    </div>

    <div class="eau-login-box--left">
        <div class="eau-login-box--bubles"></div>
        <div class="eau-login-box--symbol-wrap">
            <div class="eau-login-box--symbol"></div>
        </div>
    </div>

    <div class="eau-login-box--right">

        <div class="eau-login-box--title">{{appTitle}}</div>
        <eau-login-form [hasLoginFailed]="hasLoginFailed$"
                        [isLoggingIn]="isLoggingIn$ | async"
                        (login)="onLogin($event)">
        </eau-login-form>
    </div>

    <div class="eau-login-box--footer">
        <!--<div class="eau-login-box--footer-logo"></div>-->
    </div>

    <div class="eau-login-box--shadow"></div>
</div>

<div class="eau-login-footer">
    <!--
    Commenting links until translations are set
    <a href="https://www.visma.com/privacy-statement/" target="_blank">{{'help.privacy_statement' | translate}}</a>
    |
    <a [routerLink]="getCookiesPageUrl()">{{'help.information_about_cookies' | translate}}</a>
    -->
</div>
