<div class="eau-timer" data-qa="timer">

    <!-- Keep next line in single line so ":after" style is not separated by space -->
    <div class="eau-bidding-status-component--title {{className$ | async}}" data-qa="leftMenuTenderAuctionStatus">{{title$ | async | translate}}</div>
    <div class="eau-bidding-status-component--content" data-qa="leftMenuTenderAuctionRemainingTime">
        {{remainingTime}}
    </div>
    <div class="eau-bidding-status-component--footer" data-qa="leftMenuTenderAuctionEndDate">
        {{description$ | async | translate}} {{getDate$() | async}}
    </div>

</div>