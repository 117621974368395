import { AppTranslateStore } from './../../app-translate/shared/app-translate.store';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class AppDatepickerI18nService extends NgbDatepickerI18n {

    constructor(
        private _appTranslateStore: AppTranslateStore,
    ) {
        super();
    }
    public getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }

    public getWeekdayShortName(weekday: number): string {
        const translations = this._appTranslateStore.getDatepickerTranslations();
        return translations.weekdays[weekday - 1];
    }

    public getMonthShortName(month: number): string {
        const translations = this._appTranslateStore.getDatepickerTranslations();
        return translations.months[month - 1];
    }

    public getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

}
