import { monthDayTimeFormat } from './format.const';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class DateTimeService {

    constructor(
        private _ngbDateParserFormatter: NgbDateParserFormatter,
    ) {}

    public getIsoDate(dateInUserTimeZone: string, timeInUserTimeZone: string): string {
        const dateTime = this._getParsableDateTimeString(dateInUserTimeZone, timeInUserTimeZone);
        return moment(dateTime, 'L HH:mm').toISOString();
    }

    /**
     * Returns formated date in user time zone
     */
    public getFormatedDate(isoDateTime: string): string {
        return moment(isoDateTime).format('LL HH:mm');
    }

    /**
     * Returns formated time in user time zone
     * @param time: can be time, date, isoDateTime
     */
    public getFormatedTime(isoDate: string): string {
        return moment(isoDate).format('HH:mm');
    }

    public getFormatedDateTime(isoDate: string): string {
        return moment(isoDate).format(monthDayTimeFormat[moment.locale()]);
    }

    public getStructedDate(isoDate: string): NgbDateStruct {
        if (isoDate) {
            const mom = moment(isoDate);
            return { day: mom.date(), month: mom.month() + 1, year: mom.year() };
        }

        return null;
    }

    public getDestructedDate(dateStructObj: NgbDateStruct): string {
        return this._ngbDateParserFormatter.format(dateStructObj);
    }

    public getUnixFromDate(isoDate: string): number {
        return moment(isoDate).unix();
    }

    public getDateFromUnix(unix: number, format: string): string {
        return moment.unix(unix).format(format);
    }

    private _getParsableDateTimeString(date: string, time: string): string {
        return date + ' ' + time;
    }

}
