import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorPageService {

    private _title: string;

    private _text: string;

    private _textInterpolateParams: Object;

    private _iconType: string;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _translateService: TranslateService,
    ) {}

    public shouldShowErrorPage(): boolean {
        return !!this._title;
    }

    public getTitle(): string {
        return this._title;
    }

    public getText(): string {
        return this._text;
    }

    public getTextInterpolateParams(): Object {
        return this._textInterpolateParams;
    }

    public getIconType(): string {
        return this._iconType;
    }

    public show(
        title: string,
        text: string,
        textInterpolateParams: Object = null,
        iconType: 'success' | 'info' | 'warning' | 'error' = 'error',
    ): void {
        this._title = title;
        this._text = text;
        this._textInterpolateParams = textInterpolateParams;
        this._iconType = iconType;
    }

    public hide(): void {
        this._title = null;
        this._text = null;
        this._textInterpolateParams = null;
        this._iconType = null;
    }

    public showNotAuthorized(): void {
        const title = 'error_page.title.not_authorized';
        let text = this._translateService.instant('error_page.text.not_authorized');
        const requestedUrl = this._activatedRoute.snapshot.queryParams.requestedUrl;

        if (requestedUrl) {
            text += `. Url: ${decodeURIComponent(requestedUrl)}`;
        }

        this.show(title, text);
    }

}
