<div class="clearfix">
    <form class="form-inline eau-answer-widget-form" [formGroup]="form" [class.has-error]="isInvalid()">
        <div class="eau-text-nowrap">
            <div class="form-group eau-form-group">
                <div class="input-group">

                    <!--
                        TODO: find out how to apply animation attribute to component
                        [@highlightElementAnimation]="nextAllowedBidAnimationState"
                    -->
                    <div class="formated-input-wrap">
                        <eau-formated-input
                            formControlName="value"
                            [isDisabled]="isInputDisabled()"
                            [id]="id"
                            (focus)="onFocus()"
                            data-qa="nextAllowedBidInput">
                        </eau-formated-input>
                    </div>

                    <button
                        type="button"
                        class="input-group-addon input-group-addon--first-child"
                        [attr.area-label]="'common.btn.save' | translate"
                        (click)="onSubmit()"
                        *ngIf="shouldShowControls()"
                    >
                        <span class="icon icon-checkmark" data-qa="nextAllowedBidInputConfirmBtn"></span>
                    </button>
                    <button
                        type="button"
                        class="input-group-addon"
                        [attr.area-label]="'common.btn.cancel' | translate"
                        (click)="onCancel()"
                        *ngIf="shouldShowControls()"
                    >
                        <span class="icon icon-remove" data-qa="nextAllowedBidInputCancelBtn"></span>
                    </button>
                    <div class="input-group-addon eau-spinner-addon input-group-addon--first-child" *ngIf="isSavingBid">
                        <span class="eau-spinner-medium"></span>
                    </div>
                </div>
            </div>
            <span class="eau-form-unit--beside">{{unit}}</span>
        </div>

        <eau-validation-errors [form]="form" [key]="key"></eau-validation-errors>
    </form>

    <a class="eau-copy-bid-link"
        data-qa="copyNextAllowedBidLink"
        (click)="onCopyNextAllowedBid()"
        eauEmptyLink
        *ngIf="!isDisabled">
        {{'bidding.copy_next_allowed_bid' | translate}}
    </a>
</div>