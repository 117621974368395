import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormatService } from './../shared/format.ser';
import {
    Component, Output, forwardRef,
    EventEmitter, Input, OnInit, OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormatedInputComponent),
        },
    ],
    selector: 'eau-formated-input',
    templateUrl: './formated-input.template.html',
})
export class FormatedInputComponent implements ControlValueAccessor, OnInit, OnDestroy {

    @Input()
    public isDisabled: boolean;

    @Input()
    public id: string;

    @Output()
    public focus: EventEmitter<void> = new EventEmitter<void>();

    public viewValue: string;

    private _modelValue: number;

    private _subscription: Subscription;

    constructor(
        private _formatService: FormatService,
        private _translateService: TranslateService,
    ) { }

    public ngOnInit(): void {
        this._subscription = this._translateService.onLangChange
            .subscribe(() => {
                this.viewValue = this._formatService.getFormatedNumber(this._modelValue, false);
            });
    }

    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    /**
     * Is called when input value is changed somewhere outside of this component
     */
    public writeValue(value: number): void {
        this._modelValue = value;
        this.viewValue = this._formatService.getFormatedNumber(value, false);
    }

    public onChange(event: KeyboardEvent): void {
        const formatedValue = (<HTMLInputElement>event.target).value;
        const unformatedValue = this._formatService.getUnformatedNumber(formatedValue);
        this.viewValue = formatedValue;
        this._modelValue = unformatedValue;
        this.propagateChange(unformatedValue);
    }

    public onKeyPress(event: KeyboardEvent): void {
        if (this._formatService.isDecimalSeparator(event.key)) {
            event.preventDefault();
        }
    }

    public onFocus(): void {
        this.viewValue = this._formatService.getValueWithoutThousandSeparators(this.viewValue);
        this.focus.emit();
    }

    public onBlur(): void {
        if (this._formatService.isNumber(this._modelValue)) {
            this.viewValue = this._formatService.getFormatedNumber(this._modelValue, false);
        }
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(): void {
        //
    }

    private propagateChange(_: any): void {
        //
    }

}
