import { getFormattedDate } from './../../app-forms/app-validators/date.validator';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class DateParserFormatter extends NgbDateParserFormatter {
    public parse(value: string): NgbDateStruct {
        if (value) {
            const mom = moment(value, 'L');
            return { day: mom.date(), month: mom.month() + 1, year: mom.year() };
        }
        return null;
    }

    public format(date: NgbDateStruct): string {
        let stringDate: string = '';
        if (date) {
            const momentDate = moment(getFormattedDate(date));
            stringDate = momentDate.format('L');
        }
        return stringDate;
    }
}
