import { DataSyncService } from './../../data-sync/shared/data-sync.service';
import { FormatService } from './../../format/shared/format.ser';
import { AppInfoStore } from './../../app-info/shared/app-info.store';
import { defaultBidsState } from './bids.data';
import { AudioService } from './../../core/shared/audio.service';
import { BidsApiService } from './bids-api.service';
import { BidsState, Bid, BidsTab } from './bids.interfaces';
import { BidsChartType } from './../../bids-chart/shared/bids-chart.interfaces';
import { NotificationsService } from './../../notifications/shared/notifications.service';
import { Injectable } from '@angular/core';
import { BaseStore } from './../../store/shared/base-store';

@Injectable()
export class BidsStore extends BaseStore<BidsState> {

    constructor(
        private _bidsApiService: BidsApiService,
        private _notificationsService: NotificationsService,
        private _audioService: AudioService,
        private _appInfoStore: AppInfoStore,
        private _formatService: FormatService,
        private _dataSyncService: DataSyncService,
    ) {
        super(defaultBidsState);
    }

    public loadBids(auctionId: number): void {
        this._updateState(<BidsState>{
            isLoading: true,
        });

        this._bidsApiService.getBids(auctionId)
            .subscribe((response) => {
                this._updateState(<BidsState>{
                    bids: response.bids,
                    isLoaded: true,
                    isLoading: false,
                });
            },
            () => {
                this._updateState(<BidsState>{
                    isLoading: false,
                });
            });
    }

    public getBids(): Bid[] {
        return [...this._getState().bids];
    }

    public addBid(bid: Bid, unit: string): void {
        this._informAboutNewBid(bid, unit);

        bid.animationState = 'isHighlighted';

        this._updateState(<BidsState>{
            bids: [bid, ...this._getState().bids],
        });
    }

    public syncBids(auctionId: number, unit: string): void {
        const currentBids = this._getState().bids;

        this._bidsApiService.getBids(auctionId)
            .subscribe(
                ({ bids: newBids }) => {
                    this._updateState(<BidsState>{
                        bids: newBids,
                    });

                    this._informAboutNewBids(currentBids, [...newBids], unit);
                },
                this._dataSyncService.onSyncFail,
            );
    }

    public setActiveTab(tab: BidsTab): void {
        if (this._getState().activeTab === tab) {
            return;
        }

        this._updateState(<BidsState>{
            activeTab: tab,
        });
    }

    public setActiveChartType(type: BidsChartType): void {
        if (this._getState().activeChartType === type) {
            return;
        }

        this._updateState(<BidsState>{
            activeChartType: type,
        });
    }

    public updateChartRange(range: number[]): void {
        this._updateState(<BidsState>{
            chartRange: range,
        });
    }

    public setChartAsLoaded = (): void => {
        this._updateState(<BidsState>{
            isChartLoaded: true,
        });
    }

    private _informAboutNewBid = (bid: Bid, unit: string): void => {
        const bidValue = this._formatService.getFormatedNumberWithUnit(bid.value, unit);
        const bidInfo = `${bid.supplierName}, ${bidValue}`;
        this._notificationsService.info(
            'notifications.purchaser.new_bid_arrived',
            { bidInfo },
            true,
        );

        if (this._appInfoStore.isSoundOn()) {
            this._audioService.playNotificationSound();
        }
    }

    private _informAboutNewBids = (currentBids: Bid[], newBids: Bid[], unit: string): void => {
        currentBids.reverse();
        newBids.reverse();

        newBids.forEach((newBid) => {
            const doesBidExist = currentBids.some((currentBid) => {
                return currentBid.id === newBid.id;
            });

            if (!doesBidExist) {
                this._informAboutNewBid(newBid, unit);
            }
        });
    }

}
