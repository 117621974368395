import { FocusOnInitDirective } from './shared/focus-on-init.directive';
import { EmptyLinkDirective } from './shared/empty-link.directive';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [
        EmptyLinkDirective,
        FocusOnInitDirective,
    ],
    exports: [
        EmptyLinkDirective,
        FocusOnInitDirective,
    ],
})
export class UtilsModule {

}
