import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidationService } from './../../app-forms/shared/validation.service';

@Component({
    selector: 'eau-app-input',
    styleUrls: [
        '../shared/app-inputs.styles.less',
    ],
    templateUrl: './app-input.template.html',
})
export class AppInputComponent implements AfterViewInit {

    @Input()
    public form: FormGroup;

    @Input()
    public key: string;

    @Input()
    public label: string;

    @Input()
    public id: string;

    private _el: ElementRef;

    constructor(
        private _validationService: ValidationService,
        el: ElementRef,
    ) {
        this._el = el;
    }

    public ngAfterViewInit(): void {
        const el = this._el.nativeElement;
        const input = el.querySelector('input');
        el.className += 'form-group';
        input.setAttribute('id', this.getId());
        input.className += 'form-control';
    }

    public isInvalid(): boolean {
        return this._validationService.isInvalid(this.form, this.key);
    }

    public getId(): string {
        if (this.id) {
            return this.id;
        }

        return `eau-app-input-${this.key}`;
    }

}
