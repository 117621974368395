import { Language } from './../../app-translate/shared/app-translate.interfaces';
import { RedirectionService } from './../../app-routing/shared/redirection.service';
import { UserRole } from './../../user/shared/user.interfaces';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'eau-nav',
    styleUrls: [
        './nav.styles.less',
    ],
    templateUrl: './nav.template.html',
})
export class NavComponent {

    public isClosed: boolean = true;

    @Input()
    public title: string;

    @Input()
    public displayName: string;

    @Input()
    public userRole: UserRole;

    @Input()
    public languages: Language[];

    @Input()
    public isLoggedIn: boolean;

    @Output()
    public logout: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public toggleSound: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _redirectionService: RedirectionService,
    ) {}

    public onNavbarToggle(): void {
        this.isClosed = !this.isClosed;
    }

    public onLogout(): void {
        this.logout.emit();
    }

    public onHomeClick(): void {
        if (this.userRole) {
            this._redirectionService.redirectToDefault(this.userRole);
        } else {
            this._redirectionService.redirectToHome();
        }
    }

    public onToggleSound(): void {
        this.toggleSound.emit();
    }

}
