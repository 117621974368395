import { ErrorPageService } from './../shared/error-page.service';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-error-page',
    templateUrl: './error-page.template.html',
})
export class ErrorPageComponent {

    constructor(
        private _errorPageService: ErrorPageService,
    ) {}

    public getTitle(): string {
        return this._errorPageService.getTitle();
    }

    public getText(): string {
        return this._errorPageService.getText();
    }

    public getTextInterpolateParams(): Object {
        return this._errorPageService.getTextInterpolateParams();
    }

    public getIconType(): string {
        return this._errorPageService.getIconType();
    }

}
