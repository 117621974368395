import {
    Directive,
    ElementRef,
 } from '@angular/core';

@Directive({
    selector: '[eauFocusOnInit]',
})
export class FocusOnInitDirective {

    constructor(
        private _element: ElementRef,
    ) {}

    public ngAfterViewChecked(): void {
        setTimeout(() => {
            this._element.nativeElement.focus();
        }, 2000);
    }

}
