<div class="panel" data-qa="bidsContainer">
    <div class="panel-body">

        <h2>{{'bids.component_title' | translate}}</h2>

        <eau-loader-holder [showLoader]="(!(isLoaded$ | async))">

            <tabset #tabs>
                <tab heading="{{'bids.tab.list' | translate}}"
                    (selectTab)="onSelectTab(listTab)"
                    [active]="isTabActive$(listTab) | async">

                    <eau-bids-list
                        [bids]="(bids$ | async)"
                        [unit]="(unit$ | async)"
                        [joinedSuppliers]="joinedSuppliers$ | async">
                    </eau-bids-list>

                </tab>
                <tab heading="{{'bids.tab.chart' | translate}}"
                    (selectTab)="onSelectTab(chartTab)"
                    [active]="isTabActive$(chartTab) | async">

                    <eau-loader-spinner *ngIf="!(isChartLoaded$ | async)"></eau-loader-spinner>
                    <router-outlet></router-outlet>

                </tab>
            </tabset>

        </eau-loader-holder>

    </div>
</div>
