
import { combineLatest,  Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { DateTimeService } from './../../format/shared/date-time.service';
import { AuctionStatus, TimerLabelsStatuses } from '../shared/auction-info.interfaces';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-timer',
    styleUrls: [
        '../../shared/shared/shared.styles.less',
        './timer.styles.less',
    ],
    templateUrl: './timer.template.html',
})
export class TimerComponent {

    @Input()
    public startDateTime: Observable<string>;

    @Input()
    public endDateTime: Observable<string>;

    @Input()
    public remainingTime: string;

    @Input()
    public status: Observable<AuctionStatus>;

    public title$: Observable<string>;

    public className$: Observable<string>;

    public description$: Observable<string>;

    constructor(
        private _dateTimeService: DateTimeService,
    ) {}

    public ngOnInit(): void {
        this.title$ = this._mapToStatusesProp('title');
        this.className$ = this._mapToStatusesProp('className');
        this.description$ = this._mapToStatusesProp('description');
    }

    public getDate$(): Observable<string> {
        return combineLatest([
            this.status,
            this.startDateTime,
            this.endDateTime,
        ]).pipe(
            map(([status, startDateTime, endDateTime]) => {
                if (status === AuctionStatus.notSet) {
                    return '';
                }

                const baseTime = status === AuctionStatus.notStarted
                    ? startDateTime : endDateTime;

                if (!baseTime) {
                    return '';
                }

                return this._dateTimeService.getFormatedDateTime(baseTime);
            }));
    }

    private _mapToStatusesProp(prop: 'title' | 'className' | 'description'): Observable<string> {
        return this.status.pipe(map((status) => {
            const statuses = this._getStatuses();
            return statuses[status][prop];
        }));
    }

    private _getStatuses(): TimerLabelsStatuses {
        const statuses: TimerLabelsStatuses = {};

        statuses[AuctionStatus.notSet] = {
            className: '',
            description: '',
            title: '',
        };

        statuses[AuctionStatus.notStarted] = {
            className: '',
            description: 'timer.status_not_started.description',
            title: 'timer.status_not_started.title',
        };

        statuses[AuctionStatus.ongoing] = {
            className: 'text-success',
            description: 'timer.status_ongoing.description',
            title: 'timer.status_ongoing.title',
        };

        statuses[AuctionStatus.finished] = {
            className: '',
            description: 'timer.status_finished.description',
            title: 'timer.status_finished.title',
        };

        return statuses;
    }

}
