<div class="panel eau-main-panel">
    <div class="panel-body">
        <h1>{{title}}</h1>

        <div class="alert alert-error" role="alert">
            <div>
                <span class="vismaicon vismaicon-lg vismaicon-filled vismaicon-{{iconType}}" aria-hidden="true"></span>
                {{text}}
            </div>
        </div>

        <button type="button" class="btn btn-default" (click)="goHome()">
            {{'common.btn.go_to_home_page' | translate}}
        </button>
    </div>
</div>