import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ValidationResult } from './../shared/validators.interfaces';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

export function dateValidator(control: FormControl): ValidationResult {
    if (!control.value) {
        return null;
    }

    if (!isDateValid(control.value)) {
        return {
            date: true,
        };
    }

    return null;
}

function isDateValid(value: NgbDateStruct): boolean {
    const valueString = getFormattedDate(value);
    return moment(valueString, 'YYYY-MM-DD', true).isValid();
}

// Not possible to use NgbDateParserFormatter.format method in static method
// So repeating implementation here
export function getFormattedDate(date: NgbDateStruct): string {
    if (!date) {
        return '';
    }

    const month = isNumber(date.month) ? padNumber(date.month) : '';
    const day = isNumber(date.day) ? padNumber(date.day) : '';
    return `${date.year}-${month}-${day}`;
}

function isNumber(value: any): boolean {
    return !isNaN(toInteger(value));
}

function padNumber(value: any): string {
    if (isNumber(value)) {
        return ('0' + value).slice(-2);
    }
    return '';
}

function toInteger(value: any): number {
    return parseInt('' + value, 10);
}
