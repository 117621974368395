<eau-join-auction-shared-container [auctionId]="auctionId"></eau-join-auction-shared-container>
<eau-join-auction-purchaser-container [auctionId]="auctionId"></eau-join-auction-purchaser-container>
<eau-data-sync-purchaser-container
    [auctionId]="auctionId"
    [unit]="unit$ | async">
</eau-data-sync-purchaser-container>

<div class="row">
    <div class="col-md-12">
        <eau-auction-info-container [auctionId]="auctionId"></eau-auction-info-container>
    </div>
</div>

<div *ngIf="shouldRenderAllWidgets$() | async">
    <div class="row eau-purhcaser-container-row" >
        <div
            [class.col-sm-6]="!(shouldExpandSettings$ | async)"
            [class.col-sm-8]="shouldExpandSettings$ | async"
            [class.col-sm-offset-2]="shouldExpandSettings$ | async">
            <eau-settings-container
                [auctionId]="auctionId"
                [title]="'settings.component_title_for_purchaser' | translate"
                [canUserEdit]="true">
            </eau-settings-container>
        </div>
        <div class="col-sm-6" [hidden]="shouldExpandSettings$ | async">
            <div class="eau-sticky-bidding-status" [eauSticky]="stickyBiddingStatusOptions">
                <div class="panel eau-purchaser-bidding-status-panel">
                    <div class="panel-body">
                        <eau-timer-container
                            [startDateTime]="startDateTime$"
                            [endDateTime]="endDateTime$">
                        </eau-timer-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" [hidden]="shouldExpandSettings$ | async">
        <div class="col-md-12">
            <eau-bids-container [auctionId]="auctionId"></eau-bids-container>
        </div>
    </div>
</div>
