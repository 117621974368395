import { AppTranslateService } from './../../app-translate/shared/app-translate.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AppResolver implements Resolve<any> {

    constructor(
        private _appTranslateService: AppTranslateService,
    ) {}

    public resolve(): Observable<boolean> {
        return this._appTranslateService.getTranslationsResolver$();
    }

}
