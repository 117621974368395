
import { take, skipWhile, map } from 'rxjs/operators';
import { AuctionStatus } from './../../auction-info/shared/auction-info.interfaces';
import { SettingsSocketsService } from './../shared/settings-sockets.service';
import { AuctionInfoStore } from './../../auction-info/shared/auction-info.store';
import { SettingsStore } from './../shared/settings.store';
import { Subscription ,  Observable } from 'rxjs';
import { AppTranslateStore } from './../../app-translate/shared/app-translate.store';
import { Settings, SettingsState, SettingsFormModel } from './../shared/settings.interfaces';
import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-settings-container',
    templateUrl: './settings-container.template.html',
})
export class SettingsContainerComponent implements OnInit, OnDestroy {

    @Input()
    public auctionId: number;

    @Input()
    public title: string;

    @Input()
    public canUserEdit: boolean;

    public settingsState$: Observable<SettingsState>;

    public areSettingsSet$: Observable<boolean>;

    public unit$: Observable<string>;

    public auctionStatus$: Observable<AuctionStatus>;

    public language$: Observable<string>;

    public shouldShowLoader$: Observable<boolean>;

    private _subscriptions: Subscription[] = [];

    constructor(
        private _settingsSocketsService: SettingsSocketsService,
        private _appTranslateStore: AppTranslateStore,
        private _auctionInfoStore: AuctionInfoStore,
        private _settingsStore: SettingsStore,
    ) {}

    public ngOnInit(): void {
        this.settingsState$ = this._settingsStore.getState$();
        this.areSettingsSet$ = this._settingsStore.selectors.areSettingsSet$();
        this.auctionStatus$ = this._auctionInfoStore.getState$().pipe(map((x) => x.auctionStatus));

        this.shouldShowLoader$ = this.settingsState$.pipe(map((s) => {
            if (!s.isLoaded) {
                return true;
            }

            if (s.isLoading) {
                return true;
            }

            if (!s.model.startDateTime && !s.isInEditMode) {
                return true;
            }

            return false;
        }));

        this.unit$ = this._auctionInfoStore.getState$().pipe(
            map((auctionInfo) => auctionInfo.auctionModel.unit));

        this.language$ = this._appTranslateStore.getState$().pipe(
            map((state) => state.activeLanguage));

        this._settingsStore.loadSettings(this.auctionId);
        this._initSocketsEvents();

        this.settingsState$.pipe(
            skipWhile((s) => !s.isLoaded),
            take(1))
            .subscribe(this._openEditModeIfSettingsNotSet.bind(this));
    }

    public ngOnDestroy(): void {
        this._settingsStore.resetState();
        this._subscriptions.forEach((s) => s.unsubscribe());
    }

    public onEdit(): void {
        this._settingsStore.openEditMode();
    }

    public onSave(newSettings: Settings | SettingsFormModel): void {
        this.areSettingsSet$.pipe(take(1)).subscribe((areSet) => {
            if (areSet) {
                this._settingsStore.updateSettings(this.auctionId, newSettings);
            } else {
                this._settingsStore.createSettings(this.auctionId, <Settings>newSettings);
            }
        });
    }

    public onCancel(): void {
        this._settingsStore.closeEditMode();
    }

    public canSettingsBeEdited$(): Observable<boolean> {
        return this._auctionInfoStore.getState$().pipe(
            map((x) => {
                return this.canUserEdit && x.auctionStatus !== AuctionStatus.finished;
            }));
    }

    private _initSocketsEvents(): void {
        const s1 = this._settingsSocketsService
            .settingsUpdated()
            .subscribe((newSettings) => {
                this._settingsStore.useNewSettings(newSettings);
            });

        const s2 = this._settingsSocketsService
            .auctionProlonged()
            .subscribe((response) => {
                this._settingsStore.prolong(response.endDateTime);
            });

        this._subscriptions = [s1, s2];
    }

    private _openEditModeIfSettingsNotSet(state: SettingsState): void {
        if (state.model.startDateTime) {
            return;
        }

        if (!this.canUserEdit) {
            return;
        }

        // TODO: does not work without timeout
        setTimeout(() => {
            this._settingsStore.openEditMode();
        });
    }

}
