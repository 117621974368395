import { defaultJoinAuctionState, joinAuctionPeriodicities } from './join-auction.data';
import { JoinAuctionState, JoinedSupplier } from './join-auction.interfaces';
import { Injectable } from '@angular/core';
import { BaseStore } from './../../store/shared/base-store';
import * as moment from 'moment';

@Injectable()
export class JoinAuctionStore extends BaseStore<JoinAuctionState> {

    constructor() {
        super(defaultJoinAuctionState);
    }

    public setSupplierAsJoinedAuction(supplierName: string): void {
        const joinedSuppliers = this._getState().joinedSuppliers;
        const isJoined = joinedSuppliers.some((x) => x.name === supplierName);
        const currentTime = this._getCurrentIsoTime();
        const newJoinedSupplier: JoinedSupplier = {
            lastSeenOnline: currentTime,
            name: supplierName,
        };
        let newJoinedSuppliers: JoinedSupplier[];

        if (isJoined) {
            newJoinedSuppliers = joinedSuppliers.map((x) => {
                if (x.name === supplierName) {
                    return newJoinedSupplier;
                }

                return x;
            });
        } else {
            newJoinedSuppliers = [...joinedSuppliers, newJoinedSupplier];
        }

        this._updateState(<JoinAuctionState>{
            joinedSuppliers: newJoinedSuppliers,
        });
    }

    public setSupplierAsLeftAuction(supplierName: string): void {
        const newJoinedSuppliers = this._getState().joinedSuppliers
            .filter((x) => x.name !== supplierName);

        this._updateState(<JoinAuctionState>{
            joinedSuppliers: newJoinedSuppliers,
        });
    }

    public cleanupOrphanSuppliers = (): void => {
        const joinedSuppliers = this._getState().joinedSuppliers;
        const currentTime = this._getCurrentIsoTime();

        const newJoinedSuppliers = joinedSuppliers.filter((supplier) => {
            const diff = moment(currentTime)
                .diff(moment(supplier.lastSeenOnline), 'milliseconds', true);
            return diff < joinAuctionPeriodicities.maxIdleTime;
        });

        if (joinedSuppliers.length !== newJoinedSuppliers.length) {
            this._updateState(<JoinAuctionState>{
                joinedSuppliers: newJoinedSuppliers,
            });
        }
    }

    private _getCurrentIsoTime(): string {
        return new Date().toISOString();
    }

}
