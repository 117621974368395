import { monthDayTimeFormat } from './../../format/shared/format.const';
import { FormatService } from './../../format/shared/format.ser';
import { Settings, BidImprovementType } from './../shared/settings.interfaces';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-settings-preview',
    styleUrls: [
        './settings-preview.styles.less',
    ],
    templateUrl: './settings-preview.template.html',
})
export class SettingsPreviewComponent {

    @Input()
    public canEdit: boolean;

    @Input()
    public settings: Settings;

    @Input()
    public unit: string;

    // parent component must provide active language as input param
    // otherwise, because of change detections strategy = onPush,
    // methods that use moment.js won't be executed when language changes
    @Input()
    public language: string;

    @Output()
    public onEdit: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private _formatService: FormatService,
    ) {}

    public onEditBtnClick(): void {
        this.onEdit.emit();
    }

    public getPeriod(): string {
        const momentStart = moment(this.settings.startDateTime);
        const momentEnd = moment(this.settings.endDateTime);
        const startYear = momentStart.format('YYYY');
        const endYear = momentEnd.format('YYYY');
        const startMonth = momentStart.format('MMMM');
        const endMonth = momentEnd.format('MMMM');
        const startDay = momentStart.format('D');
        const endDay = momentEnd.format('D');
        let startFormat: string;
        let endFormat: string;

        if (startYear === endYear) {
            startFormat = monthDayTimeFormat[moment.locale()];
        } else {
            startFormat = 'LLL';
        }

        if (startYear === endYear && startMonth === endMonth && startDay === endDay) {
            endFormat = 'HH:mm';
        } else if (startYear === endYear && startMonth === endMonth) {
            endFormat = monthDayTimeFormat[moment.locale()];
        } else if (startYear === endYear ) {
            endFormat = monthDayTimeFormat[moment.locale()];
        } else {
             endFormat = 'LLL';
        }

        const startDate = momentStart.format(startFormat);
        const endDate = momentEnd.format(endFormat);

        return startDate + ' - ' + endDate;
    }

    public getTimeExtension(): string {
        return (this.settings.timeExtension / 60) + ' min';
    }

    public getMinImprovement(): string {
        const unit = this.settings.bidImprovementType === BidImprovementType.percentage
            ? '%'
            : this.unit;

        return this._formatService.getFormatedNumberWithUnit(this.settings.minBidImprovement, unit);
    }

}
