
import { take, map } from 'rxjs/operators';
import { RedirectionService } from './../../app-routing/shared/redirection.service';
import { UserStore } from './../../user/shared/user.store';
import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './home-page.template.html',
})
export class HomePageComponent implements OnInit {

    constructor(
        private _redirectionService: RedirectionService,
        private _userStore: UserStore,
    ) {}

    public ngOnInit(): void {
        this._userStore.getState$().pipe(
            map((s) => s.user.role),
            take(1))
            .subscribe((role) => {
                this._redirectionService.redirectToDefault(role);
            });
    }

}
