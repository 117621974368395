import { appRoutesPaths } from './app-routes.constants';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserRole } from '../../user/shared/user.interfaces';

@Injectable()
export class RedirectionService {

    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
    ) {}

    public redirectAfterLogin(role: UserRole): void {
        const returnUrl = this._activatedRoute.snapshot.queryParams.returnUrl;

        if (returnUrl) {
            this._router.navigateByUrl(decodeURIComponent(returnUrl))
                .then((isSuccess) => {
                    if (!isSuccess) {
                        // can happen if user logged in with different role
                        // than at the time when he was redirected to login page
                        this.redirectToDefault(role);
                    }
                });
        } else {
            this.redirectToDefault(role);
        }
    }

    public redirectAfterLogout(): void {
        this.redirectNotAuthenticated();
    }

    public redirectNotAuthenticated(
        redirectUrl?: string,
        shouldRedirectToCurrentRoute?: boolean,
    ): void {
        const loginUrl = `/${appRoutesPaths.login}`;
        const currentUrl = this._router.routerState.snapshot.url;

        if (currentUrl === loginUrl) {
            // user already is in login page
            // and could get 401 from other pending ajax requests
            return;
        }

        let returnUrl = redirectUrl;

        if (!returnUrl) {
            returnUrl = shouldRedirectToCurrentRoute ? currentUrl : null;
        }

        const navigationExtras: NavigationExtras = {};

        if (returnUrl && returnUrl !== '/') {
            navigationExtras.queryParams = {
                returnUrl: encodeURIComponent(returnUrl),
            };
        }

        this._router.navigate([loginUrl], navigationExtras);
    }

    public redirectNotAuthorized(requestedUrl: string): void {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                requestedUrl: encodeURIComponent(requestedUrl),
            },
        };

        this._router.navigate([`/${appRoutesPaths.notAuthorized}`], navigationExtras);
    }

    public redirectToDefault(role: UserRole): void {
        if (role === UserRole.purchaser) {
            this._router.navigateByUrl(`/${appRoutesPaths.purchaser.default}`);
        } else if (role === UserRole.supplier) {
            this._router.navigateByUrl(`/${appRoutesPaths.supplier.default}`);
        }
    }

    /**
     * Same method as redirectToDefault, but only used when user role is not known
     */
    public redirectToHome(): void {
        this._router.navigateByUrl(`/${appRoutesPaths.root}`);
    }

    public goToRoute( route: string,
                      navigationExtras?: NavigationExtras,
                      ...params: any): Promise<boolean> {
        const routeWithParams = this.getRouteWithParams(route, ...params);
        return this._router.navigateByUrl(routeWithParams, navigationExtras);
    }

    public getRouteWithParams(route: string, ...params: any[]): string {
        if (params) {
            params.forEach((p) => {
                route = route.replace(/:[^\/]*/, p);
            });
        }

        return '/' + route;
    }

}
