<div class="row">
	<div class="col-md-12">

		<div class="panel eau-main-panel">
			<div class="panel-body">

                <h2 data-qa="mainPageTitle" >{{'common.auctions' | translate}}</h2>

				<eau-loader-holder [showLoader]="showLoader$ | async">

                    <button type="button"
                        class="btn btn-default"
						data-qa="createAuctionButton"
                        (click)="onCreateAuction()">
                        {{'auctions_list.btn.create_auction' | translate}}
                    </button>

                    <eau-auctions-list
                        [auctions]="auctions$ | async"
                        (onDelete)="deleteAuction($event)">
                    </eau-auctions-list>

				</eau-loader-holder>

			</div>
		</div>

	</div>
</div>
