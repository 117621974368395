import { JoinAuctionStore } from './../shared/join-auction.store';
import { pubSubEvents } from './../../core/shared/pub-sub-events.values';
import { PubSubService } from './../../pub-sub/shared/pub-sub.service';
import { AppInfoStore } from './../../app-info/shared/app-info.store';
import { Subscription } from 'rxjs';
import { JoinAuctionSocketsService } from './../shared/join-auction-sockets.service';
import {
    Component,
    Input,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-join-auction-shared-container',
    templateUrl: './join-auction-shared-container.template.html',
})
export class JoinAuctionSharedContainerComponent implements OnInit, OnDestroy {

    @Input()
    public auctionId: number;

    private _subscriptions: Subscription[];

    constructor(
        private _joinAuctionSocketsService: JoinAuctionSocketsService,
        private _joinAuctionStore: JoinAuctionStore,
        private _appInfoStore: AppInfoStore,
        private _pubSubService: PubSubService,
    ) {}

    public ngOnInit(): void {
        this._bindUnloadEventToLeaveAuction();

        this._appInfoStore.whenConnected$()
            .subscribe(() => {
                this._joinAuctionSocketsService.joinAuction(this.auctionId);
            });

        const s1 = this._pubSubService.sub(pubSubEvents.logoutInitiated).subscribe(() => {
            this._joinAuctionSocketsService.leaveAuction(this.auctionId);
        });

        this._subscriptions = [s1];
    }

    public ngOnDestroy(): void {
        this._unbindUnloadEventToLeaveAuction();
        this._joinAuctionStore.resetState();
        this._joinAuctionSocketsService.leaveAuction(this.auctionId);
        this._subscriptions.forEach((x) => x.unsubscribe());
    }

    private _bindUnloadEventToLeaveAuction(): void {
        window.onbeforeunload = () => {
            this._joinAuctionSocketsService.leaveAuction(this.auctionId);
        };
    }

    private _unbindUnloadEventToLeaveAuction(): void {
        window.onbeforeunload = null;
    }

}
