import { PubSubModule } from './../pub-sub/pub-sub.module';
import { LocalStorageService } from './shared/local-storage.service';
import { ErrorHandlingService } from './shared/error-handling.service';
import { AppInfoModule } from './../app-info/app-info.module';
import { NotificationsModule } from './../notifications/notifications.module';
import {
    AppDatepickerI18nService,
} from './../app-forms/app-datepicker/app-datepicker-i18n.service';
import { DialogModule } from './../dialog/dialog.module';
import { NgbModule, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { AppConfig } from './shared/app.config';
import { AudioService } from './shared/audio.service';
import { BaseSocketsService } from './shared/base-sockets.service';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { BaseApiService } from './shared/base-api.service';
import { SessionService } from './shared/session.service';

@NgModule({
    exports: [
        NgbModule,
    ],
    imports: [
        CommonModule,
        DialogModule,
        NotificationsModule,
        AppInfoModule,
        PubSubModule,
    ],
    providers: [
        BaseApiService,
        AudioService,
        BaseSocketsService,
        ErrorHandlingService,
        LocalStorageService,
        // need to define custom NgbDatepickerI18n provider in the same place
        // where NgbModule.forRoot() is called:
        {
            provide: NgbDatepickerI18n,
            useClass: AppDatepickerI18nService,
        },
        SessionService,
    ],
})
export class CoreModule {

    public static forRoot(config: AppConfig): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                { provide: AppConfig, useValue: config },
            ],
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }

}
