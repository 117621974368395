import { SettingsSocketsService } from './shared/settings-sockets.service';
import { SettingsStore } from './shared/settings.store';
import { NgModule } from '@angular/core';
import { AppFormsModule } from '../app-forms/app-forms.module';
import { SharedModule } from '../shared/shared.module';
import { SettingsContainerComponent } from './settings-container/settings-container.component';
import { SettingsFormComponent } from './settings-form/settings-form.component';
import { SettingsPreviewComponent } from './settings-preview/settings-preview.component';
import { SettingsApiService } from './shared/settings-api.service';

@NgModule({
    declarations: [
        SettingsFormComponent,
        SettingsPreviewComponent,
        SettingsContainerComponent,
    ],
    exports: [
        SettingsContainerComponent,
    ],
    imports: [
        AppFormsModule,
        SharedModule,
    ],
    providers: [
        SettingsApiService,
        SettingsStore,
        SettingsSocketsService,
    ],
})
export class SettingsModule {

}
