<form class="form-horizontal"
    [formGroup]="createAuctionForm"
    (ngSubmit)="onSubmit()"
    data-qa="createAuctionForm">

    <div class="modal-header">
        <!--<button type="button"
            class="close"
            aria-label="Close"
            (click)="onCancel()">

            <span aria-hidden="true">&times;</span>
        </button>-->
        <h4 class="modal-title">{{'auctions_list.btn.create_auction' | translate}} </h4>
    </div>

    <div class="modal-body">

        <eau-app-input [label]="'common.procurement_name' | translate" [form]="createAuctionForm" [key]="'procurementName'">
            <input type="text" class="form-control" data-qa="procurementName" formControlName="procurementName">
        </eau-app-input>

        <eau-app-input [label]="'common.procurement_ref_number' | translate" [form]="createAuctionForm" [key]="'procurementReferenceNumber'">
            <input type="text" class="form-control" data-qa="procurementReferenceNumber" formControlName="procurementReferenceNumber">
        </eau-app-input>

        <eau-app-input [label]="'common.procurement_organization' | translate" [form]="createAuctionForm" [key]="'procurementOrganizationName'">
            <input type="text" class="form-control" data-qa="procurementOrganizationName" formControlName="procurementOrganizationName">
        </eau-app-input>

        <eau-app-input [label]="'common.currency' | translate" [form]="createAuctionForm" [key]="'currency'">
            <input type="text" class="form-control" data-qa="currency" formControlName="currency">
        </eau-app-input>

        <eau-app-input [label]="'common.unit' | translate" [form]="createAuctionForm" [key]="'unit'">
            <input type="text" class="form-control" data-qa="unit" formControlName="unit">
        </eau-app-input>

        <eau-app-input [label]="'DocsProjectId'" [form]="createAuctionForm" [key]="'docsProjectId'">
            <input type="text" class="form-control" formControlName="docsProjectId">
        </eau-app-input>

        <eau-app-input [label]="'LotId'" [form]="createAuctionForm" [key]="'lotId'">
            <input type="text" class="form-control" data-qa="lotId" formControlName="lotId">
        </eau-app-input>

        <eau-app-input [label]="'LotName'" [form]="createAuctionForm" [key]="'lotName'">
            <input type="text" class="form-control" data-qa="lotName" formControlName="lotName">
        </eau-app-input>

        <h3>{{'auction_create.initial_bids' | translate}}</h3>

        <table class="table" data-qa="initialBidsTable" formArrayName="initialBids">
            <tr *ngFor="let control of initialBids.controls; let i = index;">
                <td style="width: 100%;" [@listItemDisappearAnimation]>

                    <div [formGroup]="control">
                        <eau-app-input [label]="'Supplier'" [form]="control" [key]="'supplier'" [id]="getCustomInputId('supplier', i)">
                            <input type="text" class="form-control" data-qa="supplier" formControlName="supplier">
                        </eau-app-input>

                        <eau-app-input [label]="'Supplier name' | translate" [form]="control" [key]="'supplierName'" [id]="getCustomInputId('supplierName', i)">
                            <input type="text" class="form-control" data-qa="supplierName" formControlName="supplierName">
                        </eau-app-input>

                        <eau-app-input [label]="'auction_create.initial_bid' | translate" [form]="control" [key]="'value'" [id]="getCustomInputId('value', i)">
                            <input type="text" class="form-control" data-qa="initialBidValue" formControlName="value">
                        </eau-app-input>
                    </div>

                </td>
                <td [@listItemDisappearAnimation]>
                    <button type="button"
                        class="btn btn-default eau-icon-btn"
                        [attr.area-label]="'common.btn.delete' | translate"
                        data-qa="deleteSupplierFromAuction"
                        (click)="onDeleteBid(i)"
                        [disabled]="createAuctionForm.controls['initialBids'].controls.length < 2">
                        <span class="vismaicon vismaicon-sm vismaicon-delete"></span>
                    </button>
                </td>
            </tr>
        </table>

        <button type="button"
            class="btn btn-default eau-icon-btn"
            [attr.area-label]="'common.btn.add' | translate"
            data-qa="addSupplierToAuction"
            (click)="onAddBid()">
            +
        </button>

        <eau-loader-cover *ngIf="isCreatingAuction$ | async"></eau-loader-cover>

    </div>

    <div class="modal-footer">
        <button type="submit" class="btn btn-primary" data-qa="saveAuctionButton" [disabled]="!createAuctionForm.valid || (isCreatingAuction$ | async)">
            {{'common.btn.save' | translate}}
        </button>

        <button type="button" class="btn btn-default" data-qa="cancelAuctionCreationButton" (click)="onCancel()" [disabled]="(isCreatingAuction$ | async)">
            {{'common.btn.cancel' | translate}}
        </button>
    </div>

</form>
