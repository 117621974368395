import { PubSubEvent } from './pub-sub.interfaces';
import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class PubSubService {
    private _events: PubSubEvent = {};

    public sub(key: string): Observable<any> {
        if (!this._events[key]) {
            this._events[key] = new Subject();
        }

        return this._events[key].asObservable();
    }

    public pub(key: string, data?: any): void {
        if (!this._events[key]) {
            return;
        }

        this._events[key].next(data);
    }
}
