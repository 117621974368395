import { Bid, PendingBid } from './../../bids/shared/bids.interfaces';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export interface BaseAuctionModel {
    currency: string;
    procurementName: string;
    procurementOrganizationName: string;
    procurementReferenceNumber: string;
    unit: string;
}

export enum BiddingType {
    SinglePrice = 'SinglePrice',
    DocsApp = 'DocsApp',
}

export interface Auction extends BaseAuctionModel {
    biddingType: BiddingType;
    animationState?: 'isHighlighted';
    bids?: Bid[];
    id: number;
    initialBids?: Bid[];
}

export interface AuctionsState {
    auctions: Auction[];
    isCreatingAuction: boolean;
    isLoaded: boolean;
    isLoading: boolean;
}

export interface AuctionCreateFormModel extends BaseAuctionModel {
    docsProjectId: number;
    lotId: number;
    lotName: string;
    initialBids: {
        createdDate: NgbDateStruct;
        createdTime: string;
        supplier: string;
        supplierName: string;
        tenderId: number;
        value: number;
    }[];
}

export interface GetAuctionsApiResponseModel {
    auctions: Auction[];
}

export interface CreateAuctionApiRequestModel extends BaseAuctionModel {
    docsProjectId: number;
    initialBids: PendingBid[];
    lotId: number;
    lotName: string;
}

export interface CreateAuctionApiResponseModel extends BaseAuctionModel {
    id: number;
    initialBids: Bid[];
    lotId: number;
    lotName: string;
    biddingType: BiddingType;
}
