<div class="row">
	<div class="col-md-12">

		<div class="panel eau-main-panel">
			<div class="panel-body">

                <h2 data-qa="mainSupplierPageTitle">{{'common.tenders' | translate}}</h2>

				<eau-loader-holder [showLoader]="shouldShowLoader$ | async">

                    <eau-tenders-list
						data-qa="tenderListItem"
                        [tenders]="tenders$ | async">
                    </eau-tenders-list>

				</eau-loader-holder>

			</div>
		</div>

	</div>
</div>
