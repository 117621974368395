import {
    Directive,
    ElementRef,
    Renderer2,
    AfterViewInit,
    HostListener,
 } from '@angular/core';

@Directive({
    selector: '[eauEmptyLink]',
})
export class EmptyLinkDirective implements AfterViewInit {

    constructor(
        private _element: ElementRef,
        private _renderer: Renderer2,
    ) {}

    public ngAfterViewInit(): void {
        this._renderer.setAttribute(this._element.nativeElement, 'href', '');
    }

    @HostListener('click', ['$event'])
    public onClick(e: Event): void {
        e.preventDefault();
    }

}
