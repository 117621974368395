import { DataSyncModule } from './../data-sync/data-sync.module';
import { JoinAuctionModule } from './../join-auction/join-auction.module';
import {
    AuctionSupplierContainerComponent,
} from './auction-supplier-container/auction-supplier-container.component';
import {
    AuctionSupplierPageComponent,
} from './auction-supplier-page/auction-supplier-page.component';
import { SettingsModule } from '../settings/settings.module';
import { AuctionInfoModule } from '../auction-info/auction-info.module';
import { LayoutModule } from '../layout/layout.module';
import { BiddingModule } from '../bidding/bidding.module';
import { BiddingStatusModule } from './../bidding-status/bidding-status.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        AuctionSupplierPageComponent,
        AuctionSupplierContainerComponent,
    ],
    imports: [
        BiddingModule,
        AuctionInfoModule,
        BiddingStatusModule,
        LayoutModule,
        SettingsModule,
        SharedModule,
        JoinAuctionModule,
        DataSyncModule,
    ],
})
export class AuctionSupplierModule {

}
