import { ErrorPageService } from './../../layout/shared/error-page.service';
import { HttpError } from './../../core/shared/api.interfaces';
import { defaulAuctionInfotState } from './auction-info.data';
import { AuctionInfoApiService } from './auction-info-api.service';
import { AuctionInfoState, AuctionStatus } from './auction-info.interfaces';
import { BaseStore } from './../../store/shared/base-store';
import { Injectable } from '@angular/core';

@Injectable()
export class AuctionInfoStore extends BaseStore<AuctionInfoState> {

    constructor(
        private _auctionInfoApiService: AuctionInfoApiService,
        private _errorPageService: ErrorPageService,
    ) {
        super(defaulAuctionInfotState);
    }

    public getAuctionInfo(auctionId: number): void {
        this._updateState(<AuctionInfoState>{
            isLoading: true,
        });

        this._auctionInfoApiService.getAuction(auctionId)
            .subscribe((response) => {
                this._updateState(<AuctionInfoState>{
                    auctionModel: response,
                    isLoaded: true,
                    isLoading: false,
                });
            },
            (e: HttpError) => {
                this._updateState(<AuctionInfoState>{
                    isLoading: false,
                });

                if (e.status === 404) {
                    const title = 'error_page.title.auction_not_found';
                    const text = 'error_page.text.auction_not_found';
                    this._errorPageService.show(title, text);
                }
            });
    }

    public updateAuctionStatus(newStatus: AuctionStatus): void {
        this._updateState(<AuctionInfoState>{
            auctionStatus: newStatus,
        });
    }

    public isBiddingDisabled = (): boolean => {
        return this._getState().auctionStatus !== AuctionStatus.ongoing;
    }
}
