import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class AppConfig {

    public appTitle: string = environment.appTitle;

    public apiServerUrl: string = environment.apiServerUrl;

    public defaultLang: string = environment.defaultLang;

    public timespanBeforeSessionTimeout: number = environment.timespanBeforeSessionTimeout;

    public sessionTimeout: number = environment.sessionTimeout;

    public socketsHub: string = environment.socketsHub;

    public socketsServerUrl: string = environment.socketsServerUrl;

}
