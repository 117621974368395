
import { map } from 'rxjs/operators';
import { appRoutesPaths } from './../../app-routing/shared/app-routes.constants';
import { RedirectionService } from './../../app-routing/shared/redirection.service';
import { AppConfig } from './../../core/shared/app.config';
import { AppTranslateStore } from './../../app-translate/shared/app-translate.store';
import { Language } from './../../app-translate/shared/app-translate.interfaces';
import { LoginApiRequestModel } from './../shared/user.interfaces';
import { UserStore } from './../shared/user.store';
import { Observable } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-login-container',
    styleUrls: [
        './login-container.styles.less',
    ],
    templateUrl: './login-container.template.html',
})
export class LoginContainerComponent implements OnInit {

    public appTitle: string;

    public hasLoginFailed$: Observable<boolean>;

    public isLoggingIn$: Observable<boolean>;

    public languages$: Observable<Language[]>;

    constructor(
        config: AppConfig,
        private _userStore: UserStore,
        private _appTranslateStore: AppTranslateStore,
        private _redirectionService: RedirectionService,
    ) {
        this.appTitle = config.appTitle;
    }

    public ngOnInit(): void {
        this.hasLoginFailed$ = this._userStore.getState$().pipe(map((s) => s.hasLoginFailed));
        this.isLoggingIn$ = this._userStore.getState$().pipe(map((s) => s.isLoggingIn));
        this.languages$ = this._appTranslateStore.getState$().pipe(map((s) => s.languages));
    }

    public onLogin(model: LoginApiRequestModel): void {
        this._userStore.login(model);
    }

    public getCookiesPageUrl(): string {
        return this._redirectionService.getRouteWithParams(appRoutesPaths.public.cookies);
    }

    click() {
        this.appTitle = "My new title";
        //document.location.href = 'https://api.dev.eauction.mercell.com/api/index.html';
        $.getJSON("https://api.dev.eauction.mercell.com/api/auctions/all/b683b845-8499-4b84-b28c-97962b8c95c3");
    }
}
