import { BiddingState } from './bidding.interfaces';

export const defaultBiddingState: BiddingState = {
    bidModel: {
        value: null,
        valueInitial: null,
        valuePrevious: null,
    },
    isInputInEditMode: false,
    isLoaded: false,
    isLoading: false,
    isSavingBid: false,
    isSubmittingBid: false,
};

export const mockBiddingState: BiddingState = {
    bidModel: {
        value: 100,
        valueInitial: 200,
        valuePrevious: 150,
    },
    isInputInEditMode: false,
    isLoaded: true,
    isLoading: false,
    isSavingBid: false,
    isSubmittingBid: false,
};
