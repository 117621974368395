import {
    DataSyncSupplierContainerComponent,
} from './data-sync-supplier-container/data-sync-supplier-container.component';
import {
    DataSyncPurchaserContainerComponent,
} from './data-sync-purchaser-container/data-sync-purchaser-container.component';
import { DataSyncService } from './shared/data-sync.service';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [
        DataSyncPurchaserContainerComponent,
        DataSyncSupplierContainerComponent,
    ],
    exports: [
        DataSyncPurchaserContainerComponent,
        DataSyncSupplierContainerComponent,
    ],
    providers: [
        DataSyncService,
    ],
})
export class DataSyncModule {

}
