<li class="icon first-level-item dropdown" dropdown>

    <a eauEmptyLink dropdownToggle class="dropdown-toggle eau-always-show-text">
        <span class="vismaicon vismaicon-menu vismaicon-user-settings"></span>
        <span class="eau-text" data-qa="connectedUserName" *ngIf="title">{{title}}</span>
    </a>

    <ul class="dropdown-menu eau-dropdown-menu--right eau-languages-dropdown"
        role="menu"
        *dropdownMenu>

        <li *ngFor="let lang of languages; trackBy:lang?.key" role="menuitem">
            <a eauEmptyLink
                [class.eau-active]="lang.isActive"
                (click)="onChooseLanguage(lang.key)">
                {{lang.title | translate}}
            </a>
        </li>

    </ul>
</li>
