import { RouterModule } from '@angular/router';
import { AppFormsModule } from './../app-forms/app-forms.module';
import {
    CreateAuctionFormContainerComponent,
} from './create-auction-form-container/create-auction-form-container.component';
import { AuctionsApiService } from './shared/auctions-api.service';
import { AuctionsStore } from './shared/auctions.store';
import { AuctionsPageComponent } from './auctions-page/auctions-page.component';
import { AuctionsListComponent } from './auctions-list/auctions-list.component';
import { AuctionsContainerComponent } from './auctions-container/auctions-container.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        AuctionsPageComponent,
        AuctionsContainerComponent,
        AuctionsListComponent,
        CreateAuctionFormContainerComponent,
    ],
    exports: [AuctionsPageComponent],
    imports: [SharedModule, AppFormsModule, RouterModule],
    providers: [AuctionsStore, AuctionsApiService],
})
export class AuctionsModule {}
