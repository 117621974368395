import { RedirectionService } from './shared/redirection.service';
import { AppResolver } from './shared/app.resolver';
import { appRouting } from './shared/app.routing';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
    exports: [
        RouterModule,
    ],
    imports: [
        appRouting,
    ],
    providers: [
        AppResolver,
        RedirectionService,
    ],
})
export class AppRoutingModule {}
