<div class="eau-bidding-status-component eau-tender-rank clearfix">
    <div class="eau-bidding-status-component--title" data-qa="leftMenuRankText">{{'bidding_status.tender_rank' | translate}}</div>
    <div class="eau-bidding-status-component--content">
        <span class="eau-tender-rank--position" data-qa="leftMenuCurrentRankValue">{{getRank()}}</span>
        <span class="eau-tender-rank--value" data-qa="leftMenuRank">({{getSubmittedBidValue()}})</span>
    </div>
    <div class="eau-bidding-status-component--footer">
        {{'bidding_status.last_submitted_bid' | translate}}: {{getSubmittedBidValue()}}
    </div>
</div>
