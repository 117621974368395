import {
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ToastrService, ToastContainerDirective } from 'ngx-toastr';
import { NotificationsService } from '../shared/notifications.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
    animations: [
        trigger('closeBtnAnimation', [
            state('void', style({ opacity: 0, top: '0px' })),
            state('*', style({ opacity: 1, top: '-20px' })),
            transition('* <=> void', animate('200ms ease-in')),
        ]),
    ],
    encapsulation: ViewEncapsulation.None,
    selector: 'eau-notifications-holder',
    styleUrls: ['./notifications-holder.styles.less'],
    templateUrl: './notifications-holder.template.html',
})
export class NotificationsHolderComponent implements OnInit {
    @ViewChild(ToastContainerDirective, { static: true })
    private toastContainer: ToastContainerDirective;

    constructor(
        private _toastrService: ToastrService,
        private _notificationsService: NotificationsService,
    ) {}

    public ngOnInit(): void {
        this._toastrService.overlayContainer = this.toastContainer;
    }

    public onClose(): void {
        this._toastrService.clear();
    }

    public shouldShowClose(): boolean {
        return this._notificationsService.getCount() > 1;
    }
}
