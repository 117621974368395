
import { from,  Observable } from 'rxjs';
import { AlertComponent } from './../alert/alert.component';
import { ConfirmComponent } from './../confirm/confirm.component';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DialogService {

    private _activeModalRef: NgbModalRef;

    constructor(
        private _modalService: NgbModal,
    ) { }

    public open(content: any): void {
        const options: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
        };

        this._modalService.open(content, options);
    }

    public confirm(question: string, isUndismissible: boolean = false): Observable<boolean> {
        const options: NgbModalOptions = {
            backdrop: 'static',
            size: 'sm',
            windowClass: 'eau-dialog--confirm',
        };

        if (isUndismissible) {
            options.keyboard = false;
            options.windowClass += ' eau-dialog--undismissible';
        }

        this._activeModalRef = this._modalService.open(ConfirmComponent, options);
        this._activeModalRef.componentInstance.question = question;
        return from(this._activeModalRef.result);
    }

    public alert(text: string, btnText?: string): Observable<boolean> {
        const options: NgbModalOptions = {
            backdrop: 'static',
            size: 'sm',
            windowClass: 'eau-dialog--confirm',
        };

        this._activeModalRef = this._modalService.open(AlertComponent, options);
        this._activeModalRef.componentInstance.text = text;
        this._activeModalRef.componentInstance.btnText = btnText;
        return from(this._activeModalRef.result);
    }

    public dismissActiveDialog(): void {
        if (this._activeModalRef) {
            this._activeModalRef.dismiss();
        }
    }

}
