import { BaseSocketsService } from './../../core/shared/base-sockets.service';
import { SocketListenHook } from './../../core/shared/base-sockets.interfaces';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class BiddingStatusSocketsService {

    constructor(
        private _baseSocketsService: BaseSocketsService,
    ) {}

    public competitorSubmittedBid(): Observable<void> {
        return  this._baseSocketsService.get(SocketListenHook.competitorSubmittedBid);
    }
}
