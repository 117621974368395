import { SettingsState, BidImprovementType } from './settings.interfaces';

export const defaultSettingsState: SettingsState = {
    isInEditMode: false,
    isLoaded: false,
    isLoading: false,
    model: {
        bidImprovementType: null,
        endDateTime: null,
        minBidImprovement: null,
        startDateTime: null,
        timeExtension: null,
    },
};

export const settingsStateMock: SettingsState = {
    isInEditMode: false,
    isLoaded: true,
    isLoading: false,
    model: {
        bidImprovementType: BidImprovementType.unit,
        endDateTime: '2016-07-01T15:00:00.000Z',
        minBidImprovement: 100,
        startDateTime: '2016-07-01T13:00:00.000Z',
        timeExtension: 300,
    },
};
