import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotificationsService {
    private _count: number = 0;

    constructor(
        private _toastrService: ToastrService,
        private _translateService: TranslateService,
    ) { }

    public info(
        description?: string,
        interpolateParams?: Object | boolean,
        isAutoClose?: boolean,
    ): void {
        description = this.translateDescription(description, interpolateParams);

        let disableTimeOutParam: boolean = true;
        if (isAutoClose) {
            disableTimeOutParam = false;
        }

        const toastr = this._toastrService.info(description, '', {
            disableTimeOut: disableTimeOutParam,
        });

        this.countToastrs(toastr);
    }

    public warning(description?: string, interpolateParams?: Object | boolean): void {
        description = this.translateDescription(description, interpolateParams);

        const toastr = this._toastrService.warning(description);
        this.countToastrs(toastr);
    }

    public error(description?: string, interpolateParams?: Object | boolean): void {
        if (!description.startsWith("200 - OK")) {
            description = this.translateDescription(description, interpolateParams);

            const toastr = this._toastrService.error(description);
            this.countToastrs(toastr);
        }
    }

    public countToastrs(toastr: any): void {
        toastr.onShown.subscribe(() => {
            this._count++;
        });

        toastr.onHidden.subscribe(() => {
            this._count--;
        });
    }

    public getCount(): number {
        return this._count;
    }

    public closeAll(): void {
        this._toastrService.clear();
    }

    private translateDescription(
        description?: string,
        interpolateParams?: Object | boolean,
    ): string {
        if (interpolateParams !== false) {
            return this._translateService.instant(description, interpolateParams);
        }

        return description;
    }
}
