import {
    SupplierJoinedAuctionSocketsResponseModel,
    SupplierLeftAuctionSocketsResponseModel,
    SupplierRespondedToPurchaserAsJoinedAuctionSocketsResponseModel,
    SupplierRespondedAsOnlineSocketsResponseModel,
} from './join-auction.interfaces';
import { BaseSocketsService } from './../../core/shared/base-sockets.service';
import { SocketListenHook, SocketEmitHook } from './../../core/shared/base-sockets.interfaces';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class JoinAuctionSocketsService {

    constructor(
        private _baseSocketsService: BaseSocketsService,
    ) {}

    public joinAuction(auctionId: number): void {
        this._baseSocketsService.send(SocketEmitHook.joinAuction, auctionId);
    }

    public leaveAuction(auctionId: number): void {
        this._baseSocketsService.send(SocketEmitHook.leaveAuction, auctionId);
    }

    public supplierJoinedAuction(): Observable<SupplierJoinedAuctionSocketsResponseModel> {
        return this._baseSocketsService.get(SocketListenHook.supplierJoinedAuction);
    }

    public supplierLeftAuction(): Observable<SupplierLeftAuctionSocketsResponseModel> {
        return this._baseSocketsService.get(SocketListenHook.supplierLeftAuction);
    }

    // observed by purchaser to get all online suppliers one by one
    public supplierRespondedToPurchaserAsJoinedAuction()
    : Observable<SupplierRespondedToPurchaserAsJoinedAuctionSocketsResponseModel> {
        return this._baseSocketsService
            .get(SocketListenHook.supplierRespondedToPurchaserAsJoinedAuction);
    }

    // observed by supplier. When got this event, send socket event to backend
    // to inform that supplier is online
    public requestIfSupplierJoinedAuction(): Observable<void> {
        return this._baseSocketsService.get(SocketListenHook.requestIfSupplierJoinedAuction);
    }

    public respondAsSupplierJoinedAuction(auctionId: number): void {
        this._baseSocketsService.send(
            SocketEmitHook.respondAsSupplierJoinedAuction,
            auctionId,
            );
    }

    public supplierIsOnline(auctionId: number): void {
        this._baseSocketsService.send(
            SocketEmitHook.supplierIsOnline,
            auctionId,
        );
    }

    public supplierRespondedAsOnline(): Observable<SupplierRespondedAsOnlineSocketsResponseModel> {
        return this._baseSocketsService
            .get(SocketListenHook.supplierRespondedAsOnline);
    }

}
