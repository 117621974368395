import { BehaviorSubject ,  Observable } from 'rxjs';

export abstract class BaseStore<T> {

    protected _state$: BehaviorSubject<T>;
    private _stateAsObservable$: Observable<T>;
    private _defaultState: T;

    constructor(defaultState: T) {
        this._state$ = new BehaviorSubject(defaultState);
        this._stateAsObservable$ = this._state$.asObservable();
        this._defaultState = defaultState;
    }

    public getState$(): Observable<T> {
        return this._stateAsObservable$;
    }

    public resetState(): void {
        this._updateState(this._defaultState);
    }

    protected _getState(): T {
        return this._state$.getValue();
    }

    protected _updateState(partOfState: T): void {
        const newState: T = Object.assign(
            {},
            this._getState(),
            <T>partOfState,
        );

        this._state$.next(newState);
    }

}
