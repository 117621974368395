import { UtilsModule } from './../utils/utils.module';
import { FormatModule } from './../format/format.module';
import { LoadersModule } from './../loaders/loaders.module';
import { AppTranslateModule } from './../app-translate/app-translate.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
    exports: [
        CommonModule,
        AppTranslateModule,
        LoadersModule,
        FormatModule,
        UtilsModule,
    ],
    imports: [
        CommonModule,
        LoadersModule,
        AppTranslateModule,
        FormatModule,
        UtilsModule,
    ],
})
export class SharedModule {

}
