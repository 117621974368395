import { defaultAuctionsState } from './auctions.data';
import { AuctionsApiService } from './auctions-api.service';
import { AuctionsState, CreateAuctionApiRequestModel } from './auctions.interfaces';
import { Injectable } from '@angular/core';
import { BaseStore } from './../../store/shared/base-store';

@Injectable()
export class AuctionsStore extends BaseStore<AuctionsState> {

    constructor(
        private _auctionsApiService: AuctionsApiService,
    ) {
        super(defaultAuctionsState);
    }

    public getAuctions(): void    {
        this._updateState(<AuctionsState>{
            isLoading: true,
        });

        this._auctionsApiService.getAuctions()
        .subscribe((response) => {
                this._updateState(<AuctionsState>{
                    auctions: response.auctions,
                    isLoaded: true,
                    isLoading: false,
                });
            },
            () => {
                this._updateState(<AuctionsState>{
                    isLoading: false,
                });
            });
    }

    public createAuction(
        lotId: number,
        model: CreateAuctionApiRequestModel,
        callback: () => void,
    ): void {

        const auctions = this._getState().auctions;

        this._updateState(<AuctionsState>{
            isCreatingAuction: true,
        });

        this._auctionsApiService.createAuction(lotId, model)
            .subscribe((response) => {
                const newAuction = Object.assign({}, response, {
                    animationState: 'isHighlighted',
                });

                this._updateState(<AuctionsState>{
                    auctions: [newAuction, ...auctions],
                    isCreatingAuction: false,
                });

                callback();
            },
            () => {
                this._updateState(<AuctionsState>{
                    isCreatingAuction: false,
                });
            });
    }

    public deleteAuction(auctionId: number): void {
        const auctions = this._getState().auctions;

        this._auctionsApiService.deleteAuction(auctionId)
            .subscribe(() => {
                const newAuctions = auctions.filter((x) => {
                    return x.id !== auctionId;
                });

                this._updateState(<AuctionsState>{
                    auctions: newAuctions,
                });
            });
    }

}
