
import { switchMap, delay, filter, distinctUntilChanged, take, map } from 'rxjs/operators';
import { UserStore } from './../../user/shared/user.store';
import { Subscription ,  Observable } from 'rxjs';
import { BaseSocketsService } from './../../core/shared/base-sockets.service';
import { AppInfoStore } from './../shared/app-info.store';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { SocketsConnectionState } from '../../core/shared/base-sockets.interfaces';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-connection-container',
    styleUrls: [
        './connection-container.styles.less',
    ],
    templateUrl: './connection-container.template.html',
})
export class ConnectionContainerComponent implements OnInit, OnDestroy {

    public connectionState$: Observable<SocketsConnectionState>;

    public hasFailedToInitReconnection$: Observable<boolean>;

    private _subscriptions: Subscription[] = [];

    constructor(
        private _baseSocketsService: BaseSocketsService,
        private _appInfoStore: AppInfoStore,
        private _userStore: UserStore,
    ) {}

    public ngOnInit(): void {
        const state$ = this._appInfoStore.getState$();
        this.connectionState$ = state$.pipe(map((s) => s.socketsConnectionState));
        this.hasFailedToInitReconnection$ = state$.pipe(map((s) => s.hasFailedToInitReconnection));
        this._showPopoverWhenNeeded();
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((s) => s.unsubscribe());
        this._appInfoStore.resetState();
    }

    public onTogglePopover(): void {
        this._appInfoStore.togglePopover();
    }

    public onReconnect(): void {
        this._baseSocketsService.reconnect();

        setTimeout(() => {
            // if after 100ms state hasn't changed from disconnected to some other,
            // it means reconnection initiation has failed (no internet connection or other reasons)

            this._appInfoStore.getState$().pipe(
                map((s) => s.socketsConnectionState),
                take(1))
                .subscribe((s) => {
                    if (s === SocketsConnectionState.disconnected) {
                        this._appInfoStore.setAsFailedToInitReconnection();
                    }
                });
        }, 100);
    }

    private _showPopoverWhenNeeded(): void {
        const s = this.connectionState$.pipe(
            distinctUntilChanged(),
            map(shouldShow),
            filter((x) => !!x),
            delay(500),
            switchMap(() => this._userStore.getState$().pipe(map((x) => x.user))),
            filter((user) => !!user))
            .subscribe(this._appInfoStore.showPopover);

        this._subscriptions.push(s);

        function shouldShow(state: SocketsConnectionState): boolean {
            switch (state) {
                case SocketsConnectionState.reconnecting:
                case SocketsConnectionState.disconnected:
                    return true;
                default:
                    return false;
            }
        }
    }

}
