import { DateParserFormatter } from './format/shared/date.parser.formatter';
import { AppTranslateModule } from './app-translate/app-translate.module';
import { AppTranslateApiService } from './app-translate/shared/app-translate-api.service';
import { AppTranslateStore } from './app-translate/shared/app-translate.store';
import { AppConfig } from './core/shared/app.config';
import { LoadersModule } from './loaders/loaders.module';
import { TendersModule } from './tenders/tenders.module';
import { HomeModule } from './home/home.module';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AuctionSupplierModule } from './auction-supplier/auction-supplier.module';
import { AuctionPurchaserModule } from './auction-purchaser/auction-purchaser.module';
import { UserModule } from './user/user.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NotificationsModule } from './notifications/notifications.module';
import { LayoutModule } from './layout/layout.module';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BidsModule } from './bids/bids.module';
import { AuctionsModule } from './auctions/auctions.module';
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { AppTranslateLoader } from './app-translate/shared/app-translate-loader.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';

export function AppTranslateLoaderFactory(
    appTranslateStore: AppTranslateStore,
    appTranslateApiService: AppTranslateApiService,
): AppTranslateLoader {
    return new AppTranslateLoader(appTranslateStore, appTranslateApiService);
}

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BidsModule,
        AuctionsModule,
        TendersModule,
        CoreModule,
        AuctionPurchaserModule,
        AuctionSupplierModule,
        LayoutModule,
        LoadersModule,
        HomeModule,
        NotificationsModule,
        UserModule,
        AppTranslateModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                deps: [AppTranslateStore, AppTranslateApiService],
                provide: TranslateLoader,
                useFactory: AppTranslateLoaderFactory,
            },
        }),
    ],
    providers: [
        AppConfig,
        [{ provide: NgbDateParserFormatter, useClass: DateParserFormatter }],
    ],
})
export class AppModule {}
