import {
    JoinAuctionSupplierContainerComponent,
} from './join-auction-supplier-container/join-auction-supplier-container.component';
import {
    JoinAuctionPurchaserContainerComponent,
} from './join-auction-purchaser-container/join-auction-purchaser-container.component';
import {
    JoinAuctionSharedContainerComponent,
} from './join-auction-shared-container/join-auction-shared-container.component';
import { JoinAuctionSocketsService } from './shared/join-auction-sockets.service';
import { JoinAuctionStore } from './shared/join-auction.store';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        JoinAuctionSharedContainerComponent,
        JoinAuctionPurchaserContainerComponent,
        JoinAuctionSupplierContainerComponent,
    ],
    exports: [
        JoinAuctionSharedContainerComponent,
        JoinAuctionPurchaserContainerComponent,
        JoinAuctionSupplierContainerComponent,
    ],
    imports: [
        SharedModule,
    ],
    providers: [
        JoinAuctionStore,
        JoinAuctionSocketsService,
    ],
})
export class JoinAuctionModule {

}
