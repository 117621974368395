import { Subscription } from 'rxjs';
import { AnswerAppCommunicationService } from './../shared/answer-app-communication.service';
import { BiddingStatusStore } from './../../bidding-status/shared/bidding-status.store';
import { AnswerAppApiService } from './../shared/answer-app-api.service';
import { Component, ChangeDetectionStrategy, OnDestroy,
    AfterViewInit, Input, ViewChild, ElementRef, ChangeDetectorRef,
} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-answer-app',
    styleUrls: [
    ],
    templateUrl: './answer-app.template.html',
})
export class AnswerAppComponent implements AfterViewInit, OnDestroy {

    @Input()
    public auctionId: number;

    @Input()
    public tenderId: number;

    @ViewChild('container')
    public containerEl: ElementRef;

    private _hasAppBootstrapped: boolean = false;

    private _hasLoadFailed: boolean = false;

    private _subscribtions: Subscription[] = [];

    constructor(
        private _answerAppApiService: AnswerAppApiService,
        private _biddingStatusStore: BiddingStatusStore,
        private _changeDetectorRef: ChangeDetectorRef,
        private _answerAppCommunicationService: AnswerAppCommunicationService,
    ) {}

    public ngAfterViewInit(): void {
        this._loadTendSignDocs();
        this._bindTendSignDocsEvents();
    }

    public ngOnDestroy(): void {
        this._subscribtions.forEach((x) => x.unsubscribe());
    }

    public shouldShowLoadingMsg(): boolean {
        return !this._hasAppBootstrapped && !this._hasLoadFailed;
    }

    public shouldShowErrorMsg(): boolean {
        return this._hasLoadFailed;
    }

    private _loadTendSignDocs = (): void => {
        this._answerAppApiService.getAnswerApp(this.auctionId, this.tenderId)
            .subscribe((response) => {

                const onSuccess = () => {
                    this._answerAppCommunicationService.setAppAssetsAsLoaded();
                };

                const onError = () => {
                    this._hasLoadFailed = true;
                    this._changeDetectorRef.markForCheck();
                };

                // TODO: consider security risks here
                this.containerEl.nativeElement.innerHTML = response.html;
                this._answerAppCommunicationService.setAppParams(response.params);

                if (!this._answerAppCommunicationService.areAppAssetsLoaded()) {
                    this._createScript(response.script, onSuccess, onError);
                } else {
                    this._answerAppCommunicationService.bootstrapApp();
                }
            });
    }

    private _bindTendSignDocsEvents = (): void => {
        const s1 = this._answerAppCommunicationService.onAppBootstrapped$()
            .subscribe(() => {
                this._hasAppBootstrapped = true;
                this._changeDetectorRef.detectChanges();
            });

        const s2 = this._answerAppCommunicationService.onAppRendered$()
            .subscribe(() => {
                // Need this in order to update sticky "add bid toolbar" position
                this._changeDetectorRef.markForCheck();
            });

        const s3 = this._answerAppCommunicationService.onAnswerChange$()
            .subscribe(this._updatePendingBidValue);

        this._subscribtions = [s1, s2, s3];
    }

    private _updatePendingBidValue = (): void => {
        this._answerAppApiService.getPendingBidValue(this.auctionId, this.tenderId)
            .subscribe((x) => {
                this._biddingStatusStore
                    .updateBiddingStatusAfterChangingPendingBid(x.pendingBidValue);
            });
    }

    private _createScript = (
        url: string,
        successCallback: () => any,
        errorCallback: () => any,
    ): void => {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');

        script.type = 'text/javascript';
        script.src = url;
        script.onload = successCallback;
        script.onerror = errorCallback;

        head.appendChild(script);
    }

}
