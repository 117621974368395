
import { map } from 'rxjs/operators';
import { HttpError } from './../../core/shared/api.interfaces';
import { defaultSettingsState } from './settings.data';
import { NotificationsService } from './../../notifications/shared/notifications.service';
import { DateTimeService } from './../../format/shared/date-time.service';
import { SettingsApiService } from './settings-api.service';
import {
    SettingsState,
    Settings,
    SettingsStoreSelectors,
    SettingsFormModel,
    CreateSettingsApiRequestModel,
} from './settings.interfaces';
import { Injectable } from '@angular/core';
import { BaseStore } from './../../store/shared/base-store';

@Injectable()
export class SettingsStore extends BaseStore<SettingsState> {

    public selectors: SettingsStoreSelectors;

    constructor(
        private _settingsApiService: SettingsApiService,
        private _dateTimeService: DateTimeService,
        private _notificationsService: NotificationsService,
    ) {
        super(defaultSettingsState);

        this.selectors = {
            areSettingsSet$: () => {
                return this.getState$().pipe(map((s) => !!s.model.startDateTime));
            },
        };
    }

    public openEditMode(): void {
        this._updateState(<SettingsState>{
            isInEditMode: true,
        });
    }

    public closeEditMode(): void {
        this._updateState(<SettingsState>{
            isInEditMode: false,
        });
    }

    public loadSettings(auctionId: number): void {

        this._updateState(<SettingsState>{
            isLoading: true,
        });

        this._settingsApiService.getSettings(auctionId)
            .subscribe((response) => {
                this._updateState(<SettingsState>{
                    isLoaded: true,
                    isLoading: false,
                    model: response,
                });
            },
            (error: HttpError) => {
                const isLoaded = error.status === 404 ? true : false;

                this._updateState(<SettingsState>{
                    isLoaded,
                    isLoading: false,
                });
            });
    }

    public syncSettings(auctionId: number): void {
        this._settingsApiService.getSettings(auctionId)
            .subscribe((response) => {
                if (!this._areSettingsEqual(this._getState().model, response)) {
                    this.useNewSettings(response);
                }
            });
    }

    public createSettings(auctionId: number, newSettings: Settings): void {
        this._createOrUpdateSettings(auctionId, newSettings, true);
    }

    public updateSettings(auctionId: number, newSettings: SettingsFormModel): void {
        this._createOrUpdateSettings(auctionId, newSettings, false);
    }

    public useNewSettings(settings: Settings): void {
        this._updateState(<SettingsState>{
            model: settings,
        });

        this._notificationsService.info('notifications.supplier.settings_updated');
    }

    public prolong(newEndDateTime: string): void {
        const state = this._state$.getValue();
        const newModel = Object.assign({}, state.model, {
            endDateTime: newEndDateTime,
        });

        this._updateState(<SettingsState>{
            model: newModel,
        });

        const msg = 'notifications.all.auction_prolonged';
        const formatedNewEndDateTime = this._dateTimeService
            .getFormatedDateTime(newEndDateTime);
        const minutes = state.model.timeExtension / 60;
        this._notificationsService.info(msg, {
            minutes,
            newEndDateTime: formatedNewEndDateTime,
        });
    }

    private _createOrUpdateSettings(
        auctionId: number,
        newSettings: Settings | SettingsFormModel,
        isCreateAction: boolean,
    ): void {
        this._updateState(<SettingsState>{
            isLoading: true,
        });

        const action = isCreateAction
            ? this._settingsApiService.createSettings(
                auctionId,
                <CreateSettingsApiRequestModel>newSettings,
            )
            : this._settingsApiService.updateSettings(auctionId, newSettings);

        action.subscribe((response) => {
            this._updateState(<SettingsState>{
                isInEditMode: false,
                isLoading: false,
                model: response,
            });
        },
        () => {
            this._updateState(<SettingsState>{
                isLoading: false,
            });
        });
    }

    private _areSettingsEqual = (s1: Settings, s2: Settings): boolean => {
        return JSON.stringify(s1) === JSON.stringify(s2);
    }

}
