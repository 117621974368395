import { RouterModule } from '@angular/router';
import { TendersApiService } from './shared/tenders-api.service';
import { TendersStore } from './shared/tenders.store';
import { TendersListComponent } from './tenders-list/tenders-list.component';
import { TendersContainerComponent } from './tenders-container/tenders-container.component';
import { TendersPageComponent } from './tenders-page/tenders-page.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        TendersPageComponent,
        TendersContainerComponent,
        TendersListComponent,
    ],
    exports: [
        TendersPageComponent,
    ],
    imports: [
        SharedModule,
        RouterModule,
    ],
    providers: [
        TendersStore,
        TendersApiService,
    ],
})
export class TendersModule {

}
