
import { throwError,  Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { AppConfig } from './../../core/shared/app.config';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppTranslateApiService {

    private _apiUrl: string;

    constructor(
        private _http: HttpClient,
        config: AppConfig,
    ) {
        this._apiUrl = config.apiServerUrl;
    }

    public getTranslationsData(langKey: string): Observable<any> {
        const url = this._apiUrl + `translations/${langKey}`;

        // Can't use BaseApiService here as it would cause circular dependency
        // (BaseApiService uses TranslateModule)
        return this._http.get(url).pipe(
            map((res) => res),
            catchError(() => {
                return throwError({});
            }));
    }

}
