<div class="modal-header">
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="onCancelClick()">
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{'dialog.confirm.title' | translate}}</h4>
</div>

<div class="modal-body" [innerHTML]="question | translate"></div>

<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onYesClick()" #yesBtn data-qa="dialogConfirmBtn">
        {{'common.btn.yes' | translate}}
    </button>

    <button type="button" class="btn btn-default" (click)="onNoClick()" data-qa="dialogCancelBtn">
        {{'common.btn.no' | translate}}
    </button>
</div>
