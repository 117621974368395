import { RedirectionService } from './../../app-routing/shared/redirection.service';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-alert-page',
    templateUrl: './alert-page.template.html',
})
export class AlertPageComponent {

    @Input()
    public title: string;

    @Input()
    public text: string;

    @Input()
    public iconType: 'success' | 'info' | 'warning' | 'error';

    constructor(
        private _redirectionService: RedirectionService,
    ) {}

    public goHome(): void {
        this._redirectionService.redirectToHome();
    }

}
