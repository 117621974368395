
import { of,  Observable } from 'rxjs';
import {
    GetAnswerAppApiResponseModel,
    GetPendingBidValueApiResponseModel,
} from './answer-app.interfaces';
import { Injectable } from '@angular/core';
import { BaseApiService } from '../../core/shared/base-api.service';

@Injectable()
export class AnswerAppApiService {

    constructor(
        private _apiService: BaseApiService,
    ) {}

    public getAnswerApp(
        auctionId: number,
        tenderId: number,
    ): Observable<GetAnswerAppApiResponseModel> {

        // let url = this._getUrl(auctionId, tenderId);
        // url += `answershtml?docsProjectId=3019&docsTenderId=4014`;
        // return this._apiService.get(url);

        return of({
            html: '<div id="ng-app" data-ng-strict-di><tsd-app-supply></tsd-app-supply></div>',
            // tslint:disable:object-literal-sort-keys
            params: {
                sessionToken: 'tsuumk_5f83v6l2mbsomlqm836x5a-f489nepq6ed4',
                projectId: 9,
                documentId: 40,
                tenderId: 9,
                baseUri: 'http://localhost:49915/api',
                language: 'en',
                activeMode: 'Default',
                selectedTenderIds: null,
                documentType: 'Specification',
                selectedTenderResultsLotId: null,
                selectedTenderId: null,
                selectedDocumentFilter: null,
                selectedLotFilter: null,
                selectedRequirementFilter: '',
                isReadOnly: false,
                isWeightOnly: false,
                isCommentOnly: false,
                isChooseLotsDialogVisible: false,
                appType: 'Supply',
                isLightVersion: true,
            },
            script: 'http://localhost:49915/api/assets?id=appSupplyAsync.js',
        });
    }

    public getPendingBidValue(
        auctionId: number,
        tenderId: number,
    ): Observable<GetPendingBidValueApiResponseModel> {

        let url = this._getUrl(auctionId, tenderId);
        url +=  `recalculatedbid?docsProjectId=1009&docsTenderId=1009`;

        return this._apiService.get(url);
    }

    private _getUrl(auctionId: number, tenderId: number): string    {
        return `auctions/${auctionId}/tenders/${tenderId}/`;
    }

}
