
import { throwError } from 'rxjs';
import { pubSubEvents } from './pub-sub-events.values';
import { PubSubService } from './../../pub-sub/shared/pub-sub.service';
import { TranslateService } from '@ngx-translate/core';
import { ErrorPageService } from './../../layout/shared/error-page.service';
import { HttpError } from './api.interfaces';
import { NotificationsService } from './../../notifications/shared/notifications.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorHandlingService {

    constructor(
        private _notificationsService: NotificationsService,
        private _errorPageService: ErrorPageService,
        private _translateService: TranslateService,
        private _pubSubService: PubSubService,
    ) { }

    public handleApiError(error: HttpError): any {
        if (!error || error.status === 404) {
            return throwError(error);
        }

        if (error.status === 401) {
            this._pubSubService.pub(pubSubEvents.userSessionExpired);
            return throwError(error);
        } else if (error.status === 403) {
            this._errorPageService.showNotAuthorized();
            return throwError(error);
        }

        const notificationMsg = this._getErrorNotificationMsg(error);

        if (notificationMsg) {
            this._notificationsService.error(notificationMsg);
        }

        return throwError(error);
    }

    private _getErrorNotificationMsg(error: any): string {
        const modelMessages = this._getErrorModelMessages(error);

        if (modelMessages.length) {
            return modelMessages.join('<br>---<br>');
        }

        return this._getErrorStatusMessage(error);
    }

    private _getErrorModelMessages(error: any): string[] {
        const messages: string[] = [];

        try {
            const errorModel = error.error;

            if (errorModel.errors) {
                errorModel.errors.forEach((err: any) => {
                    for (const key in err) {
                        if (err.hasOwnProperty(key)) {
                            const keyErrors = err[key];
                            keyErrors.forEach((keyError: any) => {
                                const msg = key ? `${key}: ${keyError}` : keyError;
                                messages.push(msg);
                            });
                        }
                    }
                });
            }
        } catch (e) {
            //
        }

        return messages;
    }

    private _getErrorStatusMessage(error: any): string {
        if (error.status === 0) {
            // can happen when server or internet connection is down

            const untranslatedMsg = 'notifications.api_error.server_or_network_problem';
            const translatedMsg = this._translateService.instant(untranslatedMsg);
            const offlineMultilingualMsg = `Request failed due to a network hiccup
                / Begäran misslyckades på grund av ett tillfälligt nätverksfel`;

            const msg = untranslatedMsg === translatedMsg
                ? offlineMultilingualMsg // can happen when offline and no translations loaded yet
                : translatedMsg;

            return msg;
        }

        if (error.status && error.statusText) {
            return `${error.status} - ${error.statusText}<br>Url: ${error.url}`;
        }

        return '';
    }

}
