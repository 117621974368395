import { appRoutesPaths } from './../../app-routing/shared/app-routes.constants';
import { RedirectionService } from './../../app-routing/shared/redirection.service';
import {
    listItemAppearAnimation,
    listItemDisappearAnimation,
} from './../../core/shared/animations';
import { Auction } from './../shared/auctions.interfaces';
import {
    Component, Input, Output, EventEmitter, ChangeDetectionStrategy,
 } from '@angular/core';

@Component({
    animations: [
        listItemAppearAnimation,
        listItemDisappearAnimation,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-auctions-list',
    styleUrls: [
        './auctions-list.styles.less',
    ],
    templateUrl: './auctions-list.template.html',
})
export class AuctionsListComponent {

    @Input() public auctions: Auction[];

    @Output('onDelete')
    public deleteAuction: EventEmitter<number> = new EventEmitter<number>();

    constructor(
        private _redirectionService: RedirectionService,
    ) {}

    public getAuctionUrl(auctionId: number): string {
        return this._redirectionService
            .getRouteWithParams(appRoutesPaths.purchaser.auction, auctionId);
    }

    public onDelete(auctionId: number): void {
        this.deleteAuction.emit(auctionId);
    }

}
