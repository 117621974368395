import { TendersState, Tender } from './tenders.interfaces';

export const defaultTendersState: TendersState = {
    isLoaded: false,
    isLoading: false,
    tenders: [],
};

export const defaultTendersMocked: Tender[] = [
    {
        auctionId: 1,
        id: 1,
        procurementName: 'Dummy procurement name 1',
    },
    {
        auctionId: 2,
        id: 2,
        procurementName: 'Dummy procurement name 2',
    },
];
