import { GetTendersApiResponseModel } from './tenders.interfaces';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseApiService } from '../../core/shared/base-api.service';

@Injectable()
export class TendersApiService {

    constructor(
        private _apiService: BaseApiService,
    ) {}

    public getTenders(): Observable<GetTendersApiResponseModel> {
        const url = 'tenders';
        return this._apiService.get(url);
    }

}
