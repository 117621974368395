import { ChartType } from '@eauc/charts/shared/chart.interfaces';

export enum BidsChartType {
    initialBids = 1,
    allBids,
    bySupplier,
}

export interface BidsChartTypeObj {
    btnTitle: string;
    chartType: ChartType;
    key: BidsChartType;
    title: string;
}

export interface ChartSliderConfig {
    connect?: boolean[];
    keyboard?: boolean;
    margin?: number;
    range?: {
        max: number;
        min: number;
    };
    start?: number[];
    step?: number;
    tooltips?: ChartTooltipFormatter[];
}

export interface ChartTooltipFormatter {
    from: (isoDate: string) => number;
    to: (relativeSliderValue: number) => string;
}

export interface ChartItem {
    datasetIndex: number;
    index: number;
    x: number;
    xLabel: number | string;
    y: number;
    yLabel: number;
}

export interface ChartDatasetItem {
    supplierName: string;
    x: number;
    y: number;
}
