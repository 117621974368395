import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Params, ActivatedRoute } from '@angular/router';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './auction-purchaser-page.template.html',
})
export class AuctionPurchaserPageComponent implements OnInit {

    public auctionId: number;

    constructor(
        private _activatedRoute: ActivatedRoute,
    ) {}

    public ngOnInit(): void {
        this._activatedRoute.params.forEach((params: Params) => {
            this.auctionId = +params.auctionId;
        });
    }

}
