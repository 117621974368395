export enum SocketListenHook {
    settingsUpdated = <any>'settingsUpdated',
    auctionProlonged = <any>'auctionProlonged',
    newBidArrived = <any>'newBidArrived',
    competitorSubmittedBid = <any>'competitorSubmittedBid',
    supplierRespondedToPurchaserAsJoinedAuction =
    <any>'supplierRespondedToPurchaserAsJoinedAuction',
    supplierJoinedAuction = <any>'supplierJoinedAuction',
    supplierLeftAuction = <any>'supplierLeftAuction',
    requestIfSupplierJoinedAuction = <any>'requestIfSupplierJoinedAuction',
    supplierRespondedAsOnline = <any>'supplierRespondedAsOnline',
}

export enum SocketEmitHook {
    joinAuction = <any>'joinAuction',
    leaveAuction = <any>'leaveAuction',
    respondAsSupplierJoinedAuction = <any>'respondAsSupplierJoinedAuction',
    supplierIsOnline = <any>'supplierIsOnline',
}

export enum SocketsConnectionState {
    connecting = 0,
    connected = 1,
    reconnecting = 2,
    disconnected = 4,
}

export interface SignalRSocketsConnectionResponseModel {
    oldState: number;
    newState: number;
}
