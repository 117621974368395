import { DataSyncService } from './../../data-sync/shared/data-sync.service';
import { BiddingStatusStore } from './../../bidding-status/shared/bidding-status.store';
import { defaultBiddingState } from './bidding.data';
import { BiddingState, BidModel } from './bidding.interfaces';
import { BiddingApiService } from './bidding-api.service';
import { Injectable } from '@angular/core';
import { BaseStore } from './../../store/shared/base-store';

@Injectable()
export class BiddingStore extends BaseStore<BiddingState> {

    constructor(
        private _biddingApiService: BiddingApiService,
        private _biddingStatusStore: BiddingStatusStore,
        private _dataSyncService: DataSyncService,
    ) {
        super(defaultBiddingState);
    }

    public loadBid(auctionId: number, tenderId: number): void {

        this._updateState(<BiddingState>{
            isLoading: true,
        });

        this._biddingApiService.getBid(auctionId, tenderId)
            .subscribe(
                (response) => {
                    this._updateState(<BiddingState>{
                        bidModel: response,
                        isLoaded: true,
                        isLoading: false,
                    });
                },
                () => {
                    this._updateState(<BiddingState>{
                        isLoading: false,
                    });
                });
    }

    public openInputInEditMode(): void {
        this._updateState(<BiddingState>{
            isInputInEditMode: true,
        });
    }

    public closeInputInEditMode(): void {
        this._updateState(<BiddingState>{
            isInputInEditMode: false,
        });
    }

    public updateBid(auctionId: number, tenderId: number, value: number): void {

        this._updateState(<BiddingState>{
            isInputInEditMode: false,
            isSavingBid: true,
        });

        this._biddingApiService.updateBid(auctionId, tenderId, value)
            .subscribe(
                (response) => {
                    this._updateState(<BiddingState>{
                        bidModel: response,
                        isSavingBid: false,
                    });

                    this._biddingStatusStore.
                        updateBiddingStatusAfterChangingPendingBid(response.value);
                },
                () => {
                    this._updateState(<BiddingState>{
                        isSavingBid: false,
                    });
                });
    }

    public syncBid(auctionId: number, tenderId: number): void {
        this._biddingApiService.getBid(auctionId, tenderId)
            .subscribe(
                (response) => {
                    if (!this._areBidModelsEqual(this._getState().bidModel, response)) {
                        this._updateState(<BiddingState>{
                            bidModel: response,
                        });
                    }
                }, this._dataSyncService.onSyncFail);
    }

    public submitBid(auctionId: number, tenderId: number): void {

        this._updateState(<BiddingState>{
            isSubmittingBid: true,
        });

        this._biddingApiService.submitBid(auctionId, tenderId)
            .subscribe(
                (response) => {
                    this._updateState(<BiddingState>{
                        bidModel: response.bid,
                        isSubmittingBid: false,
                    });

                    this._biddingStatusStore
                        .updateBiddingStatusAfterSubmittingBid(response.biddingStatus);
                },
                () => {
                    this._updateState(<BiddingState>{
                        isSubmittingBid: false,
                    });
                });
    }

    private _areBidModelsEqual = (b1: BidModel, b2: BidModel): boolean => {
        return JSON.stringify(b1) === JSON.stringify(b2);
    }

}
