<div class="eau-connection-container">

    <eau-connection-indicator
        [connectionState]="connectionState$"
        (togglePopover)="onTogglePopover()">
    </eau-connection-indicator>

    <eau-connection-popover
        [connectionState]="connectionState$"
        [hasFailedToInitReconnection]="hasFailedToInitReconnection$ | async"
        (reconnect)="onReconnect()"
        (togglePopover)="onTogglePopover()">
    </eau-connection-popover>

</div>
