import { trigger, transition, animate, keyframes, style } from '@angular/animations';

const successColor = '#ccebff';
const errorColor = '#D6565A';

export const listItemAppearAnimation = trigger('listItemAppearAnimation', [
    transition('void => isHighlighted', [
        animate(1000, keyframes([
            style({background: 'none',  offset: 0}),
            style({background: successColor,  offset: 0.3}),
            style({background: successColor,  offset: 0.5}),
            style({background: '#fff',  offset: 1}),
        ])),
    ]),
]);

export const listItemDisappearAnimation = trigger('listItemDisappearAnimation', [
    transition('* => void', [
        animate(500, keyframes([
            style({background: errorColor, offset: 0, opacity: 1 }),
            style({background: errorColor, offset: 0.2, opacity: 1}),
            style({offset: 1, opacity: 0}),
        ])),
    ]),
]);

export const shakeAnimation = trigger('shakeAnimation', [
    transition('* => isShaked', [
        animate(500, keyframes([
            style({ offset: 0, transform: 'translateX(0)' }),
            style({ offset: 0.1, transform: 'translateX(-5px)' }),
            style({ offset: 0.3, transform: 'translateX(5px)' }),
            style({ offset: 0.5, transform: 'translateX(-5px)' }),
            style({ offset: 0.7, transform: 'translateX(5px)' }),
            style({ offset: 0.9, transform: 'translateX(-5px)' }),
            style({ offset: 1, transform: 'translateX(0)'}),
        ])),
    ]),
]);

export const highlightElementAnimation = trigger('highlightElementAnimation', [
    transition('* => isHighlighted', [
        animate(1000, keyframes([
            style({background: 'none',  offset: 0}),
            style({background: successColor,  offset: 0.3}),
            style({background: successColor,  offset: 0.5}),
            style({background: 'none',  offset: 1}),
        ])),
    ]),
]);
