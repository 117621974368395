<form class="eau-login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">

    <div class="alert alert-warning alert-sm"
        [@shakeAnimation]="isShaked"
        *ngIf="shouldShowValidationAlert()">
        <span class="vismaicon vismaicon-filled vismaicon-warning"></span>
        {{'login.failed' | translate}}
    </div>

    <div class="eau-login-form--control">
        <input type="text"
            id="eau-username"
            class="eau-login-form--input"
            placeholder="{{'common.username' | translate}}"
            title="{{'common.username' | translate}}"
            data-qa="inputUsername"
            formControlName="username"
            #usernameInput>

        <label for="eau-username" class="eau-login-form--icon eau-login-form--icon-username"
            title="{{'common.username' | translate}}">
        </label>
    </div>

    <div class="eau-login-form--control">
        <input type="password"
            id="eau-password"
            class="eau-login-form--input"
            placeholder="{{'common.password' | translate}}"
            title="{{'common.password' | translate}}"
            data-qa="inputPassword"
            formControlName="password">

        <label for="eau-password" class="eau-login-form--icon eau-login-form--icon-password"
            title="{{'common.password' | translate}}">
        </label>
    </div>

    <eau-loader-btn [shouldShowLoader]="isLoggingIn">
        <button type="submit"
            class="btn btn-default eau-login-form--btn"
            data-qa="loginButton"
            [disabled]="isLoggingIn">
            {{'common.btn.login' | translate}}
        </button>
    </eau-loader-btn>

</form>
