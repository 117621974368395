import { TranslateService } from '@ngx-translate/core';
import { DialogService } from './../../dialog/shared/dialog.service';
import { AuctionStatus } from './../../auction-info/shared/auction-info.interfaces';
import { Subscription ,  Observable } from 'rxjs';
import {
    Settings, BidImprovementOption, SettingsForm, BidImprovementType, SettingsFormModel,
} from './../shared/settings.interfaces';
import { DateTimeService } from './../../format/shared/date-time.service';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    OnDestroy,
    Output,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from '@angular/core';
import {
    FormBuilder, FormGroup, Validators,
    FormControl, AbstractControl, ValidatorFn,
} from '@angular/forms';
import { timeValidator } from '../../app-forms/app-validators/time.validator';
import { integerValidator } from '../../app-forms/app-validators/integer.validator';
import { dateValidator } from '../../app-forms/app-validators/date.validator';
import * as moment from 'moment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomValidators } from 'ng2-validation';
import { lessThanValidator } from '../../app-forms/app-validators/lessThan.validator';
import { greaterThanValidator } from '../../app-forms/app-validators/greaterThan.validator';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-settings-form',
    styleUrls: [
        './settings-form.styles.less',
    ],
    templateUrl: './settings-form.template.html',
})
export class SettingsFormComponent implements OnInit, OnDestroy {

    @Input()
    public settings: Settings;

    @Input()
    public areSettingsSet: boolean;

    @Input()
    public isLoading: boolean;

    @Input()
    public unit: string;

    @Input()
    public auctionStatusAsync: Observable<AuctionStatus>;

    @Output()
    public onSave: EventEmitter<SettingsFormModel> = new EventEmitter<SettingsFormModel>();

    @Output()
    public onCancel: EventEmitter<void> = new EventEmitter<void>();

    public settingsForm: FormGroup;

    public bidImprovementTypes: BidImprovementOption[];

    public selectedBidImprovementType: BidImprovementType;

    public initialStartTime: string;

    public initialEndTime: string;

    public auctionStatus: AuctionStatus;

    private _subscriptions: Subscription[] = [];

    constructor(
        private _formBuilder: FormBuilder,
        private _dateTimeService: DateTimeService,
        private _dialogService: DialogService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _translateService: TranslateService,
    ) { }

    public ngOnInit(): void {
        this.bidImprovementTypes = this._getBidImprovementTypes();
        this.selectedBidImprovementType = this.areSettingsSet
            ? this.settings.bidImprovementType
            : this.bidImprovementTypes[0].type;

        this._buildForm();

        const s = this.auctionStatusAsync.subscribe((status) => {
            this.auctionStatus = status;
            this._handleInputsDisabling();
        });

        const s1 = this._translateService.onLangChange.subscribe(() => {
            const startDate = this.settingsForm.get('startDate');
            const endDate = this.settingsForm.get('endDate');

            this.settingsForm.patchValue({
                ['startDate']: startDate.value,
                ['endDate']: endDate.value,
            });
        });

        this._subscriptions.push(s, s1);
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((x) => x.unsubscribe());
    }

    public onSaveBtnClick(): void {
        if (this.auctionStatus === AuctionStatus.finished) {
            this._dialogService.alert('settings.cannot_edit_auction_finished');
            return;
        }

        // there are validation rules that depends on current time
        // if user adds valid data to form and then waits, it can become invalid after some time,
        // therefore need to revalidate form before submitting
        this.settingsForm.updateValueAndValidity();

        if (this.settingsForm.invalid) {
            return;
        }

        const newSettings = this._getNewSettingsFromFormValue();
        this.onSave.emit(newSettings);
    }

    public onCancelBtnClick(): void {
        this.onCancel.emit();
    }

    public onSelectBidImprovementType(option: BidImprovementOption): void {
        this.selectedBidImprovementType = option.type;
        const minBidImprovementControl = this.settingsForm.get('minBidImprovement');
        minBidImprovementControl.setValidators(this._getMinBidImprovementValidators());
        minBidImprovementControl.markAsDirty();
        minBidImprovementControl.updateValueAndValidity();
    }

    public onTimeChange(value: string, isEndTime: boolean): void {
        const newValue = this._dateTimeService.getFormatedTime(value);
        const formValues: SettingsForm = this.settingsForm.getRawValue();
        let hasChanged = false;

        if (isEndTime && formValues.endTime !== newValue) {
            formValues.endTime = newValue;
            hasChanged = true;
        }

        if (!isEndTime && formValues.startTime !== newValue) {
            formValues.startTime = newValue;
            hasChanged = true;
        }

        if (hasChanged) {
            this.settingsForm.patchValue(formValues);
            this.settingsForm.get(isEndTime ? 'endTime' : 'startTime').markAsDirty();
        }
    }

    public isSubmitBtnDisabled(): boolean {
        if (this.settingsForm.invalid) {
            return true;
        }

        if (this.isLoading) {
            return true;
        }

        if (this.areSettingsSet && this.settingsForm.pristine) {
            return true;
        }

        return false;
    }

    public isStartDateControlDisabled(): boolean {
        return this.settingsForm.get('startDate').disabled;
    }

    public isStartTimeControlDisabled(): boolean {
        return this.settingsForm.get('startTime').disabled;
    }

    private _getBidImprovementTypes(): BidImprovementOption[] {
        return [
            {
                title: '%',
                type: BidImprovementType.percentage,
            },
            {
                title: this.unit,
                type: BidImprovementType.unit,
            },
        ];
    }

    private _getNewSettingsFromFormValue(): SettingsFormModel {
        const values: SettingsForm = this.settingsForm.value;
        const formatedEndDate = this._dateTimeService.getDestructedDate(values.endDate);
        const isoEndDateTime = this._dateTimeService
            .getIsoDate(formatedEndDate, values.endTime);

        const model: SettingsFormModel = {
            bidImprovementType: this.selectedBidImprovementType,
            endDateTime: isoEndDateTime,
            minBidImprovement: values.minBidImprovement,
            timeExtension: parseInt(values.timeExtension + '', 10) * 60,
        };

        if (values.startDate) {
            const formatedStartDate = this._dateTimeService.getDestructedDate(values.startDate);
            const isoStartDateTime = this._dateTimeService
                .getIsoDate(formatedStartDate, values.startTime);

            model.startDateTime = isoStartDateTime;
        }

        return model;
    }

    private _buildForm(): void {
        const isoEndDate = this.areSettingsSet
            ? this.settings.endDateTime
            : this._getdefaultISODate();

        const endDate = this._dateTimeService.getStructedDate(isoEndDate);

        const isoEndTime = this.areSettingsSet
            ? this.settings.endDateTime
            : this._getdefaultISOTime(true);

        const endTime = this._dateTimeService.getFormatedTime(isoEndTime);

        const minBidImprovement = this.areSettingsSet ?
            this.settings.minBidImprovement : 10;

        const isoStartDate = this.areSettingsSet
            ? this.settings.startDateTime
            : this._getdefaultISODate();

        const startDate = this._dateTimeService.getStructedDate(isoStartDate);

        const isoStartTime = this.areSettingsSet
            ? this.settings.startDateTime
            : this._getdefaultISOTime(false);

        const startTime = this._dateTimeService.getFormatedTime(isoStartTime);

        const timeExtension = this.areSettingsSet ?
            this.settings.timeExtension / 60 : 2;

        this.settingsForm = this._formBuilder.group({
            endDate: [
                endDate,
                [Validators.required, dateValidator],
            ],
            endTime: [
                endTime,
                [Validators.required, timeValidator],
            ],
            minBidImprovement: [
                minBidImprovement,
                this._getMinBidImprovementValidators(),
            ],
            startDate: [
                startDate,
                [Validators.required, dateValidator],
            ],
            startTime: [
                startTime,
                [Validators.required, timeValidator],
            ],
            timeExtension: [
                timeExtension,
                [
                    Validators.required,
                    CustomValidators.number,
                    integerValidator,
                    greaterThanValidator(0),
                ],
            ],
        }, {
            validator: this._relatedInputsValidator(),
        });

        this.initialStartTime = isoStartTime;
        this.initialEndTime = isoEndTime;
    }

    private _relatedInputsValidator(): (group: FormGroup) => void {
        const self = this;

        return (group: FormGroup): void => {
            const startDateControl = group.get('startDate');
            const startDateObj: NgbDateStruct = startDateControl.value;
            const endDateControl = group.get('endDate');
            const endDateObj: NgbDateStruct = endDateControl.value;
            const startDate = this._dateTimeService.getDestructedDate(startDateObj);
            const endDate = this._dateTimeService.getDestructedDate(endDateObj);
            const startDateStamp = moment(startDate, 'L').unix();
            const endDateStamp = moment(endDate, 'L').unix();
            const startTimeControl = group.get('startTime');
            const endTimeControl = group.get('endTime');
            const startTimeStamp = moment(startTimeControl.value, 'HH:mm').unix();
            const endTimeStamp = moment(endTimeControl.value, 'HH:mm').unix();

            validateRelatedDates();
            validateRelatedTimes();

            function validateRelatedDates(): void {

                if (areDefaultDatesRulesFulfilledAndNotEmpty()
                    && isStartDateLaterThanEndDate()) {
                    endDateControl.setErrors({ datePeriod: true });
                    endDateControl.markAsDirty();
                } else {
                    endDateControl.setErrors(endDateControl.validator(endDateControl));
                }

                function areDefaultDatesRulesFulfilledAndNotEmpty(): boolean {
                    const startDateFormControl = new FormControl(startDateObj, dateValidator);
                    const endDateFormControl = new FormControl(endDateObj, dateValidator);

                    if (Validators.required(startDateFormControl)
                        || Validators.required(endDateFormControl)) {
                        return false;
                    }

                    if (dateValidator(startDateFormControl)
                        || dateValidator(endDateFormControl)) {
                        return false;
                    }

                    return true;
                }

                function isStartDateLaterThanEndDate(): boolean {
                    return startDateStamp > endDateStamp;
                }
            }

            function validateRelatedTimes(): void {
                let isStartTimeValid = true;
                let isEndTimeValid = true;

                if (areDefaultTimesRulesFulfilled()) {
                    if (isStartTimeHigherThanOrEqualToEndTime()) {
                        endTimeControl.setErrors({ timePeriod: true });
                        endTimeControl.markAsDirty();
                        isEndTimeValid = false;
                    }

                    if (self._canStartDateTimeControlsBeEdited()
                        && !isStartTimeLaterThanCurrent()) {
                        startTimeControl.setErrors({ laterThanCurrentTime: true });
                        startTimeControl.markAsDirty();
                        isStartTimeValid = false;
                    }

                    if (!isEndTimeLaterThanCurrent()) {
                        endTimeControl.setErrors({ laterThanCurrentTime: true });
                        endTimeControl.markAsDirty();
                        isEndTimeValid = false;
                    }
                }

                if (isStartTimeValid) {
                    startTimeControl.setErrors(startTimeControl.validator(startTimeControl));
                }

                if (isEndTimeValid) {
                    endTimeControl.setErrors(endTimeControl.validator(endTimeControl));
                }

                function areDefaultTimesRulesFulfilled(): boolean {
                    const startTimeFormControl = new FormControl(
                        startTimeControl.value,
                        timeValidator,
                    );
                    const endTimeFormControl = new FormControl(endTimeControl.value, timeValidator);

                    if (timeValidator(startTimeFormControl)
                        || timeValidator(endTimeFormControl)) {
                        return false;
                    }

                    return true;
                }

                function isStartTimeHigherThanOrEqualToEndTime(): boolean {
                    if (startDateStamp !== endDateStamp) {
                        return false;
                    }

                    return startTimeStamp >= endTimeStamp;
                }

                function isStartTimeLaterThanCurrent(): boolean {
                    return isTimeLaterThanCurrent(startDateControl, startTimeControl);
                }

                function isEndTimeLaterThanCurrent(): boolean {
                    return isTimeLaterThanCurrent(endDateControl, endTimeControl);
                }

                function isTimeLaterThanCurrent(
                    dateControl: AbstractControl,
                    timeControl: AbstractControl,
                ): boolean {
                    const formatedDate = self._dateTimeService
                        .getDestructedDate(dateControl.value);

                    const isoDateTime = self._dateTimeService
                        .getIsoDate(formatedDate, timeControl.value);

                    const dateTimeUnix = moment(isoDateTime).unix();
                    const currentDateTimeUnix = moment(new Date().toISOString()).unix();

                    return dateTimeUnix > currentDateTimeUnix;
                }

            }
        };
    }

    private _getdefaultISODate(): string {
        return new Date().toISOString();
    }

    private _getdefaultISOTime(isEndTime: boolean): string {
        const oneHour = 60 * 60 * 1000;
        let time = new Date().getTime() + oneHour;

        if (isEndTime) {
            time += oneHour;
        }

        const date = new Date(time);
        date.setHours(date.getHours() + Math.ceil(date.getMinutes() / 60));
        date.setMinutes(0);

        return date.toISOString();
    }

    private _handleInputsDisabling(): void {
        const startDateControl = this.settingsForm.get('startDate');
        const startTimeControl = this.settingsForm.get('startTime');

        if (this._canStartDateTimeControlsBeEdited()) {
            startDateControl.enable();
            startTimeControl.enable();
        } else {
            startDateControl.disable();
            startTimeControl.disable();
        }

        // need to manually force change detection so date and time pickers
        // buttons disabled state is reflected in UI
        this._changeDetectorRef.markForCheck();
    }

    private _canStartDateTimeControlsBeEdited(): boolean {
        return this.auctionStatus === AuctionStatus.notSet
            || this.auctionStatus === AuctionStatus.notStarted;
    }

    private _getMinBidImprovementValidators(): ValidatorFn[] {
        const validators = [
            Validators.required,
            CustomValidators.number,
            integerValidator,
            greaterThanValidator(0),
        ];

        if (this.selectedBidImprovementType === BidImprovementType.percentage) {
            validators.push(lessThanValidator(100));
        }

        return validators;
    }

}
