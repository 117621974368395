import { AppTranslateService } from './../shared/app-translate.service';
import { Language } from './../shared/app-translate.interfaces';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'eau-languages-dropdown',
    styleUrls: [
        './languages-dropdown.styles.less',
    ],
    templateUrl: './languages-dropdown.template.html',
})
export class LanguagesDropdownComponent {

    @HostBinding('class.eau-languages-dropdown')
    public className: boolean = true;

    @Input()
    public title: string;

    @Input()
    public languages: Language[];

    constructor(
        private _appTranslateService: AppTranslateService,
    ) {}

    public onChooseLanguage(langKey: string): void {
        this._appTranslateService.setAppLanguage(langKey);
    }

}
