import { TendersApiService } from './tenders-api.service';
import { defaultTendersState } from './tenders.data';
import { TendersState } from './tenders.interfaces';
import { Injectable } from '@angular/core';
import { BaseStore } from './../../store/shared/base-store';

@Injectable()
export class TendersStore extends BaseStore<TendersState> {

    constructor(
        private _tendersApiService: TendersApiService,
    ) {
        super(defaultTendersState);
    }

    public getTenders(): void {
        this._updateState(<TendersState>{
            isLoading: true,
        });

        this._tendersApiService.getTenders()
            .subscribe((response) => {
                this._updateState(<TendersState>{
                    isLoaded: true,
                    isLoading: false,
                    tenders: response.tenders,
                });
            },
            () => {
                this._updateState(<TendersState>{
                    isLoading: false,
                });
            });
    }

}
