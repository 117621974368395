import { AuctionInfoState, AuctionStatus } from './auction-info.interfaces';

export const defaulAuctionInfotState: AuctionInfoState = {
    auctionModel: {
        biddingType: null,
        currency: null,
        id: null,
        procurementName: null,
        procurementOrganizationName: null,
        procurementReferenceNumber: null,
        unit: null,
    },
    auctionStatus: AuctionStatus.notSet,
    isLoaded: false,
    isLoading: false,
};

export const auctionInfotStateMock: AuctionInfoState = {
    auctionModel: {
        biddingType: null,
        currency: 'SEK',
        id: 1,
        procurementName: 'Dummy procurement name',
        procurementOrganizationName: 'Dummy organization name',
        procurementReferenceNumber: 'Dummy reference number',
        unit: 'SEK',
    },
    auctionStatus: AuctionStatus.notStarted,
    isLoaded: true,
    isLoading: false,
};
