// tslint:disable:object-literal-sort-keys

export const appRoutesChildPaths = {
    chart: 'chart',
};

export const appRoutesPaths = {
    root: '',
    login: 'login',
    notAuthorized: '403',
    noMatch: '**',
    purchaser: {
        default: 'purchaser/auctions',
        auctions: 'purchaser/auctions',
        auction: 'purchaser/auctions/:auctionId',
        auctionChart: `purchaser/auctions/:auctionId/${appRoutesChildPaths.chart}`,
    },
    supplier: {
        default: 'supplier/tenders',
        tenders: 'supplier/tenders',
        tender: 'supplier/auctions/:auctionId/tenders/:tenderId',
    },
    public: {
        root: 'public',
        cookies: 'public/cookies',
    },
};
