import { AnswerAppCommunicationService } from './shared/answer-app-communication.service';
import { AnswerAppApiService } from './shared/answer-app-api.service';
import {
    AnswerAppComponent,
} from './answer-app/answer-app.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [
        AnswerAppComponent,
    ],
    exports: [
        AnswerAppComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
    ],
    providers: [
        AnswerAppApiService,
        AnswerAppCommunicationService,
    ],
})
export class AnswerAppIntegrationModule {

}
