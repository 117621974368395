import { Component, Input, ElementRef, ViewChild, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    templateUrl: './alert.template.html',
})
export class AlertComponent implements OnInit {

    @Input()
    public text: string;

    @Input()
    public btnText: string;

    @ViewChild('okBtn', {static: true})
    public yesBtn: ElementRef;

    constructor(
        private _activeModal: NgbActiveModal,
    ) {}

    public ngOnInit(): void {
        // if not wrapped in timeout, scroll position changes
        setTimeout(() => {
            this.yesBtn.nativeElement.focus();
        });
    }

    public onOkClick(): void {
        this._activeModal.close();
    }

    public getBtnText(): string {
        return this.btnText || 'common.btn.ok';
    }

}
