
import { distinctUntilChanged, map } from 'rxjs/operators';
import { appRoutesPaths } from './../../app-routing/shared/app-routes.constants';
import { RedirectionService } from './../../app-routing/shared/redirection.service';
import { JoinAuctionStore } from './../../join-auction/shared/join-auction.store';
import { SettingsStore } from './../../settings/shared/settings.store';
import { BidsSocketsService } from './../shared/bids-sockets.service';
import { BidsStore } from './../shared/bids.store';
import { AuctionInfoStore } from './../../auction-info/shared/auction-info.store';
import { Subscription ,  Observable } from 'rxjs';
import { Component, OnInit, Input, OnDestroy, ViewChild,
    ChangeDetectionStrategy,
} from '@angular/core';
import { Bid, BidsTab } from '../shared/bids.interfaces';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-bids-container',
    styleUrls: [
        './bids-container.styles.less',
    ],
    templateUrl: './bids-container.template.html',
})
export class BidsContainerComponent implements OnInit, OnDestroy {

    @Input()
    public auctionId: number;

    @ViewChild('tabs', {static: true})
    public tabs: any;

    public bids$: Observable<Bid[]>;

    public isLoaded$: Observable<boolean>;

    public isLoading$: Observable<boolean>;

    public isChartLoaded$: Observable<boolean>;

    public unit$: Observable<string>;

    public startDateTime$: Observable<string>;

    public joinedSuppliers$: Observable<string[]>;

    public listTab: BidsTab = BidsTab.list;

    public chartTab: BidsTab = BidsTab.chart;

    private _unit: string;

    private _subscriptions: Subscription[] = [];

    constructor(
        private _bidsStore: BidsStore,
        private _bidsSocketsService: BidsSocketsService,
        private _joinAuctionStore: JoinAuctionStore,
        private _auctionInfoStore: AuctionInfoStore,
        private _settingsStore: SettingsStore,
        private _redirectionService: RedirectionService,
    ) {}

    public ngOnInit(): void {
        this.bids$ = this._bidsStore.getState$().pipe(map((state) => state.bids));
        this.isLoaded$ = this._bidsStore.getState$().pipe(map((state) => state.isLoaded));
        this.isChartLoaded$ = this._bidsStore.getState$().pipe(map((state) => state.isChartLoaded));
        this.unit$ = this._auctionInfoStore.getState$()
        .pipe(map((state) => state.auctionModel.unit));

        this.startDateTime$ = this._settingsStore.getState$().pipe(
            map((state) => state.model.startDateTime),
            distinctUntilChanged());

        this.joinedSuppliers$ = this._joinAuctionStore.getState$().pipe(
            map((x) => {
                return x.joinedSuppliers.map((supplier) => {
                    return supplier.name;
                });
            }),
            // only emit change when array of joined suppliers changed
            distinctUntilChanged(null, (arrayOfJoinedSuppliers) => {
                return JSON.stringify(arrayOfJoinedSuppliers);
            }));

        this._bidsStore.loadBids(this.auctionId);
        this._initSocketEvents();

        const s = this.unit$.subscribe((unit) => {
            this._unit = unit;
        });

        this._subscriptions.push(s);
    }

    public ngAfterViewInit(): void {
        this.tabs.classMap['nav-tabs-primary'] = true;
    }

    public ngOnDestroy(): void {
        this._bidsStore.resetState();
        this._subscriptions.forEach((s) => s.unsubscribe());
    }

    public onSelectTab(tab: BidsTab): void {
        this._bidsStore.setActiveTab(tab);

        if (tab === BidsTab.chart) {
            this._redirectionService.goToRoute(
                appRoutesPaths.purchaser.auctionChart,
                { skipLocationChange: true },
                this.auctionId,
            ).then(this._bidsStore.setChartAsLoaded);
        }
    }

    public isTabActive$(tab: BidsTab): Observable<boolean> {
        return this._bidsStore.getState$().pipe(map((state) => state.activeTab === tab));
    }

    private _initSocketEvents(): void {
        const s = this._bidsSocketsService
            .newBidArrived()
            .subscribe((bid) => {
                this._bidsStore.addBid(bid, this._unit);
            });

        this._subscriptions.push(s);
    }

}
