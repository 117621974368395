import {
    GetSettingsApiResponseModel,
    CreateSettingsApiRequestModel,
    UpdateSettingsApiRequestModel,
    CreateSettingsApiResponseModel,
    UpdateSettingsApiResponseModel,
} from './settings.interfaces';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../../core/shared/base-api.service';

@Injectable()
export class SettingsApiService {

    constructor(
        private _apiService: BaseApiService,
    ) {}

    public getSettings(auctionId: number): Observable<GetSettingsApiResponseModel> {
        const url = this._getUrl(auctionId);
        return this._apiService.get(url);
    }

    public createSettings(
        auctionId: number,
        model: CreateSettingsApiRequestModel,
    ): Observable<CreateSettingsApiResponseModel> {
        const url = this._getUrl(auctionId);
        return this._apiService.post(url, model);
    }

    public updateSettings(
        auctionId: number,
        model: UpdateSettingsApiRequestModel,
    ): Observable<UpdateSettingsApiResponseModel> {
        const url = this._getUrl(auctionId);
        return this._apiService.put(url, model);
    }

    private _getUrl(auctionId: number): string {
        return `auctions/${auctionId}/settings`;
    }

}
