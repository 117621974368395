import { RedirectionService } from './../../app-routing/shared/redirection.service';
import { UserApiService } from './user-api.service';
import { defaultUserState } from './user.data';
import { UserState, LoginApiRequestModel, User } from './user.interfaces';
import { Injectable } from '@angular/core';
import { BaseStore } from './../../store/shared/base-store';

@Injectable()
export class UserStore extends BaseStore<UserState> {

    constructor(
        private _userApiService: UserApiService,
        private _redirectionService: RedirectionService,
    ) {
        super(defaultUserState);
    }

    public login(model: LoginApiRequestModel): void {
        const user = this._getState().user;

        if (user) {
            // usually should not happen
            // can only happen if wrong error code was returned from api
            // or some other unexpected case
            this._redirectionService.redirectToDefault(user.role);
            return;
        }

        this._updateState(<UserState>{
            hasLoginFailed: false,
            isLoggingIn: true,
        });

        this._userApiService.login(model)
            .subscribe((response) => {
                this._updateState(<UserState>{
                    isIdentityChecked: true,
                    isLoggingIn: false,
                    user: response,
                });

                this._redirectionService.redirectAfterLogin(response.role);
            },
            () => {
                this._updateState(<UserState>{
                    hasLoginFailed: true,
                    isLoggingIn: false,
                });
            });
    }

    public logout = (): void => {
        this._updateState(<UserState>{
            isLoggingOut: true,
        });

        this._userApiService.logout()
            .subscribe(() => {
                this._updateState(<UserState>{
                    isLoggingOut: false,
                    user: null,
                });

                this._redirectionService.redirectAfterLogout();
            },
            () => {
                this._updateState(<UserState>{
                    isLoggingOut: false,
                });
            });
    }

    public getLoggedInUser(): Promise<User> {
        return new Promise((resolve, reject) => {
            const isIdentityChecked = this._getState().isIdentityChecked;

            if (isIdentityChecked) {
                resolve(this._getState().user);
                return;
            }

            this._userApiService.getLoggedInUser()
                .subscribe((user) => {
                    this._updateState(<UserState>{
                        isIdentityChecked: true,
                        user,
                    });

                    resolve(user);
                }, () => {
                    this._updateState(<UserState>{
                        isIdentityChecked: true,
                    });

                    resolve(null);
                });
        });
    }

    /**
     * Use this method when user is already unauthenticated (session expired)
     * and only unset of user data is needed
     */
    public unsetLoggedInUser(): void {
        this._updateState(<UserState>{
            user: null,
        });
    }

    public isUserLoggedIn(): boolean {
        return !!this._getState().user;
    }

    public isUserLoggingOut(): boolean {
        return this._getState().isLoggingOut;
    }
}
