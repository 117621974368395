
import { map } from 'rxjs/operators';
import { AuctionInfoStore } from './../shared/auction-info.store';
import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-auction-info-container',
    templateUrl: './auction-info-container.template.html',
})
export class AuctionInfoContainerComponent implements OnInit, OnDestroy {

    @Input()
    public auctionId: number;

    public procurementName$: Observable<string>;

    public procurementReferenceNumber$: Observable<string>;

    public procurementOrganizationName$: Observable<string>;

    public showLoader$: Observable<boolean>;

    constructor(
        private _auctionInfoStore: AuctionInfoStore,
    ) {}

    public ngOnInit(): void {
        const auctionInfoState$ = this._auctionInfoStore.getState$();

        this.procurementName$ = auctionInfoState$.pipe(
            map((state) => state.auctionModel.procurementName));
        this.procurementReferenceNumber$ = auctionInfoState$.pipe(
            map((state) => state.auctionModel.procurementReferenceNumber));
        this.procurementOrganizationName$ = auctionInfoState$.pipe(
            map((state) => state.auctionModel.procurementOrganizationName));
        this.showLoader$ = auctionInfoState$.pipe(
            map((state) => !state.isLoaded));

        this._auctionInfoStore.getAuctionInfo(this.auctionId);
    }

    public ngOnDestroy(): void {
        this._auctionInfoStore.resetState();
    }

}
