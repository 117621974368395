import { PubSubService } from './shared/pub-sub.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        PubSubService,
    ],
})
export class PubSubModule {

}
