
import { map } from 'rxjs/operators';
import {
    listItemDisappearAnimation,
} from './../../core/shared/animations';
import { PendingBid } from './../../bids/shared/bids.interfaces';
import { integerValidator } from './../../app-forms/app-validators/integer.validator';
import { Observable } from 'rxjs';
import { AuctionsStore } from './../shared/auctions.store';
import {
    CreateAuctionApiRequestModel,
    AuctionCreateFormModel,
} from './../shared/auctions.interfaces';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    animations: [
        listItemDisappearAnimation,
    ],
    styleUrls: [
        './create-auction-form-container.styles.less',
    ],
    templateUrl: './create-auction-form-container.template.html',
})
export class CreateAuctionFormContainerComponent implements OnInit {

    @Input()
    public title: string;

    public createAuctionForm: FormGroup;

    public isCreatingAuction$: Observable<boolean>;

    constructor(
        private _formBuilder: FormBuilder,
        private _activeModal: NgbActiveModal,
        private _auctionsStore: AuctionsStore,
    ) { }

    public ngOnInit(): void {
        this.isCreatingAuction$ = this._auctionsStore.getState$().pipe(
            map((state) => state.isCreatingAuction));

        this._buildForm();
    }

    public onSubmit(): void {
        const formValue: AuctionCreateFormModel = this.createAuctionForm.getRawValue();
        const model: CreateAuctionApiRequestModel = formValue;
        const lotId = +model.lotId;
        model.docsProjectId = +model.docsProjectId;
        delete model.lotId;
        this._auctionsStore.createAuction(lotId, model, this._activeModal.close.bind(this));
    }

    public onCancel(): void {
        this._activeModal.close();
    }

    public onAddBid(): void {
        const arrayControl = <FormArray>this.createAuctionForm.get('initialBids');
        const emptyBid: PendingBid = {
            supplier: '',
            supplierName: '',
            value: null,
        };

        const newGroup = this._getBidFormGroup(emptyBid);
        arrayControl.push(newGroup);
    }

    public onDeleteBid(index: number): void {
        const arrayControl = <FormArray>this.createAuctionForm.get('initialBids');
        arrayControl.removeAt(index);
    }

    public get initialBids(): FormArray {
        return this.createAuctionForm.get('initialBids') as FormArray;
    }

    public getCustomInputId(key: string, index: number): string {
        return `eau-app-input-${key}-${index}`;
    }

    private _buildForm(): void {
        const defaultBids = this._getInitialBids();
        const bidsFormGroupArray = defaultBids.map(this._getBidFormGroup.bind(this));

        this.createAuctionForm = this._formBuilder.group({
            currency: [
                'SEK',
                [Validators.required],
            ],
            docsProjectId: [
                '0',
            ],
            initialBids: this._formBuilder.array(bidsFormGroupArray),
            lotId: [
                '0',
                [Validators.required, integerValidator],
            ],
            lotName: [
                'Default lot',
                [Validators.required],
            ],
            procurementName: [
                'Upphandling av kryofrysanläggning',
                [Validators.required],
            ],
            procurementOrganizationName: [
                'Myndighetsverket',
                [Validators.required],
            ],
            procurementReferenceNumber: [
                '122/16',
                [Validators.required],
            ],
            unit: [
                'SEK',
                [Validators.required],
            ],
        });
    }

    private _getInitialBids(): PendingBid[] {
        return [
            {
                supplier: 'supplier1',
                supplierName: 'Supplier1',
                value: 100000,
            },
            {
                supplier: 'supplier2',
                supplierName: 'Supplier2',
                value: 118000,
            },
            {
                supplier: 'supplier3',
                supplierName: 'Supplier3',
                value: 129000,
            },
        ];
    }

    private _getBidFormGroup(bid: PendingBid): FormGroup {
        return this._formBuilder.group({
            supplier: [
                bid.supplier,
                [Validators.required, integerValidator],
            ],
            supplierName: [
                bid.supplierName,
                [Validators.required],
            ],
            value: [
                bid.value,
                [Validators.required],
            ],
        });
    }

}
