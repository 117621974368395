import { BiddingStatusState } from './bidding-status.interfaces';

export const defaultBiddingStatusState: BiddingStatusState = {
    isLoaded: false,
    isLoading: false,
    leadingBidValue: null,
    nextAllowedBidValue: null,
    pendingBidValue: null,
    submittedBidRank: null,
    submittedBidValue: null,
};
