import { CustomValidators } from 'ng2-validation';
import { ValidationResult } from './../shared/validators.interfaces';
import { FormControl, ValidatorFn, Validators  } from '@angular/forms';

export function greaterThanValidator(minValue: number): ValidatorFn  {
    return (control: FormControl): ValidationResult => {

        if (Validators.required(control)) {
            return null;
        }

        if (CustomValidators.number(control)) {
            return null;
        }

        if (control.value <= minValue) {
            return {
                greaterThan: { minValue },
            };
        }

        return null;
    };
}
