import {
    SettingsUpdatedSocketsResponseModel,
    AuctionProlongedSocketsResponseModel,
} from './settings.interfaces';
import { BaseSocketsService } from './../../core/shared/base-sockets.service';
import { SocketListenHook } from './../../core/shared/base-sockets.interfaces';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SettingsSocketsService {

    constructor(
        private _baseSocketsService: BaseSocketsService,
    ) {}

    public settingsUpdated(): Observable<SettingsUpdatedSocketsResponseModel> {
        return this._baseSocketsService.get(SocketListenHook.settingsUpdated);
    }

    public auctionProlonged(): Observable<AuctionProlongedSocketsResponseModel> {
        return this._baseSocketsService.get(SocketListenHook.auctionProlonged);
    }

}
