import { UtilsModule } from './../utils/utils.module';
import { LanguagesFlagsComponent } from './languages-flags/languages-flags.component';
import { AppTranslateService } from './shared/app-translate.service';
import { AppTranslateApiService } from './shared/app-translate-api.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AppTranslateStore } from './shared/app-translate.store';
import {
    LanguagesDropdownComponent,
} from './languages-dropdown/languages-dropdown.component';
import { AppTranslateLoader } from './shared/app-translate-loader.service';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        LanguagesDropdownComponent,
        LanguagesFlagsComponent,
    ],
    exports: [
        TranslateModule,
        LanguagesDropdownComponent,
        LanguagesFlagsComponent,
    ],
    imports: [
        CommonModule,
        BsDropdownModule.forRoot(),
        TranslateModule,
        UtilsModule,
    ],
})
export class AppTranslateModule {

    public static forRoot(): ModuleWithProviders<AppTranslateModule> {
        return {
            ngModule: AppTranslateModule,
            providers: [
                AppTranslateLoader,
                AppTranslateStore,
                AppTranslateApiService,
                AppTranslateService,
            ],
        };
    }

}
