import { RedirectionService } from './../../app-routing/shared/redirection.service';
import { UserStore } from './user.store';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserRole } from '../../user/shared/user.interfaces';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(
        private _userStore: UserStore,
        private _redirectionService: RedirectionService,
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean> {
        const allowedRoles = route.data?.roles as UserRole[];

        return new Promise((resolve, reject) => {
            this._userStore.getLoggedInUser()
                .then((user) => {
                    if (user) {
                        const isAuthorized = allowedRoles.indexOf(user.role) > -1;

                        if (isAuthorized) {
                            resolve(true);
                        } else {
                            this._redirectionService.redirectNotAuthorized(state.url);
                            resolve(false);
                        }
                    } else {
                        this._redirectionService.redirectNotAuthenticated(state.url);
                        resolve(false);
                    }
                });
        });
    }

}
