import { SettingsStore } from './../../settings/shared/settings.store';
import { Subscription } from 'rxjs';
import { BidsStore } from './../../bids/shared/bids.store';
import { AppInfoStore } from './../../app-info/shared/app-info.store';
import {
    Component,
    Input,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-data-sync-purchaser-container',
    templateUrl: './data-sync-purchaser-container.template.html',
})
export class DataSyncPurchaserContainerComponent implements OnInit, OnDestroy {

    @Input()
    public auctionId: number;

    @Input()
    public unit: string;

    private _subscriptions: Subscription[] = [];

    constructor(
        private _bidsStore: BidsStore,
        private _appInfoStore: AppInfoStore,
        private _settingsStore: SettingsStore,
    ) {}

    public ngOnInit(): void {
        const s1 = this._appInfoStore.whenReconnected$()
            .subscribe(this._syncPurchaserData);

        this._subscriptions = [s1];
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((x) => x.unsubscribe());
    }

    private _syncPurchaserData = (): void => {
        this._settingsStore.syncSettings(this.auctionId);
        this._bidsStore.syncBids(this.auctionId, this.unit);
    }

}
