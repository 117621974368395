import { ErrorValue } from './../shared/validators.interfaces';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidationService } from './../../app-forms/shared/validation.service';

@Component({
    selector: 'eau-validation-errors',
    styleUrls: [
        './validation-errors.styles.less',
    ],
    templateUrl: './validation-errors.template.html',
})
export class ValidationErrorsComponent {

    @Input()
    public form: FormGroup;

    @Input()
    public key: string;

    constructor(
        private _validationService: ValidationService,
    ) {}

    public getErrorMsg(): string {
        return this._validationService.getErrorMsg(this.form, this.key);
    }

    public getInterpolateParams(): boolean | null | ErrorValue {
        return this._validationService.getErrorValue(this.form, this.key);
    }

    public isInvalid(): boolean {
        return this._validationService.isInvalid(this.form, this.key);
    }

}
