import { NotificationsService } from './../../notifications/shared/notifications.service';
import { RedirectionService } from './../../app-routing/shared/redirection.service';
import { Injectable } from '@angular/core';
import { AppConfig } from './../../core/shared/app.config';
import { DialogService } from './../../dialog/shared/dialog.service';
import { UserApiService } from './../../user/shared/user-api.service';
import { UserService } from './../../user/shared/user.service';
import { UserStore } from './../../user/shared/user.store';

@Injectable()
export class SessionService {

    private _sessionCloseToExpireTimeout: any;

    private _sessionExpireTimeout: any;

    constructor(
        private _appConfig: AppConfig,
        private _dialogService: DialogService,
        private _userApiService: UserApiService,
        private _userService: UserService,
        private _userStore: UserStore,
        private _redirectionService: RedirectionService,
        private _notificationsService: NotificationsService,
    ) { }

    public extendUserSessionTimeouts = (): void => {
        this._clearSesssionTimeouts();

        if (!this._shouldHandleSession()) {
            return;
        }

        this._sessionCloseToExpireTimeout = setTimeout(() => {
            this._dialogService.confirm('common.session_is_going_to_expire', true)
                .subscribe(
                    this._forceExtendSession,
                    this._forceEndSession,
            );
        }, this._appConfig.sessionTimeout - this._appConfig.timespanBeforeSessionTimeout);

        this._sessionExpireTimeout = setTimeout(
            this._handleExpiredSession,
            this._appConfig.sessionTimeout,
        );
    }

    private _clearSesssionTimeouts = (): void => {
        if (this._sessionCloseToExpireTimeout ) {
            clearTimeout(this._sessionCloseToExpireTimeout);
        }
        if (this._sessionExpireTimeout) {
            clearTimeout(this._sessionExpireTimeout);
        }
    }

    private _shouldHandleSession = (): boolean => {
        return this._userStore.isUserLoggedIn() && !this._userStore.isUserLoggingOut();
    }

    private _forceExtendSession = (): void => {
        this._userApiService.getLoggedInUser()
            // tslint:disable-next-line:no-empty
            .subscribe(() => {});
    }

    private _forceEndSession = (): void => {
        if (this._userStore.isUserLoggedIn()) {
            this._userService.logout();
            this._clearSesssionTimeouts();
        }
    }

    private _handleExpiredSession = (): void => {
        this._userStore.unsetLoggedInUser();
        this._redirectionService.redirectNotAuthenticated(null, true);
        this._dialogService.dismissActiveDialog();
        this._notificationsService.warning('common.session_expired');
    }

}
