import { FormatService } from './../../format/shared/format.ser';
import { pubSubEvents } from './../../core/shared/pub-sub-events.values';
import { PubSubService } from './../../pub-sub/shared/pub-sub.service';
import { highlightElementAnimation } from './../../core/shared/animations';
import { ValidationService } from './../../app-forms/shared/validation.service';
import { BidModel } from './../shared/bidding.interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    Component, Input, Output, EventEmitter, OnChanges,
    ChangeDetectionStrategy,
    SimpleChanges,
} from '@angular/core';
import { CustomValidators } from 'ng2-validation';
import { greaterThanValidator } from '../../app-forms/app-validators/greaterThan.validator';

@Component({
    animations: [highlightElementAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-answer-widget',
    styleUrls: [
        './answer-widget.styles.less',
    ],
    templateUrl: './answer-widget.template.html',
})
export class AnswerWidgetComponent implements OnChanges {

    @Input()
    public bidModel: BidModel;

    @Input()
    public isDisabled: boolean;

    @Input()
    public isSavingBid: boolean;

    @Input()
    public isInputInEditMode: boolean;

    @Input()
    public nextAllowedBidValue: number;

    @Input()
    public unit: string;

    @Input()
    public id: string;

    @Output()
    public onValueChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public onValueCancel: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public onValueFocus: EventEmitter<void> = new EventEmitter<void>();

    public form: FormGroup;

    public key: string = 'value';

    public nextAllowedBidAnimationState: string;

    private _previousValue: string;

    constructor(
        private _formBuilder: FormBuilder,
        private _validationService: ValidationService,
        private _pubSubService: PubSubService,
        private _formatService: FormatService,
    ) { }

    public ngOnInit(): void {
        this._buildForm();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.form || !changes.bidModel) {
            return;
        }

        const prevValue = changes.bidModel.previousValue.value;
        const newValue = changes.bidModel.currentValue.value;

        if (prevValue !== newValue) {
            this.form.patchValue({
                [this.key]: newValue,
            });
        }
    }

    public onSubmit(): void {
        const newValue = this.form.value[this.key];

        if (!this.form.dirty) {
            this.onCancel();
            return;
        }

        if (this.form.valid) {
            this.form.markAsPristine();
            this.onValueChange.emit(newValue);
        }
    }

    public onCancel(): void {
        if (this.form.dirty) {
            this.form.patchValue({
                [this.key]: this._previousValue,
            });
            this.form.markAsPristine();
        }

        this.onValueCancel.emit();
    }

    public onFocus(): void {
        if (!this.form.dirty) {
            this._previousValue = this.form.value[this.key];
        }

        this.onValueFocus.emit();
    }

    public onCopyNextAllowedBid(): void {
        const roundedNextAllowedBidValue = this._formatService
            .getRoundedNumber(this.nextAllowedBidValue);

        if (this.isSavingBid || this.form.value[this.key] === roundedNextAllowedBidValue) {
            return;
        }

        this._pubSubService.pub(pubSubEvents.nextAllowedBidCopied);
        this._animateCopiedNextBid();

        this.form.patchValue({
            [this.key]: roundedNextAllowedBidValue,
        });

        this.form.markAsDirty();
        this.onSubmit();
    }

    public isInvalid(): boolean {
        return this._validationService.isInvalid(this.form, this.key);
    }

    public shouldShowControls(): boolean {
        return !!this.isInputInEditMode;
    }

    public isInputDisabled(): boolean {
        return this.isDisabled || this.isSavingBid;
    }

    private _buildForm(): void {

        this.form = this._formBuilder.group({
            [this.key]: [
                this.bidModel.value,
                [Validators.required, CustomValidators.number, greaterThanValidator(0)],
            ],
        });
    }

    private _animateCopiedNextBid(): void {
        this.nextAllowedBidAnimationState = 'isHighlighted';

        setTimeout(() => {
            this.nextAllowedBidAnimationState = '';
        }, 1000);
    }

}
