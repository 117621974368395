
import { map } from 'rxjs/operators';
import { AppInfoStore } from './../shared/app-info.store';
import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-sound-toggle',
    styleUrls: [
        './sound-toggle.styles.less',
    ],
    templateUrl: './sound-toggle.template.html',
})
export class SoundToggleComponent implements OnInit {

    public isSoundOff$: Observable<boolean>;

    @Output()
    public toggleSound: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _appInfoStore: AppInfoStore,
    ) {}

    public ngOnInit(): void {
        this.isSoundOff$ = this._appInfoStore.getState$().pipe(
            map((s) => !s.isSoundOn));
    }

    public onClick(): void {
        this.toggleSound.emit();
    }

}
