<div class="input-group">

    <ng-content></ng-content>

    <div class="input-group-btn" dropdown [(isOpen)]="isOpened">

        <button type="button"
            class="btn btn-default dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            dropdownToggle>
            {{getSelectedOptionLabel()}}
            <span class="caret"></span>
        </button>

        <ul class="dropdown-menu dropdown-menu-right" role="menu" *dropdownMenu>
            <li *ngFor="let option of options">
                <a eauEmptyLink (click)="onSelectOption(option)">{{option[titleKey]}}</a>
            </li>
        </ul>

    </div>

</div>
