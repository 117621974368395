<div class="alert alert-sm" *ngIf="!auctions.length">
    <span class="vismaicon vismaicon-filled vismaicon-info"></span>
    {{'auctions_list.no_auctions_exist' | translate}}
</div>

<table class="table" data-qa="auctionListPurchaser" *ngIf="auctions.length">
    <tr *ngFor="let auction of auctions; trackBy:auction?.id" data-qa="auctionListItem">
        <td [@listItemAppearAnimation]="auction.animationState" [@listItemDisappearAnimation]>
            <a [routerLink]="getAuctionUrl(auction.id)"
                class="eau-underline-on-hover"
                data-qa="specificAuction">
                {{auction.procurementReferenceNumber}}: {{auction.procurementName}}
            </a>
        </td>
        <td [@listItemAppearAnimation]="auction.animationState" [@listItemDisappearAnimation]>
            <button type="button"
                class="btn btn-default eau-icon-btn"
                [attr.area-label]="'common.btn.delete' | translate"
                (click)="onDelete(auction.id)"
                data-qa="deleteAuctionButton">
                <span class="vismaicon vismaicon-sm vismaicon-delete"></span>
            </button>
        </td>
    </tr>
</table>
