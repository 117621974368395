import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-loader-cover',
    styleUrls: [
        './loader-cover.styles.less',
    ],
    templateUrl: './loader-cover.template.html',
})
export class LoaderCoverComponent {

}
