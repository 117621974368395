
import { take } from 'rxjs/operators';
import { AnswerAppParams } from './answer-app.interfaces';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

declare var TendSignDocs: any;

@Injectable()
export class AnswerAppCommunicationService {

    private _areAppAssetsLoaded: boolean = false;

    public onAppBootstrapped$(): Observable<void> {
        return this._onEvent$('appBootstrapped').pipe(
            take(1));
    }

    public onAppRendered$(): Observable<void> {
        return this._onEvent$('appRendered').pipe(
            take(1));
    }

    public onAnswerChange$(): Observable<void> {
        return this._onEvent$('answerChanged');
    }

    public bootstrapApp(): void {
        TendSignDocs.hostMethods.bootstrapApp();
    }

    public setAppParams(params: AnswerAppParams): void {
        if (!(<any>window).TendSignDocs) {
            (<any>window).TendSignDocs = {};
        }

        TendSignDocs.appParams = params;
    }

    public setAppAssetsAsLoaded(): void {
        this._areAppAssetsLoaded = true;
    }

    public areAppAssetsLoaded(): boolean {
        return !!this._areAppAssetsLoaded;
    }

    private _onEvent$(key: string): Observable<void> {
        const $doc = $(document);
        const fullKey = `TendSignDocs:toHost:${key}`;

        return new Observable((observer: any) => {
            $doc.on(fullKey, () => {
                observer.next();
            });

            return () => {
                $doc.off(fullKey);
            };
        });
    }

}
