
import { combineLatest,  Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { DialogService } from './../../dialog/shared/dialog.service';
import {
    CreateAuctionFormContainerComponent,
} from './../create-auction-form-container/create-auction-form-container.component';
import { AuctionsStore } from './../shared/auctions.store';
import { Auction } from './../shared/auctions.interfaces';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-auctions-container',
    styleUrls: [
        './auctions-container.styles.less',
    ],
    templateUrl: './auctions-container.template.html',
})
export class AuctionsContainerComponent implements OnInit, OnDestroy {

    public auctions$: Observable<Auction[]>;

    public showLoader$: Observable<boolean>;

    constructor(
        private _auctionsStore: AuctionsStore,
        private _dialogService: DialogService,
    ) {}

    public ngOnInit(): void    {
        const state$ = this._auctionsStore.getState$();
        this.auctions$ = state$.pipe(map((state) => state.auctions));

        this.showLoader$ = combineLatest(
            [
                state$.pipe(map((state) => state.isLoaded)),
                state$.pipe(map((state) => state.isLoading)),
            ]).pipe(
                 map(([isLoaded, isLoading]) => {
                     return (!isLoaded || isLoading);
                 }));
        this._auctionsStore.getAuctions();
    }

    public ngOnDestroy(): void {
        this._auctionsStore.resetState();
    }

    public onCreateAuction(): void {
        this._dialogService.open(CreateAuctionFormContainerComponent);
    }

    public deleteAuction(auctionId: number): void {
        this._dialogService.confirm('auctions_list.confirm_delete_auction')
            .subscribe(() => {
                this._auctionsStore.deleteAuction(auctionId);
            });
    }

}
