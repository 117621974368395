import { NewBidArrivedSocketsResponseModel } from './bids.interfaces';
import { BaseSocketsService } from './../../core/shared/base-sockets.service';
import { SocketListenHook } from './../../core/shared/base-sockets.interfaces';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class BidsSocketsService {

    constructor(
        private _baseSocketsService: BaseSocketsService,
    ) {}

    public newBidArrived(): Observable<NewBidArrivedSocketsResponseModel> {
        return this._baseSocketsService.get(SocketListenHook.newBidArrived);
    }

}
