
import { map } from 'rxjs/operators';
import { AuctionInfoStore } from './../../auction-info/shared/auction-info.store';
import { stickyElementsThresholds } from './../../core/shared/sticky-elements.values';
import { SettingsStore } from './../../settings/shared/settings.store';
import { StickyOptions } from './../../layout/sticky/sticky.interfaces';
import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-auction-purchaser-container',
    templateUrl: './auction-purchaser-container.template.html',
})
export class AuctionPurchaserContainerComponent implements OnInit {

    @Input()
    public auctionId: number;

    public unit$: Observable<string>;

    public shouldExpandSettings$: Observable<boolean>;

    public startDateTime$: Observable<string>;

    public endDateTime$: Observable<string>;

    public stickyBiddingStatusOptions: StickyOptions = {
        maxWindowWidth: stickyElementsThresholds.maxWindowWidth,
        toTop: true,
    };

    constructor(
        private _auctionInfoStore: AuctionInfoStore,
        private _settingsStore: SettingsStore,
    ) {}

    public ngOnInit(): void {
        const settingsState$ = this._settingsStore.getState$();
        this.startDateTime$ = settingsState$.pipe(map((settings) => settings.model.startDateTime));
        this.endDateTime$ = settingsState$.pipe(map((settings) => settings.model.endDateTime));
        this.shouldExpandSettings$ = this._settingsStore.getState$().pipe(
            map((s) => {
                return s.isLoaded && !s.model.startDateTime;
            }));
        this.unit$ = this._auctionInfoStore.getState$().pipe(
            map((auctionInfo) => auctionInfo.auctionModel.unit));
    }

    public shouldRenderAllWidgets$(): Observable<boolean> {
        return this._auctionInfoStore.getState$().pipe(
            map((s) => s.isLoaded));
    }

}
