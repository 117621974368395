import { DataSyncService } from './../../data-sync/shared/data-sync.service';
import { defaultBiddingStatusState } from './bidding-status.data';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from './../../notifications/shared/notifications.service';
import { BiddingStatusApiService } from './bidding-status-api.service';
import { BiddingStatusState, BiddingStatus } from './bidding-status.interfaces';
import { Injectable } from '@angular/core';
import { BaseStore } from './../../store/shared/base-store';
import { AudioService } from '../../core/shared/audio.service';
import { AppInfoStore } from '../../app-info/shared/app-info.store';

@Injectable()
export class BiddingStatusStore extends BaseStore<BiddingStatusState> {

    constructor(
        private _biddingStatusApiService: BiddingStatusApiService,
        private _notificationsService: NotificationsService,
        private _translateService: TranslateService,
        private _dataSyncService: DataSyncService,
        private _audioService: AudioService,
        private _appInfoStore: AppInfoStore,

    ) {
        super(defaultBiddingStatusState);
    }

    public syncBiddingStatus(
        auctionId: number,
        tenderId: number,
        isSilent: boolean,
    ): void {
        const wasLeader = this._isLeader();
        const rankBefore = this._getState().submittedBidRank;

        this._updateState(<BiddingStatusState>{
            isLoading: true,
        });

        this._biddingStatusApiService.getBiddingStatus(auctionId, tenderId)
            .subscribe(
                (response) => {
                    this._updateState(<BiddingStatusState>Object.assign({}, response, {
                        isLoaded: true,
                        isLoading: false,
                    }));

                    const hasRankChanged = rankBefore !== response.submittedBidRank;

                    if (!isSilent && hasRankChanged) {
                        const isLeader = this._isLeader();

                        this._notifyAboutBiddingStatusChangeAfterCompetingBidIsPublished(
                            wasLeader,
                            isLeader,
                        );
                    }
                },
                () => {
                    this._updateState(<BiddingStatusState>{
                        isLoading: false,
                    });

                    this._dataSyncService.onSyncFail();
                });
    }

    public updateBiddingStatusAfterSubmittingBid(
        newBiddingStatus: BiddingStatus,
    ): void {
        const wasLeader = this._isLeader();
        this._updateState(<BiddingStatusState>newBiddingStatus);
        const isLeader = this._isLeader();
        this._notifyAboutBiddingStatusChangeAfterOwnBidIsPublished(wasLeader, isLeader);
    }

    public updateBiddingStatusAfterChangingPendingBid(newBidValue: number): void {
        this._updateState(<BiddingStatusState>{
            pendingBidValue: newBidValue,
        });
    }

    private _isLeader(): boolean {
        return this._getState().submittedBidRank === 1;
    }

    private _notifyAboutBiddingStatusChangeAfterCompetingBidIsPublished(
        wasLeader: boolean,
        isLeader: boolean,
    ): void {
        const competitorPublishedBid = this._translateService
            .instant('notifications.supplier.competitor_published_new_bid');
        const yourLostLeaderPosition = this._translateService
            .instant('notifications.supplier.you_lost_leader_position');

        let msg = competitorPublishedBid;

        if (wasLeader && !isLeader) {
            msg += `</br><span class="text-error eau-text-strong">
                ${yourLostLeaderPosition}
            </span>`;
        }
        if (!isLeader) {
            this._notificationsService.info(msg);

            if (this._appInfoStore.isSoundOn()) {
                this._audioService.playNotificationSound();
            }
        }
    }

    private _notifyAboutBiddingStatusChangeAfterOwnBidIsPublished(
        wasLeader: boolean,
        isLeader: boolean,
    ): void {
        const yourBidPublished = this._translateService
            .instant('notifications.supplier.your_bid_was_published');
        const yourBecameLeader = this._translateService
            .instant('notifications.supplier.you_became_leader');
        let msg = yourBidPublished;

        if (!wasLeader && isLeader) {
            msg += `<br><span class="text-success eau-text-strong">
                        ${yourBecameLeader}
                    </span>`;
        }

        this._notificationsService.info(msg, false);
    }

}
