import { DialogService } from './../../dialog/shared/dialog.service';
import { Injectable } from '@angular/core';

@Injectable()
export class DataSyncService {

    constructor(
        private _dialogService: DialogService,
    ) {}

    public onSyncFail = (): void => {
        this._dialogService.dismissActiveDialog();
        this._dialogService.alert(
            'sockets_connection.failed_to_sync',
            'common.btn.ok');
    }

}
