import { AppTranslateService } from './../shared/app-translate.service';
import { Language } from './../shared/app-translate.interfaces';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'eau-languages-flags',
    styleUrls: [
        './languages-flags.styles.less',
    ],
    templateUrl: './languages-flags.template.html',
})
export class LanguagesFlagsComponent {

    @Input()
    public languages: Language[];

    constructor(
        private _appTranslateService: AppTranslateService,
    ) {}

    public onChooseLanguage(langKey: string): void {
        this._appTranslateService.setAppLanguage(langKey);
    }

    public trackByFn(lang: Language): string {
        return lang.key;
    }

}
