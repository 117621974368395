import { FormGroup } from '@angular/forms';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-app-datepicker',
    styleUrls: [
        './app-datepicker.styles.less',
    ],
    templateUrl: './app-datepicker.template.html',
})
export class AppDatepickerComponent {

    @Input()
    public form: FormGroup;

    @Input()
    public control: string;

    @Input()
    public isDisabled: boolean;

}
