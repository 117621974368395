<div class="panel eau-add-bid-toolbar">
    <div class="panel-body eau-add-bid-toolbar--inner clearfix">

        <div class="eau-left">
            <div class="eau-next-bid">
                {{'bidding.next_allowed_bid' | translate}}:
                <span [@highlightElementAnimation]="nextAllowedBidAnimationState" data-qa="mainNextAllowedBidValue">{{getNextAllowedBid()}}</span>
            </div>
            <div class="eau-my-bid" data-qa="mainPendingLastSubmitedBid">
                {{getMyNextBidText() | translate}}: {{getNewBid()}}
                <span
                    class="vismaicon vismaicon-filled"
                    data-qa="bidIsOKIcon"
                    title="{{getIconTitle() | translate}}"
                    [ngClass]="getIconClass()"
                    *ngIf="shouldShowIcon()"></span>
            </div>
        </div>
        <div class="eau-right">
            <eau-loader-btn [shouldShowLoader]="isSubmittingBid">
                <button
                    type="button"
                    class="btn btn-primary"
                    data-qa="submitBidBtn"
                    [disabled]="isDisabled()"
                    (click)="onSubmit()">
                    {{'bidding.btn.place_bid' | translate}}
                </button>
            </eau-loader-btn>
        </div>

    </div>
</div>