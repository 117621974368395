import { defaultAppTranslateState } from './app-translate.data';
import { AppTranslateState, Language, DatepickerTranslations } from './app-translate.interfaces';
import { Injectable } from '@angular/core';
import { BaseStore } from './../../store/shared/base-store';

@Injectable()
export class AppTranslateStore extends BaseStore<AppTranslateState> {

    constructor() {
        super(defaultAppTranslateState);
    }

    public setState(
        languagesKeys: string[],
        datepickerTranslations: DatepickerTranslations,
        activeLanguageKey: string,
    ): void {
        const languages: Language[] = languagesKeys.map((key) => {
            return {
                isActive: key === activeLanguageKey,
                key,
                title: `languages.selector.${key}`,
            };
        });

        this._updateState(<AppTranslateState>{
            activeLanguage: activeLanguageKey,
            datepickerTranslations,
            languages,
        });
    }

    public getDatepickerTranslations(): DatepickerTranslations {
        return this._getState().datepickerTranslations;
    }

}
