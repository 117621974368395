import { GetBiddingStatusApiResponseModel } from './bidding-status.interfaces';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseApiService } from '../../core/shared/base-api.service';

@Injectable()
export class BiddingStatusApiService {

    constructor(
        private _apiService: BaseApiService,
    ) {}

    public getBiddingStatus(
        auctionId: number,
        tenderId: number,
    ): Observable<GetBiddingStatusApiResponseModel> {
        const url = this._getUrl(auctionId, tenderId);
        return this._apiService.get(url);
    }

    private _getUrl(auctionId: number, tenderId: number): string {
        return `auctions/${auctionId}/tenders/${tenderId}/biddingstatus`;
    }

}
