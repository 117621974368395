<div class="panel eau-add-bid-container">
    <div class="panel-body">

        <h2>{{'bidding.component_title' | translate}}</h2>

        <div class="alert alert-info alert-sm" *ngIf="shouldShowBiddingDisabledAlert$ | async">
            <span class="vismaicon vismaicon-filled vismaicon-info"></span>
            {{'bidding.bidding_is_disabled' | translate}}
        </div>

        <eau-question-container
            [auctionId]="auctionId"
            [tenderId]="tenderId"
            [isBiddingDisabled]="isBiddingDisabled$ | async"
            [unit]="unit$ | async"
            *ngIf="shouldShowSinglePriceQuestion$ | async">
        </eau-question-container>

        <eau-answer-app
            [auctionId]="auctionId"
            [tenderId]="tenderId"
            *ngIf="!(shouldShowSinglePriceQuestion$ | async)">
        </eau-answer-app>

    </div>
</div>

<div *ngIf="!(isBiddingDisabled$ | async)">
    <div class="eau-sticky-add-bid-toolbar"
        [eauSticky]="stickyToolbarOptions"
        [@toolbarInOutAnimation]>

        <eau-add-bid-toolbar
            [nextAllowedBidValue]="(biddingStatus$ | async)?.nextAllowedBidValue"
            [pendingBidValue]="(biddingStatus$ | async)?.pendingBidValue"
            [submittedBidValue]="(biddingStatus$ | async)?.submittedBidValue"
            [unit]="unit$ | async"
            [isSubmittingBid]="isSubmittingBid$ | async"
            (onSubmitBid)="onSubmitBid()">
        </eau-add-bid-toolbar>
    </div>

    <div class="eau-panel-space-helper"></div>
</div>
