
import { combineLatest,
    interval,  Observable } from 'rxjs';
import { Component, OnInit, ChangeDetectionStrategy,
    Input, Output, EventEmitter } from '@angular/core';
import { SocketsConnectionState } from '../../core/shared/base-sockets.interfaces';
import { map } from 'rxjs/operators';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-connection-indicator',
    styleUrls: [
        './connection-indicator.styles.less',
    ],
    templateUrl: './connection-indicator.template.html',
})
export class ConnectionIndicatorComponent implements OnInit {

    @Input()
    public connectionState: Observable<SocketsConnectionState>;

    @Output()
    public togglePopover: EventEmitter<void> = new EventEmitter<void>();

    public className$: Observable<{ [key: string]: boolean }>;

    public ngOnInit(): void {
        const interval$ = interval(2000);

        this.className$ = combineLatest(
        [
            this.connectionState,
            interval$,
        ]).pipe(
            map(([state, observableInterval]) => {
                return this._getClassName(state, observableInterval);
            }));
    }

    public onTogglePopover(): void {
        this.togglePopover.emit();
    }

    private _getClassName(state: SocketsConnectionState, observableInterval: number): any {
        return {
            'eau-connection-indicator--active': observableInterval % 2 === 0,
            'eau-connection-indicator--connected': state === SocketsConnectionState.connected,
            'eau-connection-indicator--connecting':
                state === SocketsConnectionState.connecting
                || state === SocketsConnectionState.reconnecting,
            'eau-connection-indicator--disconnected': state === SocketsConnectionState.disconnected,
        };
    }
}
