import { AlertComponent } from './alert/alert.component';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmComponent } from './confirm/confirm.component';
import { DialogService } from './shared/dialog.service';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [AlertComponent, ConfirmComponent],
    imports: [NgbModule, TranslateModule],
    providers: [DialogService],
})
export class DialogModule {}
