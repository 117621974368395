export const baseEnvironment = {
    apiServerUrl: '/api/',
    appTitle: 'Mercell eAuction',
    defaultLang: 'sv',
    production: false,
    // need the timeout to be 2 sec. shorter than timeout value in backend
    // so user is still authorized to make api requests to api when session expiration is detected
    sessionTimeout: 60 * 60 * 1000 - 2000,
    socketsHub: 'notificationhub',
    socketsServerUrl: '/notificationhub',
    timespanBeforeSessionTimeout: 5 * 60 * 1000,
};
