import { GetBidApiResponseModel, UpdateBidApiResponseModel,
    UpdateBidApiRequestModel, SubmitBidApiResponseModel } from './bidding.interfaces';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../../core/shared/base-api.service';

@Injectable()
export class BiddingApiService {

    constructor(
        private _apiService: BaseApiService,
    ) {}

    public getBid(
        auctionId: number,
        tenderId: number,
    ): Observable<GetBidApiResponseModel> {

        const url = this._getUrl(auctionId, tenderId);
        return this._apiService.get(url);
    }

    public updateBid(
        auctionId: number,
        tenderId: number,
        value: number,
    ): Observable<UpdateBidApiResponseModel> {

        const url = this._getUrl(auctionId, tenderId);

        const model: UpdateBidApiRequestModel = {
            value,
        };

        return this._apiService.put(url, model);
    }

    public submitBid(
        auctionId: number,
        tenderId: number,
    ): Observable<SubmitBidApiResponseModel> {

        const url = this._getUrl(auctionId, tenderId);
        return this._apiService.post(url, {});
    }

    private _getUrl(auctionId: number, tenderId: number): string {
        return `auctions/${auctionId}/tenders/${tenderId}/bid`;
    }

}
