import { UserStore } from './user.store';
import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class PublicPagesGuardService implements CanActivate {

    constructor(
        private _userStore: UserStore,
    ) {}

    public canActivate(): Promise<boolean> {

        return new Promise((resolve) => {
            this._userStore.getLoggedInUser()
                .then(() => {
                    resolve(true);
                });
        });
    }

}
