import { BaseAuctionModel, Auction } from './../../auctions/shared/auctions.interfaces';

export interface GetAuctionApiResponseModel extends BaseAuctionModel {
}

export interface AuctionInfoState {
    auctionModel: Auction;
    auctionStatus: AuctionStatus;
    isLoaded: boolean;
    isLoading: boolean;
}

export enum AuctionStatus {
    notSet = 1,
    notStarted,
    ongoing,
    finished,
}

export interface TimerLabelsStatus {
    [key: string]: string;
}

export interface TimerLabelsStatuses {
    [key: number]: TimerLabelsStatus;
}
