<div class="panel clearfix">
    <div class="panel-body">

        <eau-loader-holder [showLoader]="!(isLoaded$ | async)">
            <div class="eau-left">
                <eau-timer-container
                    [startDateTime]="startDateTime$"
                    [endDateTime]="endDateTime$">
                </eau-timer-container>

                <eau-leading-bid
                    [leadingBidValue]="(biddingStatus$ | async)?.leadingBidValue"
                    [unit]="unit$ | async">
                </eau-leading-bid>
            </div>

            <div class="eau-right">
                <eau-tender-rank
                    [rank]="(biddingStatus$ | async)?.submittedBidRank"
                    [submittedBidValue]="(biddingStatus$ | async)?.submittedBidValue"
                    [unit]="unit$ | async">
                </eau-tender-rank>
            </div>
        </eau-loader-holder>

    </div>
</div>