import {
    AnswerAppIntegrationModule,
} from './../answer-app-integration/answer-app-integration.module';
import { AppFormsModule } from './../app-forms/app-forms.module';
import { AnswerWidgetComponent } from './answer-widget/answer-widget.component';
import { QuestionContainerComponent } from './question-container/question-container.component';
import { BiddingStore } from './shared/bidding.store';
import { BiddingApiService } from './shared/bidding-api.service';
import { LayoutModule } from './../layout/layout.module';
import { AddBidToolbarComponent } from './add-bid-toolbar/add-bid-toolbar.component';
import { AddBidContainerComponent } from './add-bid-container/add-bid-container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        AddBidContainerComponent,
        AddBidToolbarComponent,
        QuestionContainerComponent,
        AnswerWidgetComponent,
    ],
    exports: [
        AddBidContainerComponent,
        AddBidToolbarComponent,
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        AppFormsModule,
        LayoutModule,
        SharedModule,
        AnswerAppIntegrationModule,
    ],
    providers: [
        BiddingApiService,
        BiddingStore,
    ],
})
export class BiddingModule {

}
