import { JoinAuctionState } from './join-auction.interfaces';

export const defaultJoinAuctionState: JoinAuctionState = {
    joinedSuppliers: [],
};

export const joinAuctionPeriodicities = {
    cleanupOrphanSuppliers: 25000,
    maxIdleTime: 35000,
    supplierEmitsAsOnline: 5000,
};
