
import { filter } from 'rxjs/operators';
import { Subscription ,  Observable } from 'rxjs';
import { shakeAnimation } from './../../core/shared/animations';
import { LoginApiRequestModel } from './../shared/user.interfaces';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
    Component,
    OnInit,
    AfterContentInit,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    OnDestroy, ElementRef, ViewChild,
} from '@angular/core';

@Component({
    animations: [
        shakeAnimation,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-login-form',
    styleUrls: [
        './login-form.styles.less',
    ],
    templateUrl: './login-form.template.html',
})
export class LoginFormComponent implements OnInit, AfterContentInit, OnDestroy {

    @Input()
    public hasLoginFailed: Observable<boolean>;

    @Input()
    public isLoggingIn: boolean;

    @Output()
    public login: EventEmitter<LoginApiRequestModel> = new EventEmitter();

    public loginForm: FormGroup;

    public isShaked: 'isShaked' | '';

    @ViewChild('usernameInput', { static: true })
    private _usernameInput: ElementRef;

    private _hasLoginEverFailed: boolean;

    private _subscriptions: Subscription[] = [];

    constructor(
        private _formBuilder: FormBuilder,
    ) {}

    public ngOnInit(): void {
        this._buildForm();

        this._subscriptions.push(this.hasLoginFailed.pipe(
            filter((x) => !!x))
            .subscribe((x) => {
                this._hasLoginEverFailed = true;
                this._animateAlert();
            }),
        );
    }

    public ngAfterContentInit(): void {
        setTimeout(() => this._usernameInput.nativeElement.focus());
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((x) => x.unsubscribe());
    }

    public onSubmit(): void {
        if (!this.loginForm.valid) {
            this._hasLoginEverFailed = true;
            this._animateAlert();
            return;
        }

        const model: LoginApiRequestModel = this.loginForm.getRawValue();
        this.login.emit(model);
    }

    public shouldShowValidationAlert(): boolean {
        return this._hasLoginEverFailed;
    }

    private _buildForm(): void {
        this.loginForm = this._formBuilder.group({
            password: [
                '',
                [Validators.required],
            ],
            username: [
                '',
                [Validators.required],
            ],
        });
    }

    private _animateAlert(): void {
        this.isShaked = 'isShaked';
        setTimeout(() => {
            this.isShaked = '';
        });
    }

}
