<div class="popover bottom" *ngIf="isPopoverShown$() | async" [@showHideAnimation] >

    <div class="arrow"></div>
    <h3 class="popover-title">
        {{getPopoverTitle$() | async | translate}}

        <eau-loader-spinner *ngIf="shouldShowSpinner$() | async"></eau-loader-spinner>

        <button type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            (click)="onTogglePopover()">
            <span aria-hidden="true">×</span>
        </button>
    </h3>
    <div class="popover-content">
        {{getPopoverDescription$() | async | translate}}
        <br>
        <button type="button"
            class="btn btn-default"
            (click)="onReconnect()"
            *ngIf="shouldShowReconnectBtn$() | async">
            {{'sockets_connection.btn.reconnect_to_sockets' | translate}}
        </button>

        <p class="text-error" *ngIf="hasFailedToInitReconnection">
            {{'sockets_connection.message.could_not_reconnect' | translate}}
        </p>
    </div>
</div>
