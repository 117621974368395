import { validationMessages } from './validation.constants';
import { FormGroup } from '@angular/forms';
import { ErrorValue } from './validators.interfaces';
import { Injectable } from '@angular/core';

@Injectable()
export class ValidationService {

    public isInvalid(form: FormGroup, key: string): boolean {
        const formControl = form.get(key);
        return formControl.dirty && formControl.errors ? true : false;
    }

    public getErrorMsg(form: FormGroup, key: string): string {
        const errorKey = this._getErrorKey(form, key);
        return validationMessages[errorKey];
    }

    public getErrorValue(form: FormGroup, key: string): boolean | null | ErrorValue {
        const formControl = form.get(key);
        const errors = formControl.errors;

        if (!errors) {
            return null;
        }

        const errorKey = this._getErrorKey(form, key);
        return errors[errorKey];
    }

    private _getErrorKey(form: FormGroup, key: string): string {
        const formControl = form.get(key);
        const errors = formControl.errors;
        for (const errorKey in errors) {
            if (errors.hasOwnProperty(errorKey)) {
                return errorKey;
            }
        }

        return null;
    }

}
