
import { map } from 'rxjs/operators';
import { FormatService } from './../../format/shared/format.ser';
import { BiddingStatusStore } from './../../bidding-status/shared/bidding-status.store';
import { Observable } from 'rxjs';
import { BiddingStore } from './../shared/bidding.store';
import { BidModel } from './../shared/bidding.interfaces';
import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-question-container',
    styleUrls: [
        './question-container.styles.less',
    ],
    templateUrl: './question-container.template.html',
})
export class QuestionContainerComponent implements OnInit {

    @Input()
    public auctionId: number;

    @Input()
    public tenderId: number;

    @Input()
    public isBiddingDisabled: boolean;

    @Input()
    public unit: string;

    public bidModel$: Observable<BidModel>;

    public showLoader$: Observable<boolean>;

    public isSavingBid$: Observable<boolean>;

    public isInputInEditMode$: Observable<boolean>;

    public nextAllowedBidValue$: Observable<number>;

    public inputId: string = 'eau-total-price-input';

    constructor(
        private _biddingStore: BiddingStore,
        private _biddingStatusStore: BiddingStatusStore,
        private _formatService: FormatService,
    ) {}

    public ngOnInit(): void {
        this.bidModel$ = this._biddingStore.getState$().pipe(map((state) => state.bidModel));

        this.showLoader$ = this.bidModel$.pipe(map((model) => {
            return model && model.value !== null  ? false : true;
        }));

        this.isSavingBid$ = this._biddingStore.getState$().pipe(map((state) => state.isSavingBid));

        this.isInputInEditMode$ = this._biddingStore
            .getState$().pipe(map((s) => s.isInputInEditMode));

        this.nextAllowedBidValue$ = this._biddingStatusStore.getState$().pipe(
            map((s) => s.nextAllowedBidValue));

        this._biddingStore.loadBid(this.auctionId, this.tenderId);
    }

    public onBidValueChange(newValue: string): void {
        const parsedNewValue = parseFloat(newValue);

        this._biddingStore.updateBid(
            this.auctionId,
            this.tenderId,
            parsedNewValue,
        );
    }

    public onBidValueCancel(): void {
        this._biddingStore.closeInputInEditMode();
    }

    public onBidValueFocus(): void {
        this._biddingStore.openInputInEditMode();
    }

    public getLastSubmittedAnswer$(): Observable<string> {
        return this.bidModel$.pipe(map((x) => {
            return this._formatService.getFormatedNumberWithUnit(x.valuePrevious, this.unit);
        }));
    }

    public getInitialAnswer$(): Observable<string> {
        return this.bidModel$.pipe(map((x) => {
            return this._formatService.getFormatedNumberWithUnit(x.valueInitial, this.unit);
        }));
    }
}
