import {
    GetAuctionsApiResponseModel,
    CreateAuctionApiRequestModel,
    CreateAuctionApiResponseModel,
} from './auctions.interfaces';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseApiService } from '../../core/shared/base-api.service';

@Injectable()
export class AuctionsApiService {

    constructor(
        private _apiService: BaseApiService,
    ) {}

    public getAuctions(): Observable<GetAuctionsApiResponseModel> {
        const url = 'auctions';
        return this._apiService.get(url);
    }

    public createAuction(
        lotId: number,
        model: CreateAuctionApiRequestModel,
    ): Observable<CreateAuctionApiResponseModel> {

        const url = `auctions/${lotId}`;
        return this._apiService.post(url, model);
    }

    public deleteAuction(auctionId: number): Observable<{}> {
        const url = `auctions/${auctionId}`;
        return this._apiService.delete(url);
    }

}
