<form class="form-horizontal"
    [formGroup]="settingsForm"
    (ngSubmit)="onSaveBtnClick()"
    data-qa="auctionSettingsForm">

    <eau-app-input
        [label]="'settings.start_date' | translate"
        [form]="settingsForm"
        [key]="'startDate'"
        data-qa="startDateWrap">

        <eau-app-datepicker
            [form]="settingsForm"
            [control]="'startDate'"
            [isDisabled]="isStartDateControlDisabled()">
        </eau-app-datepicker>
    </eau-app-input>

    <eau-app-input [label]="'settings.start_time' | translate" [form]="settingsForm" [key]="'startTime'">
        <eau-timepicker
            [initialValue]="initialStartTime"
            [isDisabled]="isStartTimeControlDisabled()"
            (onChange)="onTimeChange($event, false)">
            <input type="text" formControlName="startTime" data-qa="startTimeInput" />
        </eau-timepicker>
    </eau-app-input>

    <eau-app-input
        [label]="'settings.end_date' | translate"
        [form]="settingsForm"
        [key]="'endDate'"
        data-qa="endDateWrap">

        <eau-app-datepicker [form]="settingsForm" [control]="'endDate'"></eau-app-datepicker>
    </eau-app-input>

    <eau-app-input [label]="'settings.end_time' | translate" [form]="settingsForm" [key]="'endTime'">
        <eau-timepicker [initialValue]="initialEndTime" (onChange)="onTimeChange($event, true)">
            <input type="text" formControlName="endTime" data-qa="endTimeInput" />
        </eau-timepicker>
    </eau-app-input>

    <eau-app-input [label]="'settings.automatic_extension' | translate" [form]="settingsForm" [key]="'timeExtension'">
        <div class="input-group">
            <input type="text" class="form-control" formControlName="timeExtension" data-qa="timeExtensionInput">
            <span class="input-group-addon btn btn-default">min</span>
        </div>
    </eau-app-input>

    <eau-app-input [label]="'settings.min_bid_improvement' | translate" [form]="settingsForm" [key]="'minBidImprovement'"        >

        <eau-app-input-dropdown
            [options]="bidImprovementTypes"
            [titleKey]="'title'"
            [valueKey]="'type'"
            [selectedOptionValueKey]="selectedBidImprovementType"
            (onSelect)="onSelectBidImprovementType($event)">

            <eau-formated-input
                class="form-contro_l"
                formControlName="minBidImprovement"
                data-qa="minBidImprovementInput">
            </eau-formated-input>

        </eau-app-input-dropdown>

    </eau-app-input>

    <br>

    <div class="form-group mod_al-footer eau-form-actions" [class.eau-are-settings-set]="areSettingsSet">

        <button type="submit"
            class="btn btn-primary"
            [disabled]="isSubmitBtnDisabled()"
            data-qa="saveAuctionSettingsBtn">
            {{'common.btn.save' | translate}}
        </button>
        <button type="button"
            class="btn btn-default"
            *ngIf="areSettingsSet"
            (click)="onCancelBtnClick()"
            data-qa="cancelAuctionSettingsBtn">
            {{'common.btn.cancel' | translate}}
        </button>

    </div>

</form>