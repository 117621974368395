import { GetAuctionApiResponseModel } from './auction-info.interfaces';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../../core/shared/base-api.service';

@Injectable()
export class AuctionInfoApiService {

    constructor(
        private _apiService: BaseApiService,
    ) {}

    public getAuction(auctionId: number): Observable<GetAuctionApiResponseModel> {
        const url = `auctions/${auctionId}`;
        return this._apiService.get(url);
    }

}
