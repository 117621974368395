import { AuctionInfoStore } from './../../auction-info/shared/auction-info.store';
import { NotificationsService } from './../../notifications/shared/notifications.service';
import { DialogService } from './../../dialog/shared/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FormatService } from './../../format/shared/format.ser';
import { PubSubService } from './../../pub-sub/shared/pub-sub.service';
import { pubSubEvents } from './../../core/shared/pub-sub-events.values';
import { highlightElementAnimation } from './../../core/shared/animations';
import {
    Component,
    Input,
    EventEmitter,
    Output,
    OnInit,
    OnDestroy,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from '@angular/core';

@Component({
    animations: [highlightElementAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-add-bid-toolbar',
    styleUrls: [
        './add-bid-toolbar.styles.less',
    ],
    templateUrl: './add-bid-toolbar.template.html',
})
export class AddBidToolbarComponent implements OnInit, OnDestroy {

    @Input()
    public nextAllowedBidValue: number;

    @Input()
    public pendingBidValue: number;

    @Input()
    public submittedBidValue: number;

    @Input()
    public unit: string;

    @Input()
    public isSubmittingBid: boolean;

    @Output()
    public onSubmitBid: EventEmitter<void> = new EventEmitter<void>();

    public nextAllowedBidAnimationState: string;

    private _subscription: Subscription;

    constructor(
        private _pubSubService: PubSubService,
        private _formatService: FormatService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _translateService: TranslateService,
        private _dialogService: DialogService,
        private _notificationsService: NotificationsService,
        private _auctionInfoStore: AuctionInfoStore,
    ) {}

    public ngOnInit(): void {
        this._subscription = this._pubSubService.sub(pubSubEvents.nextAllowedBidCopied)
            .subscribe(this._animateCopiedNextBid);
    }

    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    public getNextAllowedBid(): string {
        return this._formatService.getFormatedNumberWithUnit(this.nextAllowedBidValue, this.unit);
    }

    public getNewBid(): string {
        return this._formatService.getFormatedNumberWithUnit(this.pendingBidValue, this.unit);
    }

    public isDisabled(): boolean {
        if (this.isSubmittingBid) {
            return true;
        }

        if (!this._isBidValid()) {
            return true;
        }

        return false;
    }

    public shouldShowIcon(): boolean {
        return this.pendingBidValue !== this.submittedBidValue;
    }

    public getIconClass(): {[key: string]: boolean} {
        const isValid = this._isBidValid();

        return {
            'vismaicon-error': !isValid,
            'vismaicon-success': isValid,
        };
    }

    public getIconTitle(): string {
        return this._isBidValid()
            ? 'bidding.icon_title.valid'
            : 'bidding.icon_title.not_valid';
    }

    public getMyNextBidText(): string {
        return this.pendingBidValue === this.submittedBidValue
            ? 'bidding_status.last_submitted_bid'
            : 'bidding.my_next_unsubmitted_bid';
    }

    public onSubmit(): void {
        const text = this._getConfirmSubmitText();

        this._dialogService.confirm(text)
            .subscribe(() => {
                if (this._auctionInfoStore.isBiddingDisabled()) {
                    this._dialogService.alert('bidding.auction_has_ended');
                    return;
                }

                if (!this._isBidValid()) {
                    this._notificationsService.error('bidding.invalid_bid_when_submitting');
                    return;
                }

                this.onSubmitBid.emit();
            });
    }

    private _isBidValid(): boolean {
        const roundedNextAllowedBidValue = this._formatService
            .getRoundedNumber(this.nextAllowedBidValue);

        return this.pendingBidValue <= roundedNextAllowedBidValue;
    }

    private _animateCopiedNextBid = (): void => {
        this.nextAllowedBidAnimationState = 'isHighlighted';
        this._changeDetectorRef.markForCheck();

        setTimeout(() => {
            this.nextAllowedBidAnimationState = '';
            this._changeDetectorRef.markForCheck();
        }, 1000);
    }

    private _getConfirmSubmitText(): string {
        const defaultMsg = this._translateService.instant('bidding.confirm_submit_bid');
        let warning = '';
        const diff = this._formatService.getRoundedNumber(this.nextAllowedBidValue) -
            this._formatService.getRoundedNumber(this.pendingBidValue);

        if (diff > 0) {
            const formatedDiff = this._formatService.getFormatedNumberWithUnit(diff, this.unit);
            const amount = `<span class="eau-text-nowrap eau-text-strong">${formatedDiff}</span>`;

            const warningMsg = this._translateService.instant('bidding.warning_of_too_good_bid', {
                amount,
            });

            warning = `<div class="eau-text-error">${warningMsg}</div><br>`;
        }

        const template = `
            ${warning}
            <div>${defaultMsg}</div>
        `;

        return template;
    }

}
