import { RouterModule } from '@angular/router';
import { BidsSocketsService } from './shared/bids-sockets.service';
import { BidsStore } from './shared/bids.store';
import { BidsContainerComponent } from './bids-container/bids-container.component';
import { BidsApiService } from './shared/bids-api.service';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { BidsListComponent } from './bids-list/bids-list.component';
import { TabsModule } from 'ngx-bootstrap/tabs';

@NgModule({
    declarations: [
        BidsContainerComponent,
        BidsListComponent,
    ],
    exports: [
        BidsContainerComponent,
    ],
    imports: [
        FormsModule,
        SharedModule,
        TabsModule.forRoot(),
        RouterModule,
    ],
    providers: [
        BidsApiService,
        BidsStore,
        BidsSocketsService,
    ],
})
export class BidsModule {

}
