import {
    NotificationsHolderComponent,
} from './notifications-holder/notifications-holder.component';
import { NotificationsService } from './shared/notifications.service';
import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    declarations: [
        NotificationsHolderComponent,
    ],
    exports: [
        NotificationsHolderComponent,
    ],
    imports: [
        SharedModule,
        ToastrModule.forRoot({
            closeButton: true,
            enableHtml: true,
            positionClass: 'toast-top-right',
            preventDuplicates: false,
            tapToDismiss: false,
            timeOut: 5000,
        }),
    ],
    providers: [
        NotificationsService,
    ],
})
export class NotificationsModule {

}
