import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-page-header',
    styleUrls: [
        './page-header.styles.less',
    ],
    templateUrl: './page-header.template.html',
})
export class PageHeaderComponent {

    @Input()
    public procurementName: string;

    @Input()
    public procurementReferenceNumber: string;

    @Input()
    public procurementOrganizationName: string;

    public getTitle(): string {
        if (!this.procurementName) {
            return '';
        }
        return `${this.procurementReferenceNumber}: ${this.procurementName}`;
    }

    public getSubtitle(): string {
        return this.procurementOrganizationName;
    }

}
