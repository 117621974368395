import { FormatedInputComponent } from './formated-input/formated-input.component';
import { FormatService } from './shared/format.ser';
import { AppTranslateModule } from './../app-translate/app-translate.module';
import { CommonModule } from '@angular/common';
import { DateTimeService } from './shared/date-time.service';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        FormatedInputComponent,
    ],
    exports: [
        FormatedInputComponent,
    ],
    imports: [
        NgbModule,
        CommonModule,
        AppTranslateModule,
    ],
    providers: [
        DateTimeService,
        FormatService,
    ],
})
export class FormatModule {

}
