import {
    Component,
    Input,
    ChangeDetectionStrategy,
} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
    animations: [
        trigger('loaderHolderAnimation', [
            state('void', style({ opacity: 0 })),
            state('*', style({ opacity: 1 })),
            transition(
                'void => *',
                [animate('500ms')],
            ),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-loader-holder',
    styleUrls: [
        './loader-holder.styles.less',
    ],
    templateUrl: './loader-holder.template.html',
})
export class LoaderHolderComponent {

    @Input()
    public showLoader: boolean;

}
