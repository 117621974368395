
import { map } from 'rxjs/operators';
import { AuctionInfoStore } from './../../auction-info/shared/auction-info.store';
import { Observable } from 'rxjs';
import { StickyOptions } from './../../layout/sticky/sticky.interfaces';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-auction-supplier-container',
    templateUrl: './auction-supplier-container.template.html',
})
export class AuctionSupplierContainerComponent {

    @Input()
    public auctionId: number;

    @Input()
    public tenderId: number;

    public stickyBiddingStatusOptions: StickyOptions = {
        toTop: true,
    };

    constructor(
        private _auctionInfoStore: AuctionInfoStore,
    ) {}

    public shouldRenderAllWidgets$(): Observable<boolean> {
        return this._auctionInfoStore.getState$().pipe(
            map((s) => s.isLoaded));
    }

}
