
import { mapTo, take, skipWhile } from 'rxjs/operators';
import { LocalStorageService } from './../../core/shared/local-storage.service';
import { Observable } from 'rxjs';
import { AppTranslateStore } from './app-translate.store';
import { AppTranslateState } from './app-translate.interfaces';
import { AppConfig } from './../../core/shared/app.config';
import { Translations } from './../../app-translate/shared/app-translate.interfaces';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
const countdown = require('countdown');

@Injectable()
export class AppTranslateService {

    private _defaultLangKey: string;

    constructor(
        private _translateService: TranslateService,
        private _appTranslateStore: AppTranslateStore,
        private _localStorageService: LocalStorageService,
        config: AppConfig,
    ) {
        this._defaultLangKey = config.defaultLang;
    }

    public initMultilingualApp(): void {
        const userLangKey = this._getUserLanguage();
        this._translateService.use(userLangKey);

        this._translateService.onLangChange
            .subscribe((event: TranslationChangeEvent) => {
                this._setUserLanguage(event.lang);
                this._setVendorsLanguage(event.lang, event.translations);
                this._setHtmlLanguageAttribute(event.lang);
            });
    }

    public setAppLanguage(langKey: string): void {
        if (this._translateService.currentLang === langKey) {
            return;
        }

        // need to reset lang and make new api request,
        // otherwise datepicker translations would not be updated
        this._translateService.resetLang(langKey);
        this._translateService.use(langKey);
    }

    public getAppLanguage(): string {
        return this._getUserLanguage();
    }

    public getTranslationsResolver$(): Observable<boolean> {
        return this._appTranslateStore.getState$().pipe(
            skipWhile((s) => {
                return !this._areTranslationsSet(s);
            }),
            take(1),
            mapTo(true));
    }

    private _areTranslationsSet(state: AppTranslateState): boolean {
        return !!state.languages.length;
    }

    private _getUserLanguage(): string {
        return this._localStorageService.get('language') || this._defaultLangKey;
    }

    private _setUserLanguage(langKey: string): void {
        this._localStorageService.set('language', langKey);
    }

    private _setVendorsLanguage(langKey: string, t: Translations): void {
        moment.locale(langKey);
        countdown.setLabels(
            `||| ${t['countdown.hour']}| ${t['countdown.day']}||||||`,
            `||| ${t['countdown.hours']}| ${t['countdown.days']}||||||`,
            null,
            null,
            null,
            null,
        );
    }

    private _setHtmlLanguageAttribute(lang: string): void {
        document.getElementsByTagName('html')[0].setAttribute('lang', lang);
    }

}
