
import { merge,  Subscription ,  Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { SettingsSocketsService } from './../../settings/shared/settings-sockets.service';
import { AuctionInfoStore } from './../../auction-info/shared/auction-info.store';
import { BiddingStatusState } from './../shared/bidding-status.interfaces';
import { BiddingStatusStore } from './../shared/bidding-status.store';
import { SettingsStore } from './../../settings/shared/settings.store';
import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { BiddingStatusSocketsService } from '../shared/bidding-status-sockets.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-bidding-status-container',
    styleUrls: [
        './bidding-status-container.styles.less',
    ],
    templateUrl: './bidding-status-container.template.html',
})
export class BiddingStatusContainerComponent implements OnInit, OnDestroy {

    @Input()
    public auctionId: number;

    @Input()
    public tenderId: number;

    public startDateTime$: Observable<string>;

    public endDateTime$: Observable<string>;

    public unit$: Observable<string>;

    public isLoaded$: Observable<boolean>;

    public biddingStatus$: Observable<BiddingStatusState>;

    private _subscriptions: Subscription[] = [];

    constructor(
        private _settingsSocketsService: SettingsSocketsService,
        private _biddingStatusSocketsService: BiddingStatusSocketsService,
        private _settingsStore: SettingsStore,
        private _biddingStatusStore: BiddingStatusStore,
        private _auctionInfoStore: AuctionInfoStore,
    ) {}

    public ngOnInit(): void {
        this.biddingStatus$ = this._biddingStatusStore.getState$();
        const auctionInfoState$ = this._auctionInfoStore.getState$();
        const settingsState$ = this._settingsStore.getState$();

        this.startDateTime$ = settingsState$.pipe(
            map((state) => state.model.startDateTime));

        this.endDateTime$ = settingsState$.pipe(
            map((state) => state.model.endDateTime));

        this.unit$ = auctionInfoState$.pipe(
            map((auctionInfo) => auctionInfo.auctionModel.unit));

        this.isLoaded$ = merge(
            auctionInfoState$.pipe(map((state) => state.isLoaded)),
            settingsState$.pipe(map((state) => state.isLoaded)),
            this.biddingStatus$.pipe(map((state) => state.isLoaded)),
        );

        this._biddingStatusStore
            .syncBiddingStatus(this.auctionId, this.tenderId, true);

        this._initSocketsEvents();
    }

    public ngOnDestroy(): void {
        this._biddingStatusStore.resetState();
        this._subscriptions.forEach((s) => s.unsubscribe());
    }

    private _initSocketsEvents(): void {

        const s1 = this._biddingStatusSocketsService
            .competitorSubmittedBid()
            .subscribe(() => {
                this._biddingStatusStore
                    .syncBiddingStatus(this.auctionId, this.tenderId, false);
            });

        const s2 = this._settingsSocketsService
            .settingsUpdated()
            .subscribe(() => {
                this._biddingStatusStore.syncBiddingStatus(
                    this.auctionId,
                    this.tenderId,
                    true,
                );
            });

        this._subscriptions = [s1, s2];
    }

}
