
import { interval,  Subscription } from 'rxjs';
import { joinAuctionPeriodicities } from './../shared/join-auction.data';
import { JoinAuctionSocketsService } from './../shared/join-auction-sockets.service';
import {
    Component,
    Input,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-join-auction-supplier-container',
    templateUrl: './join-auction-supplier-container.template.html',
})
export class JoinAuctionSupplierContainerComponent implements OnInit, OnDestroy {

    @Input()
    public auctionId: number;

    private _subscriptions: Subscription[];

    constructor(
        private _joinAuctionSocketsService: JoinAuctionSocketsService,
    ) {}

    public ngOnInit(): void {
        const s1 = this._joinAuctionSocketsService.requestIfSupplierJoinedAuction()
            .subscribe(() => {
                this._joinAuctionSocketsService
                    .respondAsSupplierJoinedAuction(this.auctionId);
            });

        const s2 = interval(joinAuctionPeriodicities.supplierEmitsAsOnline)
            .subscribe(() => {
                this._joinAuctionSocketsService.supplierIsOnline(this.auctionId);
            });

        this._subscriptions = [s1, s2];
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((x) => x.unsubscribe());
    }
}
