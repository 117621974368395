import { BidsChartType } from './../../bids-chart/shared/bids-chart.interfaces';

interface BaseBid {
    createdOn: string;
    id: number;
    supplierName: string;
    tenderId: number;
    value: number;
}

export interface PendingBid {
    supplier: string;
    supplierName: string;
    value: number;
}

export interface Bid extends BaseBid {
    animationState?: string;
}

export interface GetBidsApiResponseModel {
    bids: BaseBid[];
}

export interface NewBidArrivedSocketsResponseModel extends Bid {
}

export interface BidsState {
    activeChartType: BidsChartType;
    activeTab: BidsTab;
    bids: Bid[];
    chartRange: number[];
    isChartLoaded: boolean;
    isLoaded: boolean;
    isLoading: boolean;
}

export enum BidsTab {
    list = 1,
    chart,
}
