<table class="table">
    <thead>
        <tr>
            <th>{{'bids.rank' | translate}}</th>
            <th>{{'common.supplier' | translate}}</th>
            <th class="text-right">{{'bids.bid' | translate}} ({{unit}})</th>
            <th>{{'bids.arrived' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let bid of bids; let i = index; trackBy: trackByFn">
            <td [attr.data-label]="'bids.rank' | translate"
                [@listItemAppearAnimation]="bid.animationState">
                {{i + 1}}
            </td>
            <td [attr.data-label]="'common.supplier' | translate"
                [@listItemAppearAnimation]="bid.animationState">
                <span
                    class="eau-online-status"
                    [class.eau-online-status--connected]="isSupplierJoined(bid.supplierName)"
                    title="{{getOnlineStatusTooltip(bid.supplierName) | translate}}">
                </span>
                {{bid.supplierName}}
            </td>
            <td [attr.data-label]="('bids.bid' | translate) + ' (' + unit + ')'"
                class="text-right"
                [@listItemAppearAnimation]="bid.animationState">
                {{getFormatedBidValue(bid)}}
            </td>
            <td [attr.data-label]="'bids.arrived' | translate"
                class="eau-created-col"
                [@listItemAppearAnimation]="bid.animationState">
                {{getCreationTime(bid)}}
            </td>
        </tr>
    </tbody>
</table>
