
import { catchError, map } from 'rxjs/operators';
import { ErrorHandlingService } from './error-handling.service';
import { AppConfig } from './app.config';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PubSubService } from './../../pub-sub/shared/pub-sub.service';
import { pubSubEvents } from './pub-sub-events.values';
import { HttpError } from './api.interfaces';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BaseApiService {

    private _apiUrl: string;

    constructor(
        config: AppConfig,
        private _http: HttpClient,
        private _errorHandlingService: ErrorHandlingService,
        private _pubSubService: PubSubService,
    )   {
        this._apiUrl = config.apiServerUrl;
    }

    public get(url: string): Observable<any> {
        const fullUrl = this._apiUrl + url;

        return this._http.get(fullUrl, {
            headers: { Pragma: 'no-cache' },
            withCredentials: true,
        }).pipe(
            map(this._onSuccess),
            catchError(this._onError));
    }

    public post(url: string, body: any): Observable<any> {
        const fullUrl = this._apiUrl + url;

        return this._http.post(fullUrl, body, { withCredentials: true }).pipe(
            map(this._onSuccess),
            catchError(this._onError));
    }

    public put(url: string, body: any): Observable<any> {
        const fullUrl = this._apiUrl + url;

        return this._http.put(fullUrl, body, { withCredentials: true }).pipe(
            map(this._onSuccess),
            catchError(this._onError));
    }

    public delete(url: string): Observable<{}> {
        const fullUrl = this._apiUrl + url;

        return this._http.delete(fullUrl, { withCredentials: true }).pipe(
            map(this._onSuccess),
            catchError(this._onError));
    }

    private _onSuccess = (response?: Response): any => {
        this._publishRequestWasMadeEvent();
        return response;
    }

    private _onError = (error: HttpError): any => {
        this._publishRequestWasMadeEvent(error.status);
        return this._errorHandlingService.handleApiError(error);
    }

    private _publishRequestWasMadeEvent = (errorStatus?: number): void => {
        if (errorStatus === 401) {
            return;
        }

        this._pubSubService.pub(pubSubEvents.apiRequestWasMade);
    }

}
