import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppInputDropdownOption } from './shared/app-input-dropdown.interfaces';

@Component({
    selector: 'eau-app-input-dropdown',
    styleUrls: [
        '../shared/app-inputs.styles.less',
    ],
    templateUrl: './app-input-dropdown.template.html',
})
export class AppInputDropdownComponent {

    public isOpened: boolean = false;

    @Input()
    public titleKey: string;

    @Input()
    public valueKey: string;

    @Input()
    public selectedOptionValueKey: string;

    @Input()
    public options: any[];

    @Output()
    public onSelect: EventEmitter<AppInputDropdownOption>
        = new EventEmitter<AppInputDropdownOption>();

    public onSelectOption(option: AppInputDropdownOption): void {
        this.onSelect.emit(option);
    }

    public getSelectedOptionLabel(): string {
        const selectedOption = this.options.find((option) => {
            return option[this.valueKey] === this.selectedOptionValueKey;
        });

        return selectedOption ? selectedOption.title : '';
    }

}
