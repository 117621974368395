import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

import 'moment';

// need to expose jQuery as global object for SignalR
// (adds the `jquery` to the global object under the names `$` and `jQuery`)
import 'expose-loader?exposes[]=$&exposes[]=jQuery!jquery';
import '@microsoft/signalr';

// Only these locales are defined to be loaded in webpack.config.js as well
import 'moment/locale/en-gb';
import 'moment/locale/sv';
import 'moment/locale/nb';
import 'moment/locale/da';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en-GB';
import localeSv from '@angular/common/locales/sv';
import localeDa from '@angular/common/locales/da';
import localeNb from '@angular/common/locales/nb';

registerLocaleData(localeEn);
registerLocaleData(localeSv);
registerLocaleData(localeDa);
registerLocaleData(localeNb);

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
