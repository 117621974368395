<div class="eau-app-datepicker">

    <div class="input-group" [formGroup]="form">

        <input class="form-control"
            autocomplete="off"
            [formControlName]="control"
            ngbDatepicker
            #datepickerEl="ngbDatepicker"
            data-qa="datepickerInput">

        <div class="input-group-btn">
            <button type="button"
                class="btn btn-default dropdown-toggle"
                [disabled]="isDisabled"
                (click)="datepickerEl.toggle()">

                <span class="icon icon-datepicker"></span>
                <span class="caret"></span>
            </button>
        </div>

    </div>

</div>
