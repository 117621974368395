import { RedirectionService } from './../../app-routing/shared/redirection.service';
import { UserStore } from './user.store';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class LoginGuardService implements CanActivate {

    constructor(
        private _userStore: UserStore,
        private _redirectionService: RedirectionService,
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean> {

        return new Promise((resolve, reject) => {
            this._userStore.getLoggedInUser()
                .then((user) => {
                    if (!user) {
                        resolve(true);
                    } else {
                        this._redirectionService.redirectToDefault(user.role);
                        resolve(false);
                    }
                });
        });
    }

}
