import { separators } from './format.const';
import { Injectable } from '@angular/core';
import { AppTranslateService } from './../../app-translate/shared/app-translate.service';
import { DecimalPipe } from '@angular/common';

declare global {
    interface String {
        replaceAll(search: string, replacement: string): string;
    }
}

// tslint:disable:only-arrow-functions
String.prototype.replaceAll = function(search: string, replacement: string): string {
    const target = this;
    return target.split(search).join(replacement);
};

@Injectable()
export class FormatService {

    constructor(
        private _appTranslateService: AppTranslateService,
    ) {}

    public getFormatedNumber(value: any, shouldRoundDecimals: boolean = true): string {
        if (!value && value !== 0) {
            return '';
        }

        value += '';

        const language = this._appTranslateService.getAppLanguage();
        const expression = shouldRoundDecimals ? '1.0-2' : '1.0-9';
        const formatedValue = new DecimalPipe(language).transform(value, expression);

        return this._getValueWithTrailingZerosHandled(formatedValue, language);
    }

    public getFormatedNumberWithUnit(value: any, unit: string): string {
        const separator = unit === '%' ? '' : ' ';
        return this.getFormatedNumber(value) + separator + unit;
    }

    public getRoundedNumber(value: number): number {
        return Number(Math.round(+(value + 'e2')) + 'e-2');
    }

    public getUnformatedNumber(formatedValue: string): number {
        if (formatedValue === '') {
            return null;
        }

        const language = this._appTranslateService.getAppLanguage();
        const decimalSeparator = this._getDecimalSeparator(language);
        const thousandSeparator = this._getThousandSeparator(language);

        thousandSeparator.forEach((x) => {
            formatedValue = formatedValue.replaceAll(x, '');
        });

        return +formatedValue.replaceAll(decimalSeparator, '.');
    }

    public getValueWithoutThousandSeparators(formatedValue: string): string {
        const language = this._appTranslateService.getAppLanguage();
        const separator = this._getThousandSeparator(language);

        separator.forEach((x) => {
            formatedValue = formatedValue.replaceAll(x, '');
        });

        return formatedValue;
    }

    public isDecimalSeparator(value: string): boolean {
        const language = this._appTranslateService.getAppLanguage();
        return this._getThousandSeparator(language).indexOf(value) > -1;
    }

    public isNumber(value: any): boolean {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }

    private _getValueWithTrailingZerosHandled(value: string, language: string): string {
        const separator = this._getDecimalSeparator(language);
        const decimals = value.split(separator)[1];

        if (decimals && decimals.length === 1) {
            value += '0';
        }

        return value + '';
    }

    private _getDecimalSeparator(language: string): string {
        return separators[language].decimal;
    }

    private _getThousandSeparator(language: string): string[] {
        return separators[language].thousand;
    }

}
