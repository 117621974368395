import { map } from 'rxjs/operators';
import { Tender } from '../shared/tenders.interfaces';
import { TendersStore } from './../shared/tenders.store';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-tenders-container',
    templateUrl: './tenders-container.template.html',
})
export class TendersContainerComponent implements OnInit, OnDestroy {

    public tenders$: Observable<Tender[]>;

    public shouldShowLoader$: Observable<boolean>;

    constructor(
        private _tendersStore: TendersStore,
    ) {}

    public ngOnInit(): void {
        const state$ = this._tendersStore.getState$();
        this.tenders$ = state$.pipe(map((state) => state.tenders));

        this.shouldShowLoader$ =  combineLatest(
        [
            state$.pipe(map((state) => state.isLoaded)),
            state$.pipe(map((state) => state.isLoading)),
        ]).pipe(
            map(([isLoaded, isLoading]) => {
                return (!isLoaded || isLoading);
            }));

        this._tendersStore.getTenders();
    }

    public ngOnDestroy(): void {
        this._tendersStore.resetState();
    }

}
