
import { combineLatest, Observable, interval } from 'rxjs';
import { map, startWith, withLatestFrom } from 'rxjs/operators';
import { AuctionInfoStore } from './../shared/auction-info.store';
import { SettingsStore } from './../../settings/shared/settings.store';
import { AuctionStatus } from '../shared/auction-info.interfaces';
import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import * as moment from 'moment';
require('moment-countdown');
const countdown = require('countdown');

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-timer-container',
    styleUrls: [
        '../../shared/shared/shared.styles.less',
        './timer-container.styles.less',
    ],
    templateUrl: './timer-container.template.html',
})
export class TimerContainerComponent implements OnInit {

    // TODO: find how to pass async params with $ sign in the name of param
    @Input()
    public startDateTime: Observable<string>;

    @Input()
    public endDateTime: Observable<string>;

    public remainingTime$: Observable<string>;

    public status$: Observable<AuctionStatus>;

    public isLoaded$: Observable<boolean>;

    constructor(
        private _settingsStore: SettingsStore,
        private _auctionInfoStore: AuctionInfoStore,
    ) { }

    public ngOnInit(): void {

        this.status$ = this._auctionInfoStore.getState$().pipe(map((state) => state.auctionStatus));

        this.isLoaded$ = combineLatest(
            [
                this.status$.pipe(map((status) => status !== AuctionStatus.notSet)),
                this._settingsStore.getState$().pipe(map((state) => state.isLoaded)),
            ]).pipe(
                map(([isStatusSet, settingsAreLoaded]) => {
                    if (isStatusSet && settingsAreLoaded) {
                        return true;
                    }

                    return false;
                }));

        this.remainingTime$ = interval(1000)
            .pipe(
                startWith(0),
                withLatestFrom(this.status$, this.endDateTime, this.startDateTime),
                map(([_interval, currentStatus, endDateTime, startDateTime]) => {
                    const newStatus = this._getNewStatus(
                        startDateTime,
                        endDateTime,
                    );
                    if (newStatus !== currentStatus) {
                        this._auctionInfoStore.updateAuctionStatus(newStatus);
                    }
                    return this._getRemainingTime(
                        newStatus,
                        startDateTime,
                        endDateTime,
                    );
                }));
    }

    private _getNewStatus(startDateTime: string, endDateTime: string): AuctionStatus {
        if (!startDateTime) {
            return AuctionStatus.notSet;
        }

        const date = new Date();
        const remainingSecsToStart = moment(startDateTime)
            .diff(moment(date), 'seconds', true);
        const remainingSecsToEnd = moment(endDateTime)
            .diff(moment(date), 'seconds', true);

        if (remainingSecsToStart > 0) {
            return AuctionStatus.notStarted;
        }

        if (remainingSecsToEnd > 0) {
            return AuctionStatus.ongoing;
        }

        return AuctionStatus.finished;
    }

    private _getRemainingTime(
        status: AuctionStatus,
        startDateTime: string,
        endDateTime: string,
    ): string {

        let baseTime: string;

        if (status === AuctionStatus.notSet) {
            return '00:00';
        }

        if (status === AuctionStatus.finished) {
            return '00:00';
        }

        if (status === AuctionStatus.notStarted) {
            baseTime = startDateTime;
        } else if (status === AuctionStatus.ongoing) {
            baseTime = endDateTime;
        }

        const date = new Date();
        const remainingHoursToStart = moment(baseTime)
            .diff(moment(date), 'hours', true);

        if (remainingHoursToStart < 1) {
            const minsNumber = moment(baseTime)
                .diff(moment(date), 'minutes');

            const mins = minsNumber.toString().length === 1 ? '0' + minsNumber : minsNumber;

            const secsNumber = moment(baseTime)
                .diff(moment(date), 'seconds') - minsNumber * 60;

            const secs = secsNumber.toString().length === 1 ? '0' + secsNumber : secsNumber;

            return mins + ':' + secs;
        }

        const unit = remainingHoursToStart < 24 ? countdown.HOURS : countdown.DAYS;

        return (<any>moment)(date)
            .countdown(baseTime, unit)
            .toString();
    }

}
