import { FormatService } from './../../format/shared/format.ser';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-tender-rank',
    styleUrls: [
        '../../shared/shared/shared.styles.less',
        './tender-rank.styles.less',
    ],
    templateUrl: './tender-rank.template.html',
})
export class TenderRankComponent {

    @Input()
    public rank: number;

    @Input()
    public submittedBidValue: number;

    @Input()
    public unit: string;

    constructor(
        private _formatService: FormatService,
    ) {}

    public getRank(): string {
        return '#' + this.rank;
    }

    public getSubmittedBidValue(): string {
        return this._formatService.getFormatedNumberWithUnit(this.submittedBidValue, this.unit);
    }

}
