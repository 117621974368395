<header class="navbar navbar-default" role="banner">

	<div class="navbar-header">

        <button type="button" class="navbar-toggle" (click)="onNavbarToggle()">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>

		<div class="dropdown" dropdown>
			<button type="button" class="navbar-brand dropdown-toggle" dropdownToggle>
                {{title}}
                <span class="caret"></span>
            </button>

			<ul class="dropdown-menu eau-dropdown-menu--left" role="menu" *dropdownMenu>
				<li>
                    <a eauEmptyLink (click)="onHomeClick()" role="menuitem">{{'topmenu.menuitem.home' | translate}}</a>
				</li>
			</ul>
		</div>
	</div>

    <nav class="nav-collapse">
        <ul class="nav navbar-nav navbar-right nav-tabs first-level"
            [class.eau-is-closed]="isClosed">
            <eau-languages-dropdown
                [title]="displayName"
                [languages]="languages">
            </eau-languages-dropdown>
            <li class="icon first-level-item" *ngIf="isLoggedIn">
                <a eauEmptyLink
                    (click)="onLogout()">
                    <span class="vismaicon vismaicon-menu vismaicon-logout"></span>
                    {{'topmenu.btn.logout' | translate}}
                </a>
            </li>
        </ul>
    </nav>

    <div class="eau-nav-icons" *ngIf="isLoggedIn">
        <eau-sound-toggle (toggleSound)="onToggleSound()"></eau-sound-toggle>
        <eau-connection-container></eau-connection-container>
    </div>

</header>
