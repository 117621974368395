export interface User {
    role: UserRole;
    displayName: string;
}

export interface UserState {
    hasLoginFailed: boolean;
    isIdentityChecked: boolean;
    isLoggingIn: boolean;
    isLoggingOut: boolean;
    user: User;
}

export interface LoginApiRequestModel {
    password: string;
    username: string;
}

export interface LoginApiResponseModel extends User {
}

export interface GetUserApiResponseModel extends User {
}

export enum UserRole {
    host = <any>'Host',
    purchaser = <any>'Purchaser',
    supplier = <any>'Supplier',
}
