import { Separators, DateFormat } from './format.interfaces';

export const separators: Separators = {
    da: {
        decimal: ',',
        thousand: ['.'],
    },
    'en-gb': {
        decimal: '.',
        thousand: [','],
    },
    nb: {
        decimal: ',',
        // Normal and unbreakable space:
        thousand: [' ', ' '],
    },
    sv: {
        decimal: ',',
        // Normal and unbreakable space:
        thousand: [' ', ' '],
    },
};

export const monthDayTimeFormat: DateFormat = {
    da: 'D[.] MMMM, HH:mm',
    'en-gb': 'D MMMM, HH:mm',
    nb: 'D[.] MMMM, HH:mm',
    sv: 'D MMMM, HH:mm',
};

export const monthDayFormat: DateFormat = {
    da: 'D[.]M',
    'en-gb': 'D[/]M',
    nb: 'D[.]M',
    sv: 'D[/]M',
};
