
import { map } from 'rxjs/operators';
import { showHideAnimation } from './connection-popover.animations';
import { AppInfoStore } from './../shared/app-info.store';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { SocketsConnectionState } from '../../core/shared/base-sockets.interfaces';

@Component({
    animations: [
        showHideAnimation,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-connection-popover',
    styleUrls: [
        './connection-popover.styles.less',
    ],
    templateUrl: './connection-popover.template.html',
})
export class ConnectionPopoverComponent {

    @Input()
    public connectionState: Observable<SocketsConnectionState>;

    @Input()
    public hasFailedToInitReconnection: boolean;

    @Output()
    public reconnect: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public togglePopover: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private _appInfoStore: AppInfoStore,
    ) {}

    public isPopoverShown$(): Observable<boolean> {
        // TODO: should receive isSocketsConnectionPopoverShown property as Input param
        // and this component should not be dependant on AppInfoStore
        // but change detection somewhy doesn't work in that case
        return this._appInfoStore.getState$().pipe(
            map((s) => s.isConnectionPopoverShown));
    }

    public onReconnect(): void {
        this.reconnect.emit();
    }

    public onTogglePopover(): void {
        this.togglePopover.emit();
    }

    public getPopoverTitle$(): Observable<string> {
        return this.connectionState.pipe(map((s) => {
            return this._getPopoverTitle(s);
        }));
    }

    public getPopoverDescription$(): Observable<string> {
        return this.connectionState.pipe(map((s) => {
            return this._getPopoverDescription(s);
        }));
    }

    public shouldShowReconnectBtn$(): Observable<boolean> {
        return this.connectionState.pipe(map((s) => s === SocketsConnectionState.disconnected));
    }

    public shouldShowSpinner$(): Observable<boolean> {
        return this.connectionState.pipe(map((s) => {
            return s === SocketsConnectionState.connecting
                || s === SocketsConnectionState.reconnecting;
        }));
    }

    private _getPopoverTitle(state: SocketsConnectionState): string {
        switch (state) {
            case SocketsConnectionState.connected:
                return 'sockets_connection.title.connected';
            case SocketsConnectionState.connecting:
            case SocketsConnectionState.reconnecting:
                return 'sockets_connection.title.connecting';
            case SocketsConnectionState.disconnected:
                return 'sockets_connection.title.disconnected';
            default:
                throw new Error('invalid SocketsConnection state');
        }
    }

    private _getPopoverDescription(state: SocketsConnectionState): string {
        switch (state) {
            case SocketsConnectionState.connected:
                return 'sockets_connection.description.connected';
            case SocketsConnectionState.connecting:
            case SocketsConnectionState.reconnecting:
                return 'sockets_connection.description.connecting';
            case SocketsConnectionState.disconnected:
                return 'sockets_connection.description.disconnected';
            default:
                throw new Error('invalid SocketsConnection state');
        }
    }
}
