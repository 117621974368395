
import { skip, filter, distinctUntilChanged,
    switchMap, take, skipWhile, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { defaultAppInfoState } from './app-info.data';
import { AppInfoState } from './app-info.interfaces';
import { Injectable } from '@angular/core';
import { BaseStore } from './../../store/shared/base-store';
import { SocketsConnectionState } from './../../core/shared/base-sockets.interfaces';

@Injectable()
export class AppInfoStore extends BaseStore<AppInfoState> {

    constructor(
    ) {
        super(defaultAppInfoState);
    }

    public updateSocketsConnectionState(newState: SocketsConnectionState): void {
        this._updateState(<AppInfoState>{
            hasFailedToInitReconnection: false,
            socketsConnectionState: newState,
        });
    }

    public toggleSound(): void {
        this._updateState(<AppInfoState>{
            isSoundOn: !this._getState().isSoundOn,
        });
    }

    public showPopover = (): void => {
        const isShown = this._getState().isConnectionPopoverShown;

        if (!isShown) {
            this.togglePopover();
        }
    }

    public togglePopover(): void {
        this._updateState(<AppInfoState>{
            isConnectionPopoverShown: !this._getState().isConnectionPopoverShown,
        });
    }

    public setAsFailedToInitReconnection(): void {
        this._updateState(<AppInfoState>{
            hasFailedToInitReconnection: true,
        });
    }

    public whenConnected$(): Observable<void> {
        return this.getState$().pipe(
            map((x) => x.socketsConnectionState),
            skipWhile((x) => x !== SocketsConnectionState.connected),
            map((x) => null),
            take(1));
    }

    public whenReconnected$(): Observable<void> {
        return this.whenConnected$().pipe(
            switchMap(() => {
                return this.getState$().pipe(
                    map((x) => x.socketsConnectionState),
                    distinctUntilChanged(),
                    filter((x) => x === SocketsConnectionState.connected),
                    skip(1),
                    map((x) => null));
                }));
    }

    public whenDisconnected$(): Observable<void> {
        return this.getState$().pipe(
            map((x) => x.socketsConnectionState),
            skipWhile((x) => x !== SocketsConnectionState.disconnected),
            map((x) => null),
            take(1));
    }

    public isConnected(): boolean {
        return this._getState().socketsConnectionState === SocketsConnectionState.connected;
    }

    public isSoundOn(): boolean {
        return this._getState().isSoundOn;
    }

}
