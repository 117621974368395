<div class="eau-timer-container eau-loader--container eau-bidding-status-component clearfix">

    <eau-loader-holder [showLoader]="!(isLoaded$ | async)">

        <eau-timer
            [startDateTime]="startDateTime"
            [endDateTime]="endDateTime"
            [remainingTime]="remainingTime$ | async"
            [status]="status$">
        </eau-timer>

    </eau-loader-holder>

</div>
