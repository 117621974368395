import { BidsState, Bid, BidsTab } from './bids.interfaces';
import { BidsChartType } from './../../bids-chart/shared/bids-chart.interfaces';

export const defaultBidsState: BidsState = {
    activeChartType: BidsChartType.initialBids,
    activeTab: BidsTab.list,
    bids: [],
    chartRange: [0, 100],
    isChartLoaded: false,
    isLoaded: false,
    isLoading: false,
};

export const defaultMockedBids: Bid[] = [
    {
        createdOn: '2016-10-27T11:13:49.575Z',
        id: 103,
        supplierName: 'Supplier3',
        tenderId: 3,
        value: 540,
    },
    {
        createdOn: '2016-10-27T09:13:49.575Z',
        id: 102,
        supplierName: 'Supplier2',
        tenderId: 2,
        value: 550,
    },
    {
        createdOn: '2016-10-27T07:13:49.575Z',
        id: 101,
        supplierName: 'Supplier1',
        tenderId: 1,
        value: 600,
    },
];

export const bidsStateMock: BidsState = {
    activeChartType: BidsChartType.initialBids,
    activeTab: BidsTab.list,
    bids: defaultMockedBids,
    chartRange: [0, 100],
    isChartLoaded: false,
    isLoaded: true,
    isLoading: false,
};
