import { UserModule } from './../user/user.module';
import { HomePageComponent } from './home-page/home-page.component';
import { SharedModule } from './../shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [
        HomePageComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        UserModule,
    ],
})
export class HomeModule {

}
