import { Injectable } from '@angular/core';
import { PubSubService } from './../../pub-sub/shared/pub-sub.service';
import { pubSubEvents } from './../..//core/shared/pub-sub-events.values';
import { BaseSocketsService } from './../../core/shared/base-sockets.service';
import { UserStore } from './user.store';

@Injectable()
export class UserService {

    constructor(
        private _pubSubService: PubSubService,
        private _baseSocketsService: BaseSocketsService,
        private _userStore: UserStore,
    ) { }

    public logout(): void {
        this._pubSubService.pub(pubSubEvents.logoutInitiated);
        this._baseSocketsService.disconnect();
        this._userStore.logout();
    }

}
