import { ModuleWithProviders } from '@angular/core';
import {
    NotAuthorizedPageComponent,
} from './../../layout/not-authorized-page/not-authorized-page.component';
import { TendersPageComponent } from './../../tenders/tenders-page/tenders-page.component';
import { LoginGuardService } from './../../user/shared/login-guard.service';
import { HomePageComponent } from './../../home/home-page/home-page.component';
import { UserRole } from './../../user/shared/user.interfaces';
import { appRoutesPaths, appRoutesChildPaths } from './app-routes.constants';
import { AuthGuardService } from './../../user/shared/auth-guard.service';
import { AppResolver } from './app.resolver';
import { NotFoundPageComponent } from './../../layout/not-found-page/not-found-page.component';
import {
    AuctionSupplierPageComponent,
} from './../../auction-supplier/auction-supplier-page/auction-supplier-page.component';
import {
    AuctionPurchaserPageComponent,
} from './../../auction-purchaser/auction-purchaser-page/auction-purchaser-page.component';
import { AuctionsPageComponent } from './../../auctions/auctions-page/auctions-page.component';
import { LoginPageComponent } from './../../user/login-page/login-page.component';
import { Routes, RouterModule } from '@angular/router';

// tslint:disable:object-literal-sort-keys

const routes: Routes = [
    {
        path: appRoutesPaths.login,
        component: LoginPageComponent,
        resolve: {
            translations: AppResolver,
        },
        canActivate: [
            LoginGuardService,
        ],
    },
    {
        path: appRoutesPaths.public.root,
        loadChildren: () =>
        import('app/public-pages/public-pages.module').then(m => m.PublicPagesModule),
    },
    {
        path: appRoutesPaths.root,
        component: HomePageComponent,
        resolve: {
            translations: AppResolver,
        },
        canActivate: [
            AuthGuardService,
        ],
        data: {
            roles: [
                UserRole.purchaser,
                UserRole.supplier,
            ],
        },
    },
    {
        path: appRoutesPaths.purchaser.auction,
        component: AuctionPurchaserPageComponent,
        resolve: {
            translations: AppResolver,
        },
        canActivate: [
            AuthGuardService,
        ],
        data: {
            roles: [
                UserRole.purchaser,
            ],
        },
        children: [
            {
                path: appRoutesChildPaths.chart,
                loadChildren: () => {
                    return import('app/bids-chart/bids-chart.module').then(m => m.BidsChartModule);
                },
            },
        ],
    },
    {
        path: appRoutesPaths.purchaser.auctions,
        component: AuctionsPageComponent,
        resolve: {
            translations: AppResolver,
        },
        canActivate: [
            AuthGuardService,
        ],
        data: {
            roles: [
                UserRole.purchaser,
            ],
        },
    },
    {
        path: appRoutesPaths.supplier.tender,
        component: AuctionSupplierPageComponent,
        resolve: {
            translations: AppResolver,
        },
        canActivate: [
            AuthGuardService,
        ],
        data: {
            roles: [
                UserRole.supplier,
            ],
        },
    },
    {
        path: appRoutesPaths.supplier.tenders,
        component: TendersPageComponent,
        resolve: {
            translations: AppResolver,
        },
        canActivate: [
            AuthGuardService,
        ],
        data: {
            roles: [
                UserRole.supplier,
            ],
        },
    },
    {
        path: appRoutesPaths.notAuthorized,
        component: NotAuthorizedPageComponent,
        resolve: {
            translations: AppResolver,
        },
        canActivate: [
            AuthGuardService,
        ],
        data: {
            roles: [
                UserRole.purchaser,
                UserRole.supplier,
            ],
        },
    },
    {
        path: appRoutesPaths.noMatch,
        component: NotFoundPageComponent,
        resolve: {
            translations: AppResolver,
        },
        canActivate: [
            AuthGuardService,
        ],
        data: {
            roles: [
                UserRole.purchaser,
                UserRole.supplier,
            ],
        },
    },
];

export const appRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes);
