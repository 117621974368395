<eau-join-auction-shared-container [auctionId]="auctionId"></eau-join-auction-shared-container>
<eau-join-auction-supplier-container [auctionId]="auctionId"></eau-join-auction-supplier-container>
<eau-data-sync-supplier-container [auctionId]="auctionId" [tenderId]="tenderId"></eau-data-sync-supplier-container>

<div class="row">
    <div class="col-md-12">
        <eau-auction-info-container [auctionId]="auctionId"></eau-auction-info-container>
    </div>
</div>

<div class="row eau-container" *ngIf="shouldRenderAllWidgets$() | async">
    <div class="col-sm-4 eau-container--sidebar">
        <eau-settings-container
            [auctionId]="auctionId"
            [title]="'settings.component_title_for_supplier' | translate"
            [canUserEdit]="false">
        </eau-settings-container>

        <div class="eau-sticky-bidding-status"
            [eauSticky]="stickyBiddingStatusOptions">
            <eau-bidding-status-container
                [auctionId]="auctionId"
                [tenderId]="tenderId">
            </eau-bidding-status-container>
        </div>
    </div>
    <div class="col-sm-8 eau-container--content">
        <eau-add-bid-container
            [auctionId]="auctionId"
            [tenderId]="tenderId">
        </eau-add-bid-container>
    </div>
</div>
