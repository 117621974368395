import { NotAuthorizedPageComponent } from './not-authorized-page/not-authorized-page.component';
import { ErrorPageService } from './shared/error-page.service';
import { ErrorPageComponent } from './error-page/error-page.component';
import { AlertPageComponent } from './alert-page/alert-page.component';
import { AppInfoModule } from './../app-info/app-info.module';
import { StickyDirective } from './sticky/sticky.directive';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { NavComponent } from './nav/nav.component';
import { CommonModule } from '@angular/common';
import { BsDropdownModule  } from 'ngx-bootstrap/dropdown';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [
        ErrorPageComponent,
        NavComponent,
        NotFoundPageComponent,
        StickyDirective,
        AlertPageComponent,
        NotAuthorizedPageComponent,
    ],
    exports: [
        ErrorPageComponent,
        NavComponent,
        NotFoundPageComponent,
        StickyDirective,
        NotAuthorizedPageComponent,
    ],
    imports: [
        CommonModule,
        BsDropdownModule.forRoot(),
        SharedModule,
        RouterModule,
        AppInfoModule,
    ],
    providers: [
        ErrorPageService,
    ],
})
export class LayoutModule {

}
