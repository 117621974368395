import {
    Component, EventEmitter, Input, Output,
    ViewEncapsulation, OnInit, HostBinding,
} from '@angular/core';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'eau-timepicker',
    styleUrls: [
        './timepicker.styles.less',
    ],
    templateUrl: './timepicker.template.html',
})
export class TimepickerComponent implements OnInit {

    @HostBinding('class.eau-timepicker')
    public className: boolean = true;

    @Input()
    public initialValue: Date;

    @Input()
    public isDisabled: boolean;

    @Output('onChange')
    public valueChanged: EventEmitter<Date> = new EventEmitter<Date>();

    public isOpened: boolean = false;

    public value: Date;

    public ngOnInit(): void {
        this.value = this.initialValue;
    }

    public onChange(): void {
        this.valueChanged.emit(this.value);
    }

}
