<div class="alert alert-sm" *ngIf="!tenders.length">
    <span class="vismaicon vismaicon-filled vismaicon-info"></span>
    {{'tenders_list.no_tenders_exist' | translate}}
</div>

<table class="table" *ngIf="tenders.length">
    <tr *ngFor="let tender of tenders; trackBy:tender?.id">
        <td>
            <a [routerLink]="getTenderUrl(tender)" data-qa="specificTender" class="eau-underline-on-hover">{{tender.procurementName}}: Tender {{tender.id}}</a>
        </td>
    </tr>
</table>
