import { ValidationResult } from './../shared/validators.interfaces';
import { FormControl } from '@angular/forms';

export function timeValidator(control: FormControl): ValidationResult {
    const exp = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;
    const valueInString = control.value + '';

    if (!valueInString.match(exp)) {
        return {
            time: true,
        };
    }

    return null;
}
