import { TimerContainerComponent } from './timer-container/timer-container.component';
import { TimerComponent } from './timer/timer.component';
import {
    AuctionInfoContainerComponent,
} from './auction-info-container/auction-info-container.component';
import { AuctionInfoStore } from './shared/auction-info.store';
import { AuctionInfoApiService } from './shared/auction-info-api.service';
import { PageHeaderComponent } from './page-header/page-header.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        AuctionInfoContainerComponent,
        PageHeaderComponent,
        TimerComponent,
        TimerContainerComponent,
    ],
    exports: [
        AuctionInfoContainerComponent,
        TimerComponent,
        TimerContainerComponent,
    ],
    imports: [
        SharedModule,
    ],
    providers: [
        AuctionInfoApiService,
        AuctionInfoStore,
    ],
})
export class AuctionInfoModule {

}
