<ul class="list-group" data-qa="auctionSettingsPreview">
    <li class="list-group-item" data-qa="auctionDatesPeriod">{{getPeriod()}}</li>
    <li class="list-group-item" data-qa="timeExtensionPreview">
        {{'settings.automatic_extension' | translate}} {{getTimeExtension()}}
    </li>
    <li class="list-group-item" data-qa="minBidImprovementPreview">
        {{'settings.min_bid_improvement' | translate}} {{getMinImprovement()}}
    </li>
</ul>

<button type="button" class="btn btn-default" (click)="onEditBtnClick()" *ngIf="canEdit">
    {{'settings.btn_edit_settings' | translate}}
</button>