import { UserState, UserRole, User } from './user.interfaces';

export const defaultUserState: UserState = {
    hasLoginFailed: false,
    isIdentityChecked: false,
    isLoggingIn: false,
    isLoggingOut: false,
    user: null,
};

export const mockUser: User = {
    displayName: 'dummy username',
    role: UserRole.purchaser,
};

export const mockUserState: UserState = {
    hasLoginFailed: false,
    isIdentityChecked: false,
    isLoggingIn: false,
    isLoggingOut: false,
    user: {
        displayName: 'dummy username',
        role: UserRole.purchaser,
    },
};
