import { Messages } from './validators.interfaces';

export const validationMessages: Messages = {
    date: 'validation.date',
    datePeriod: 'validation.date_period',
    greaterThan: 'validation.greaterThan',
    integer: 'validation.integer',
    laterThanCurrentTime: 'validation.later_than_current_time',
    lessThan: 'validation.lessThan',
    number: 'validation.number',
    required: 'validation.required',
    time: 'validation.time',
    timePeriod: 'validation.time_period',
};
