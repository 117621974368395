import { StickyOptions } from './sticky.interfaces';
import {
    Directive, ElementRef, HostListener, OnDestroy,
    AfterViewInit, AfterViewChecked, Input, OnInit, HostBinding, Renderer2,
} from '@angular/core';

@Directive({
    selector: '[eauSticky]',
})
export class StickyDirective implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

    @Input('eauSticky')
    public userOptions: StickyOptions;

    @HostBinding('class.eau-sticky-element')
    public className: boolean = true;

    private _el: HTMLElement;

    private _helperEl: HTMLElement;

    private _isStickyClassName: string;

    private _isSticky: boolean = false;

    private _options: StickyOptions;

    private _defaultOptions: StickyOptions = {
        maxWindowWidth: null,
        toTop: false,
    };

    constructor(
        angularEl: ElementRef,
        private _renderer: Renderer2,
    ) {
        this._el = angularEl.nativeElement;
    }

    public ngOnInit(): void {
        this._options = Object.assign({}, this._defaultOptions, this.userOptions || {});

        this._isStickyClassName = this._options.toTop
            ? 'eau-is-sticky-top'
            : 'eau-is-sticky-bottom';
    }

    public ngAfterViewInit(): void {
        this._createHelperElement();
        this._toggleSticky();
    }

    public ngAfterViewChecked(): void {
        this._toggleSticky();
    }

    public ngOnDestroy(): void {
        this._removeHelperElement();
        this._isSticky = false;
    }

    @HostListener('window:scroll', ['$event'])
    public onScroll(): void {
        this._toggleSticky();
    }

    @HostListener('window:resize', ['$event'])
    public onResize(): void {
        this._toggleSticky();
    }

    private _createHelperElement(): void {
        this._helperEl = document.createElement('div');
        this._el.parentNode.insertBefore(this._helperEl, this._el);
    }

    private _removeHelperElement(): void {
        this._el.parentNode.removeChild(this._helperEl);
    }

    private _toggleSticky(): void {
        if (this._isWindowWiderThanMaxAllowedWidth()
            || this._isElementHigherThanWindow()) {

            if (this._isSticky) {
                this._unsetSticky();
            }

            return;
        }

        this._isThresholdPassed() ? this._setSticky() : this._unsetSticky();
    }

    private _isWindowWiderThanMaxAllowedWidth(): boolean {
        const maxWidth = this._options.maxWindowWidth;

        if (maxWidth && maxWidth < window.innerWidth) {
            return true;
        }

        return false;
    }

    private _isElementHigherThanWindow(): boolean {
        return this._el.offsetHeight > window.innerHeight;
    }

    private _isThresholdPassed(): boolean {
        const toTop = !!this._options.toTop;
        const elOffsetTop = this._helperEl.getBoundingClientRect().top;
        const elOffsetBottom = elOffsetTop + this._el.offsetHeight;

        if (toTop && elOffsetTop <= 0) {
            return true;
        }

        if (!toTop && elOffsetBottom > window.innerHeight) {
            return true;
        }

        return false;
    }

    private _setSticky(): void {
        if (!this._isSticky) {
            this._helperEl.style.height = this._el.offsetHeight + 'px';
        }

        this._renderer.addClass(this._el, this._isStickyClassName);
        this._renderer.setStyle(this._el, 'width', this._helperEl.offsetWidth + 'px');
        this._isSticky = true;
    }

    private _unsetSticky(): void {
        if (this._isSticky) {
            this._helperEl.style.height = '0px';
        }

        this._renderer.removeClass(this._el, this._isStickyClassName);
        this._renderer.setStyle(this._el, 'width', 'auto');
        this._isSticky = false;
    }

}
