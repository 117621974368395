import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {

    public get(key: string): any {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (e) {
            return;
        }
    }

    public set(key: string, data: any): void {
        localStorage.setItem(key, JSON.stringify(data));
    }

}
