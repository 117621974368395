import { DataSyncModule } from './../data-sync/data-sync.module';
import { JoinAuctionModule } from './../join-auction/join-auction.module';
import {
    AuctionPurchaserContainerComponent,
} from './auction-purchaser-container/auction-purchaser-container.component';
import {
    AuctionPurchaserPageComponent,
} from './auction-purchaser-page/auction-purchaser-page.component';
import { SettingsModule } from '../settings/settings.module';
import { AuctionInfoModule } from '../auction-info/auction-info.module';
import { LayoutModule } from '../layout/layout.module';
import { NgModule } from '@angular/core';
import { BidsModule } from '../bids/bids.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        AuctionPurchaserPageComponent,
        AuctionPurchaserContainerComponent,
    ],
    imports: [
        BidsModule,
        AuctionInfoModule,
        LayoutModule,
        SettingsModule,
        SharedModule,
        JoinAuctionModule,
        DataSyncModule,
    ],
})
export class AuctionPurchaserModule {

}
