import { trigger, transition, animate, keyframes, style } from '@angular/animations';

export const showHideAnimation = trigger('showHideAnimation', [
    transition('void => *', [
        animate(200, keyframes([
            style({
                offset: 0,
                opacity: 0,
                transform: 'perspective(400px) rotate3d(1, 0, 0, 90deg)',
            }),
            style({
                offset: 1,
                opacity: 1,
                transform: 'perspective(400px)',
            }),
        ])),
    ]),
    transition('* => void', [
        animate(200, keyframes([
            style({
                offset: 0,
                transform: 'perspective(400px)',
            }),
            style({
                offset: 1,
                opacity: 0,
                transform: 'perspective(400px) rotate3d(1, 0, 0, 90deg)',
            }),
        ])),
    ]),
]);
