
import { interval,  Subscription } from 'rxjs';
import { joinAuctionPeriodicities } from './../shared/join-auction.data';
import { JoinAuctionStore } from './../shared/join-auction.store';
import { JoinAuctionSocketsService } from './../shared/join-auction-sockets.service';
import {
    Component,
    Input,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-join-auction-purchaser-container',
    templateUrl: './join-auction-purchaser-container.template.html',
})
export class JoinAuctionPurchaserContainerComponent implements OnInit, OnDestroy {

    @Input()
    public auctionId: number;

    private _subscriptions: Subscription[];

    constructor(
        private _joinAuctionSocketsService: JoinAuctionSocketsService,
        private _joinAuctionStore: JoinAuctionStore,
    ) {}

    public ngOnInit(): void {
        const s1 = this._joinAuctionSocketsService.supplierRespondedToPurchaserAsJoinedAuction()
            .subscribe((model) => {
                this._joinAuctionStore.setSupplierAsJoinedAuction(model.supplierName);
            });

        const s2 = this._joinAuctionSocketsService.supplierJoinedAuction()
            .subscribe((model) => {
                this._joinAuctionStore.setSupplierAsJoinedAuction(model.supplierName);
            });

        const s3 = this._joinAuctionSocketsService.supplierLeftAuction()
            .subscribe((model) => {
                this._joinAuctionStore.setSupplierAsLeftAuction(model.supplierName);
            });

        const s4 = interval(joinAuctionPeriodicities.cleanupOrphanSuppliers)
            .subscribe(this._joinAuctionStore.cleanupOrphanSuppliers);

        const s5 = this._joinAuctionSocketsService.supplierRespondedAsOnline()
            .subscribe((model) => {
                this._joinAuctionStore.setSupplierAsJoinedAuction(model.supplierName);
            });

        this._subscriptions = [s1, s2, s3, s4, s5];
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((x) => x.unsubscribe());
    }

}
