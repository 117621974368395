import { appRoutesPaths } from './../../app-routing/shared/app-routes.constants';
import { RedirectionService } from './../../app-routing/shared/redirection.service';
import { Tender } from './../shared/tenders.interfaces';
import {
    Component, Input, ChangeDetectionStrategy,
 } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-tenders-list',
    styleUrls: [
        './tenders-list.styles.less',
    ],
    templateUrl: './tenders-list.template.html',
})
export class TendersListComponent {

    @Input()
    public tenders: Tender[];

    constructor(
        private _redirectionService: RedirectionService,
    ) {}

    public getTenderUrl(tender: Tender): string {
        return this._redirectionService
            .getRouteWithParams(appRoutesPaths.supplier.tender, tender.auctionId, tender.id);
    }

}
