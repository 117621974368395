import { AppInfoStore } from './../../app-info/shared/app-info.store';
import {
    SocketsConnectionState,
    SocketEmitHook,
    SocketListenHook,
} from './base-sockets.interfaces';
import { Injectable } from '@angular/core';
import { HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

@Injectable()
export class BaseSocketsService {

    private _hubConnection: HubConnection;

    constructor(
        private _appInfoStore: AppInfoStore,
    ) {
        this.createConnection();
    }

    public connect(): void {
        this._hubConnection
            .start()
            .then(() => {
                const connectionState =
                    this._getConnectionStateFromSignalRGlobalObj(this._hubConnection.state);
                this._appInfoStore.updateSocketsConnectionState(connectionState);
            }).catch(err => {
                console.log("Cannot start the connection: " + err);
            });

    //    this._hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 20;
    //    this._hubConnection.keepAliveIntervalInMilliseconds = 1000 * 60 * 10;
    }

    public disconnect(): void {
        this._hubConnection.stop();
    }

    public reconnect(): void {
        this._hubConnection.start();
    }

    public get(key: SocketListenHook): Observable<any> {
        return new Observable((observer: any) => {
            this._hubConnection.on(key.toString(), (data: any) => {
                observer.next(data);
            });
            return () => {
                this._hubConnection.off(key.toString());
            };
        });
    }

    public send(key: SocketEmitHook, data: any): void {
        if (this._hubConnection.state !== signalR.HubConnectionState.Connected) { return; }
        if (!this._appInfoStore.isConnected()) { return; }
        const connectionState =
            this._getConnectionStateFromSignalRGlobalObj(this._hubConnection.state);
        if (connectionState === SocketsConnectionState.connected) {
            this._hubConnection.invoke(key.toString(), data);
        }
    }

    private _getConnectionStateFromSignalRGlobalObj(state: signalR.HubConnectionState)
        : SocketsConnectionState {
        switch (state) {
            case signalR.HubConnectionState.Connected:
                return SocketsConnectionState.connected;
            case signalR.HubConnectionState.Reconnecting:
                return SocketsConnectionState.reconnecting;
            case signalR.HubConnectionState.Disconnected:
                return SocketsConnectionState.disconnected;
            default:
                return SocketsConnectionState.connecting;
        }
    }

    private createConnection(): void {
        this._hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(environment.socketsServerUrl)
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();
    }
}
