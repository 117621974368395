import { Injectable } from '@angular/core';

@Injectable()
export class AudioService {

    private _baseUrl: string = 'assets/sounds/';

    public playNotificationSound(): void {
        this._play('music_marimba_chord.mp3');
    }

    private _play(file: string): void {
        const audio = new Audio();
        audio.src = `${this._baseUrl}${file}`;
        audio.play();
    }

}
