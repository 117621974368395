<div class="panel eau-settings-container-panel">
    <div class="panel-body">
        <h2>{{title}}</h2>

        <eau-loader-holder [showLoader]="shouldShowLoader$ | async">
            <eau-settings-preview
                [settings]="(settingsState$ | async)?.model"
                [unit]="(unit$ | async)"
                [language]="(language$ | async)"
                [canEdit]="canSettingsBeEdited$() | async"
                (onEdit)="onEdit()"
                *ngIf="!(settingsState$ | async)?.isInEditMode">
            </eau-settings-preview>

            <eau-settings-form
                [settings]="(settingsState$ | async)?.model"
                [areSettingsSet]="(areSettingsSet$ | async)"
                [unit]="(unit$ | async)"
                [isLoading]="(settingsState$ | async)?.isLoading"
                [auctionStatusAsync]="auctionStatus$"
                (onSave)="onSave($event)"
                (onCancel)="onCancel()"
                *ngIf="(settingsState$ | async)?.isInEditMode">
            </eau-settings-form>
        </eau-loader-holder>

    </div>
</div>
