import { FormatService } from './../../format/shared/format.ser';
import {
    Component,
    Input,
    ChangeDetectionStrategy,
    OnChanges,
} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-leading-bid',
    styleUrls: [
        '../../shared/shared/shared.styles.less',
        './leading-bid.styles.less',
    ],
    templateUrl: './leading-bid.template.html',
})
export class LeadingBidComponent implements OnChanges {

    @Input()
    public leadingBidValue: number;

    @Input()
    public unit: string;

    public isAnimated: boolean = false;

    private _previousLeadingBidValue: number;

    constructor(
        private _formatService: FormatService,
    ) {}

    public ngOnChanges(): void {
        this._handleValueChangeAnimation();
    }

    public getLeadingBid(): string {
        return this._formatService.getFormatedNumberWithUnit(this.leadingBidValue, this.unit);
    }

    private _handleValueChangeAnimation(): void {
        if (!this._previousLeadingBidValue) {
            this._previousLeadingBidValue = this.leadingBidValue;
            return;
        }

        if (this.leadingBidValue !== this._previousLeadingBidValue) {
            this._previousLeadingBidValue = this.leadingBidValue;
            this.isAnimated = true;

            setTimeout(() => {
                this.isAnimated = false;
            }, 1000);
        }
    }

}
