
import { map } from 'rxjs/operators';
import { BiddingStore } from './../shared/bidding.store';
import { AuctionInfoStore } from './../../auction-info/shared/auction-info.store';
import { AuctionStatus } from './../../auction-info/shared/auction-info.interfaces';
import { BiddingStatusStore } from './../../bidding-status/shared/bidding-status.store';
import { StickyOptions } from './../../layout/sticky/sticky.interfaces';
import {
    BiddingStatusState,
} from './../../bidding-status/shared/bidding-status.interfaces';
import { Observable, combineLatest } from 'rxjs';
import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { BiddingType } from '../../auctions/shared/auctions.interfaces';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
    animations: [
        trigger('toolbarInOutAnimation', [
            state('void', style({ opacity: 0 })),
            transition('void <=> *', [
                animate(200),
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-add-bid-container',
    styleUrls: [
        './add-bid-container.styles.less',
    ],
    templateUrl: './add-bid-container.template.html',
})
export class AddBidContainerComponent implements OnInit, OnDestroy {

    @Input()
    public auctionId: number;

    @Input()
    public tenderId: number;

    public biddingStatus$: Observable<BiddingStatusState>;

    public unit$: Observable<string>;

    public shouldShowSinglePriceQuestion$: Observable<boolean>;

    public isBiddingDisabled$: Observable<boolean>;

    public shouldShowBiddingDisabledAlert$: Observable<boolean>;

    public stickyToolbarOptions: StickyOptions = {
        toTop: false,
    };

    public isSubmittingBid$: Observable<boolean>;

    constructor(
        private _biddingStore: BiddingStore,
        private _auctionInfoStore: AuctionInfoStore,
        private _biddingStatusStore: BiddingStatusStore,
    ) {}

    public ngOnInit(): void {
        const auctionInfo$ = this._auctionInfoStore.getState$();
        this.biddingStatus$ = this._biddingStatusStore.getState$();
        this.unit$ = auctionInfo$.pipe(
            map((auctionInfo) => auctionInfo.auctionModel.unit));
        this.shouldShowSinglePriceQuestion$ = auctionInfo$.pipe(
            map((auctionInfo) => auctionInfo.auctionModel.biddingType === BiddingType.SinglePrice));

        this.isBiddingDisabled$ = auctionInfo$.pipe(map((x) => {
            return x.auctionStatus === AuctionStatus.ongoing ? false : true;
        }));

        this.isSubmittingBid$ = this._biddingStore.getState$().pipe(map((s) => s.isSubmittingBid));

        this.shouldShowBiddingDisabledAlert$ = combineLatest([
            this.isBiddingDisabled$,
            auctionInfo$.pipe(map((x) => x.auctionModel.biddingType)),
            auctionInfo$.pipe(map((x) => x.auctionStatus)),
            this._biddingStore.getState$().pipe(map((x) => x.isLoaded)),
        ]).pipe(
            map(([ isBiddingDisabled, biddingType, auctionStatus, isSingleTenderLoaded ]) => {
                return this._shouldShowBiddingDisabledAlert(
                    isBiddingDisabled,
                    biddingType,
                    auctionStatus,
                    isSingleTenderLoaded);
            }));
    }

    public ngOnDestroy(): void {
        this._biddingStore.resetState();
    }

    public onSubmitBid(): void {
        this._biddingStore.submitBid(this.auctionId, this.tenderId);
    }

    private _shouldShowBiddingDisabledAlert(
        isBiddingDisabled: boolean,
        biddingType: BiddingType,
        auctionStatus: AuctionStatus,
        isSingleTenderLoaded: boolean,
    ): boolean {
        if (!isBiddingDisabled) {
            return false;
        }

        if (auctionStatus === AuctionStatus.notSet) {
            return false;
        }

        if (biddingType === BiddingType.SinglePrice && isSingleTenderLoaded) {
            return true;
        }

        return false;
    }

}
