import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-auctions-page',
    templateUrl: './auctions-page.template.html',
})
export class AuctionsPageComponent {

}
