import {
    LoginApiRequestModel,
    LoginApiResponseModel,
    GetUserApiResponseModel,
} from './user.interfaces';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseApiService } from '../../core/shared/base-api.service';

@Injectable()
export class UserApiService {

    constructor(
        private _apiService: BaseApiService,
    ) {}

    public login(model: LoginApiRequestModel): Observable<LoginApiResponseModel> {
        const url = 'auth/login';
        return this._apiService.post(url, model);
    }

    public logout(): Observable<any> {
        const url = 'auth/logout';
        return this._apiService.post(url, {});
    }

    public getLoggedInUser = (): Observable<GetUserApiResponseModel> => {
        const url = 'auth/user';
        return this._apiService.get(url);
    }

}
