<div class="eau-question">

    <label class="eau-question--title" [attr.for]="inputId">
        {{'bidding.total_price' | translate}}
        <span class="eau-form-unit--above">({{unit}})</span>
    </label>

    <eau-loader-holder [showLoader]="showLoader$ | async">

        <div class="eau-question--widget">
            <eau-answer-widget
                [bidModel]="bidModel$ | async"
                [isDisabled]="isBiddingDisabled"
                [isSavingBid]="isSavingBid$ | async"
                [isInputInEditMode]="isInputInEditMode$ | async"
                [nextAllowedBidValue]="nextAllowedBidValue$ | async"
                [unit]="unit"
                [id]="inputId"
                (onValueChange)="onBidValueChange($event)"
                (onValueCancel)="onBidValueCancel()"
                (onValueFocus)="onBidValueFocus()">
            </eau-answer-widget>

            <div class="eau-question--previous-values">
                {{'bidding.my_last_submitted_answer' | translate}} {{getLastSubmittedAnswer$() | async}}<br>
                {{'bidding.my_initial_answer' | translate}} {{getInitialAnswer$() | async}}
            </div>
        </div>

    </eau-loader-holder>

</div>
