
import { combineLatest,  Observable } from 'rxjs';

import { skip, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { appRoutesPaths } from './app-routing/shared/app-routes.constants';
import { RedirectionService } from './app-routing/shared/redirection.service';
import { pubSubEvents } from './core/shared/pub-sub-events.values';
import { PubSubService } from './pub-sub/shared/pub-sub.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { ErrorPageService } from './layout/shared/error-page.service';
import { AppInfoStore } from './app-info/shared/app-info.store';
import { NotificationsService } from './notifications/shared/notifications.service';
import { AppConfig } from './core/shared/app.config';
import { AppTranslateStore } from './app-translate/shared/app-translate.store';
import { Language } from './app-translate/shared/app-translate.interfaces';
import { AppTranslateService } from './app-translate/shared/app-translate.service';
import { UserStore } from './user/shared/user.store';
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { BaseSocketsService } from './core/shared/base-sockets.service';
import { UserRole } from './user/shared/user.interfaces';
import { SessionService } from './core/shared/session.service';
import { UserService } from './user/shared/user.service';
import { Meta } from '@angular/platform-browser';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'eau-app',
    styleUrls: [
        './app.styles.less',
    ],
    templateUrl: './app.template.html',
})
export class AppComponent implements OnInit {

    public isLoggedIn$: Observable<boolean>;

    public shouldShowNav$: Observable<boolean>;

    public isIdentityChecked$: Observable<boolean>;

    public displayName$: Observable<string>;

    public userRole$: Observable<UserRole>;

    public languages$: Observable<Language[]>;

    public appTitle: string;

    constructor(
        private _router: Router,
        private _appConfig: AppConfig,
        private _baseSocketsService: BaseSocketsService,
        private _userStore: UserStore,
        private _appInfoStore: AppInfoStore,
        private _appTranslateService: AppTranslateService,
        private _appTranslateStore: AppTranslateStore,
        private _notificationsService: NotificationsService,
        private _errorPageService: ErrorPageService,
        private _pubSubService: PubSubService,
        private _sessionService: SessionService,
        private _userService: UserService,
        private _redirectionService: RedirectionService,
        private _meta: Meta,
    ) {
        this._meta.addTag({ name: 'http-equiv', content: 'Content-Security-Policy' });
        this._meta.addTag({ name: 'content', content: 'upgrade-insecure-requests' });
    }

    public ngOnInit(): void {
        const userState$ = this._userStore.getState$();
        this.isLoggedIn$ = userState$.pipe(map((state) => !!state.user));
        this.isIdentityChecked$ = userState$.pipe(map((state) => state.isIdentityChecked));

        this.displayName$ = userState$.pipe(map((state) => {
            return state.user ? state.user.displayName : '';
        }));

        this.userRole$ = userState$.pipe(map((state) => {
            return state.user ? state.user.role : null;
        }));

        this.languages$ = this._appTranslateStore.getState$().pipe(map((s) => s.languages));
        this.appTitle = this._appConfig.appTitle;
        this._handeUserLoginStatusChange();
        this._handleErrorPage();
        this._appTranslateService.initMultilingualApp();

        this._pubSubService.sub(pubSubEvents.apiRequestWasMade)
            .subscribe(this._sessionService.extendUserSessionTimeouts);

        this._pubSubService.sub(pubSubEvents.userSessionExpired).subscribe(() => {
            this._userStore.unsetLoggedInUser();
            this._redirectionService.redirectNotAuthenticated(null, true);
        });

        const isLoginPageActive$ = this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this._router.routerState.snapshot.url.indexOf(appRoutesPaths.login) > -1));

        this.shouldShowNav$ = combineLatest(
        [
            isLoginPageActive$,
            this.isIdentityChecked$,
        ]).pipe(
            map(([activeLoginPage, isIdentityChecked]) => {
                return !activeLoginPage && isIdentityChecked;
            }));
    }

    public onLogout(): void {
        this._userService.logout();
    }

    public onToggleSound(): void {
        this._appInfoStore.toggleSound();
    }

    public shouldShowErrorPage(): boolean {
        return this._errorPageService.shouldShowErrorPage();
    }

    private _handeUserLoginStatusChange(): void {
        this.isLoggedIn$.pipe(
            distinctUntilChanged(),
            skip(1))
            .subscribe((isLoggedIn) => {
                this._notificationsService.closeAll();

                if (isLoggedIn) {
                    this._baseSocketsService.connect();
                }
        });
    }

    private _handleErrorPage(): void {
        this._router.events.pipe(
            filter((x) => x instanceof NavigationStart))
            .subscribe(() => {
                this._errorPageService.hide();
            });
    }

}
