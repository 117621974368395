import { AuctionInfoModule } from './../auction-info/auction-info.module';
import { BiddingStatusStore } from './shared/bidding-status.store';
import { BiddingStatusApiService } from './shared/bidding-status-api.service';
import { BiddingStatusSocketsService } from './shared/bidding-status-sockets.service';
import {
    BiddingStatusContainerComponent,
} from './bidding-status-container/bidding-status-container.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LeadingBidComponent } from './leading-bid/leading-bid.component';
import { TenderRankComponent } from './tender-rank/tender-rank.component';

@NgModule({
    declarations: [
        LeadingBidComponent,
        BiddingStatusContainerComponent,
        TenderRankComponent,
    ],
    exports: [
        BiddingStatusContainerComponent,
    ],
    imports: [
        AuctionInfoModule,
        SharedModule,
    ],
    providers: [
        BiddingStatusApiService,
        BiddingStatusStore,
        BiddingStatusSocketsService,
    ],
})
export class BiddingStatusModule {

}
