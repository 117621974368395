
import { of,  Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { AppTranslateApiService } from './app-translate-api.service';
import { AppTranslateStore } from './app-translate.store';
import { Translations } from './app-translate.interfaces';
import { TranslateLoader } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class AppTranslateLoader implements TranslateLoader {

    constructor(
        private _appTranslateStore: AppTranslateStore,
        private _appTranslateApiService: AppTranslateApiService,
    ) {}

    public getTranslation(langKey: string): Observable<Translations> {
        return this._appTranslateApiService.getTranslationsData(langKey).pipe(
            map((data) => {
                // Need to set state after new translations are returned, so wrapping in setTimeout.
                // This way TranslateService is updated with new translations earlier than state.
                // When state changes, components, that depend on language
                // and uses TranslateService, render strings in correct language
                setTimeout(() => {
                    this._appTranslateStore.setState(
                        data.supportedLanguages,
                        data.datepickerTranslations,
                        langKey,
                    );
                });
                return data.translations;
            }),
            catchError(() => {
                return of({});
            }));
    }
}
