import { PublicPagesGuardService } from './shared/public-pages-guard.service';
import { LoginGuardService } from './shared/login-guard.service';
import { AuthGuardService } from './shared/auth-guard.service';
import { UserApiService } from './shared/user-api.service';
import { LoginFormComponent } from './login-form/login-form.component';
import { AppFormsModule } from './../app-forms/app-forms.module';
import { LoginContainerComponent } from './login-container/login-container.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { UserStore } from './shared/user.store';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { UserService } from './shared/user.service';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        LoginPageComponent,
        LoginContainerComponent,
        LoginFormComponent,
    ],
    imports: [
        SharedModule,
        AppFormsModule,
        RouterModule,
    ],
    providers: [
        UserStore,
        UserApiService,
        AuthGuardService,
        LoginGuardService,
        PublicPagesGuardService,
        UserService,
    ],
})
export class UserModule {

}
