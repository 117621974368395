<div class="input-group">

    <ng-content></ng-content>

    <div class="input-group-btn"
        dropdown
        [isDisabled]="isDisabled"
        [(isOpen)]="isOpened"
        [autoClose]="'outsideClick'">

        <button type="button"
            class="btn btn-default dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            dropdownToggle>
            <span class="icon icon-timepicker"></span>
            <span class="caret"></span>
        </button>

        <ul class="dropdown-menu dropdown-menu-right" role="menu" *dropdownMenu>
            <li>
                <timepicker
                    [(ngModel)]="value"
                    (ngModelChange)="onChange()"
                    [showMeridian]="false"
                    [minuteStep]="1">
                </timepicker>
            </li>
        </ul>

    </div>

</div>
