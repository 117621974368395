import { Observable } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

interface BaseSettings {
    startDateTime: string;
    endDateTime: string;
    minBidImprovement: number;
    bidImprovementType: BidImprovementType;
    timeExtension: number;
}

export enum BidImprovementType {
    percentage = <any>'Percentage',
    unit = <any>'Unit',
}

export interface BidImprovementOption {
    title: string;
    type: BidImprovementType;
}

export interface GetSettingsApiResponseModel extends BaseSettings {
}

export interface Settings extends BaseSettings {
}

export interface SettingsFormModel {
    startDateTime?: string;
    endDateTime: string;
    minBidImprovement: number;
    bidImprovementType: BidImprovementType;
    timeExtension: number;
}

export interface CreateSettingsApiRequestModel extends BaseSettings {
}

export interface UpdateSettingsApiRequestModel extends SettingsFormModel {
}

export interface CreateSettingsApiResponseModel extends BaseSettings {
}

export interface UpdateSettingsApiResponseModel extends BaseSettings {
}

export interface SettingsUpdatedSocketsResponseModel extends BaseSettings {
}

export interface AuctionProlongedSocketsResponseModel {
    endDateTime: string;
}

export interface SettingsState {
    isInEditMode: boolean;
    isLoaded: boolean;
    isLoading: boolean;
    model: Settings;
}

export interface SettingsStoreSelectors {
    areSettingsSet$: () => Observable<boolean>;
}

export interface SettingsForm {
    endDate: NgbDateStruct;
    endTime: string;
    startDate: NgbDateStruct;
    startTime: string;
    minBidImprovement: number;
    timeExtension: number;
}
