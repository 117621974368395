import { CustomValidators } from 'ng2-validation';
import { ValidationResult } from './../shared/validators.interfaces';
import { FormControl, ValidatorFn, Validators  } from '@angular/forms';

export function lessThanValidator(maxValue: number): ValidatorFn  {
    return (control: FormControl): ValidationResult => {

        if (Validators.required(control)) {
            return null;
        }

        if (CustomValidators.number(control)) {
            return null;
        }

        if (control.value >= maxValue) {
            return {
                lessThan: { maxValue },
            };
        }

        return null;
    };
}
