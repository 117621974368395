import { GetBidsApiResponseModel } from './bids.interfaces';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseApiService } from '../../core/shared/base-api.service';

@Injectable()
export class BidsApiService {

    constructor(
        private _apiService: BaseApiService,
    ) {}

    public getBids(auctionId: number): Observable<GetBidsApiResponseModel> {
        const url = this._getUrl(auctionId);
        return this._apiService.get(url);
    }

    private _getUrl(auctionId: number): string {
        return `auctions/${auctionId}/bids`;
    }

}
