import { ConnectionPopoverComponent } from './connection-popover/connection-popover.component';
import {
    ConnectionIndicatorComponent,
} from './connection-indicator/connection-indicator.component';
import {
    ConnectionContainerComponent,
} from './connection-container/connection-container.component';
import { SoundToggleComponent } from './sound-toggle/sound-toggle.component';
import { SharedModule } from './../shared/shared.module';
import { AppInfoStore } from './shared/app-info.store';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [
        ConnectionContainerComponent,
        ConnectionIndicatorComponent,
        ConnectionPopoverComponent,
        SoundToggleComponent,
    ],
    exports: [
        ConnectionContainerComponent,
        SoundToggleComponent,
    ],
    imports: [
        SharedModule,
    ],
    providers: [
        AppInfoStore,
    ],
})
export class AppInfoModule {

}
