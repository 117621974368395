import { ValidationResult } from './../shared/validators.interfaces';
import { FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

export function integerValidator(control: FormControl): ValidationResult {
    const exp = /^-?[0-9]+$/;
    const valueInString = control.value + '';

    if (Validators.required(control)) {
        return null;
    }

    if (CustomValidators.number(control)) {
        return null;
    }

    if (!valueInString.match(exp)) {
        return {
            integer: true,
        };
    }

    return null;
}
