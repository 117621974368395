import { BiddingStatusStore } from './../../bidding-status/shared/bidding-status.store';
import { BiddingStore } from './../../bidding/shared/bidding.store';
import { SettingsStore } from './../../settings/shared/settings.store';
import { Subscription } from 'rxjs';
import { AppInfoStore } from './../../app-info/shared/app-info.store';
import {
    Component,
    Input,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
} from '@angular/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'eau-data-sync-supplier-container',
    templateUrl: './data-sync-supplier-container.template.html',
})
export class DataSyncSupplierContainerComponent implements OnInit, OnDestroy {

    @Input()
    public auctionId: number;

    @Input()
    public tenderId: number;

    private _subscriptions: Subscription[] = [];

    constructor(
        private _appInfoStore: AppInfoStore,
        private _settingsStore: SettingsStore,
        private _biddingStore: BiddingStore,
        private _biddingStatusStore: BiddingStatusStore,
    ) {}

    public ngOnInit(): void {
        const s1 = this._appInfoStore.whenReconnected$()
            .subscribe(this._syncSupplierData);

        this._subscriptions = [s1];
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((x) => x.unsubscribe());
    }

    private _syncSupplierData = (): void => {
        this._settingsStore.syncSettings(this.auctionId);
        this._biddingStore.syncBid(this.auctionId, this.tenderId);
        this._biddingStatusStore.syncBiddingStatus(this.auctionId, this.tenderId, false);
    }

}
