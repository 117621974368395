import { ValidationErrorsComponent } from './validation-errors/validation-errors.component';
import { AppDatepickerComponent } from './app-datepicker/app-datepicker.component';
import { AppInputDropdownComponent } from './app-input-dropdown/app-input-dropdown.component';
import { AppInputComponent } from './app-input/app-input.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ValidationService } from './shared/validation.service';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { BsDropdownModule   } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        AppDatepickerComponent,
        AppInputComponent,
        AppInputDropdownComponent,
        TimepickerComponent,
        ValidationErrorsComponent,
    ],
    exports: [
        AppDatepickerComponent,
        AppInputComponent,
        AppInputDropdownComponent,
        TimepickerComponent,
        ValidationErrorsComponent,
        FormsModule,
        ReactiveFormsModule,
    ],
    imports: [
        BsDropdownModule.forRoot(),
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        SharedModule,
        TimepickerModule.forRoot(),
    ],
    providers: [
        ValidationService,
    ],
})
export class AppFormsModule {

}
